import { Modal } from 'react-bootstrap';

const Success = (props) => {
    const { closeResponse } = props;
    return ( 
        <div>
            <Modal 
              show={true}
              onHide={closeResponse}
              backdrop="static"
              keyboard={false}
              className="resmodal"
          >
              <Modal.Header>
                  <div className="icon-box success_backcolor">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className='bi bi-check' viewBox="0 0 16 16">
                          <path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/>
                      </svg>
                  </div>
                  <Modal.Title className='w-100' align="center">Success!</Modal.Title>
              </Modal.Header>
              <Modal.Body className='text-center'>
                  <p className="mbody_text">
                    Your profile is under evaluation
                  </p>
              </Modal.Body>
          </Modal>
        </div>
     );
}
 
export default Success;