/*
    Title: Lender's eKYC Dashboard

    Data presenting to the lender:
     - Name             | ✅ |
     - Nik              | ✅ |
     - dob              | ✅ |
     - Mail             | ✅ |
     - KTP Image        | ✅ |
     - Selfie Image     | ✅ |
     - Gender           | ✅ |   
     - Citizenship      | ✅ |
     - Religion         | ✅ |
     - Marital Status   | ✅ |
     - Occupation       | ✅ |
     - Address          | ✅ |

*/

import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageCompressor } from 'image-compressor';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';

import IdCapture from '../../Widget/idCapture/idCapture.widget';
import SelfieCapture from '../../Widget/selfieCapture/selfieCapture.widget';

import LocalstorageService from '../../services/localstorage.service';
import { verifySession } from '../../services/verify.service';
import { uploadKTP, verifyKTP } from '../../services/ktp.service';
import { Dropdown } from 'primereact/dropdown';

import { Toast } from 'primereact/toast';
import './kyc.component.css';
import BrandBadge from '../../Widget/BrandBadge/brandBadge.widget';

const KYC = ({ back, onFormSuccess }) => {

    const toast = useRef(null);
    const { t } = useTranslation();
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isAllowEdit, setIsAllowEdit] = useState(false);
    const [isOCRExtracted, setOCRExtracted] = useState(false);
    const [livesInSameAddress, setLivesInSameAddress] = useState(false);
    const [doesntLiveInSameAddress, setDoesntLiveInSameAddress] = useState(false);

    const [ktpPhoto, setKtpPhoto] = useState(null);
    const [ktpRaw, setKtpRaw] = useState(null);

    const [selfiePhoto, setSelfiePhoto] = useState(null);
    const [selfieRaw, setSelfieRaw] = useState(null);

    const [mail, setMail] = useState('');
    const [nik, setNik] = useState('');
    const [name, setName] = useState('');
    const [dob, setDOB] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [citizenship, setCitizenship] = useState('');
    const [religion, setReligion] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [occupation, setOccupation] = useState('');
    const [address, setAddress] = useState('');
    const [RtRw, setRtRw] = useState('');
    const [SD, setSD] = useState('');
    const [D, setD] = useState('');
    const [currentAddress, setcurrentAddress] = useState('');
    const [currentRtRw, setcurrentRtRw] = useState('');
    const [currentSD, setcurrentSD] = useState('');
    const [currentD, setcurrentD] = useState('');
    const [education, setEducation] = useState('');
    const [companyEstablished, setCompanyEstablished] = useState('');
    const [employeeCount, setEmployeeCount] = useState('');

    const genders = t('selectPartnerPage.KYC.genderList', {returnObjects: true});
    const educationList = t('selectPartnerPage.KYC.eduList', {returnObjects: true});
    const [isClientJulo, setClientJulo] = useState(false);

    useEffect(() => {
        let localstorageService = new LocalstorageService();
        const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        if (JuloList.includes(apiKey)){ setClientJulo(true); }

        const handleResize = () => {
            if (window.innerWidth < 577) {
                setIsMobileScreen(true);
            } else {
                setIsMobileScreen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // #region UI Handlers 
    const handleNikChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setNik(e.target.value);
        }
    };

    const handleMailChange = (e) => {
        setMail(e.target.value);
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDOBChange = (e) => {
        setDOB(e.target.value);
    }

    const handleCitizenshipChange = (e) => {
        setCitizenship(e.target.value);
    }

    const handlePlaceOfBirthChange = (e) => {
        setPlaceOfBirth(e.target.value);
    }

    const handleReligionChange = (e) => {
        setReligion(e.target.value);
    }

    const handleMaritalStatusChange = (e) => {
        setMaritalStatus(e.target.value);
    }

    const handleOccupationChange = (e) => {
        setOccupation(e.target.value);
    }

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    }

    const handleRTRWChange = (e) => {
        setRtRw(e.target.value);
    }

    const handleSDChange = (e) => {
        setSD(e.target.value);
    }

    const handleDChange = (e) => {
        setD(e.target.value);
    }
    // #endregion UI Handlers

    // #region Notification Handlers
    const showSuccess = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
    }
    const showError = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 6000 });
    }
    const showWarn = (message) => {
        toast.current.show({ severity: 'warn', summary: message, life: 3000 });
    }
    // #endregion Notification Handlers

    const handleAutoSubmit = async () => {
        
        const verification = await verifySession();
        if (verification) {
            if (verification.status == "failed") {
                window.location.reload();
                return
            }
        }

        setIsDataLoading(true);
        const localStorageService = new LocalstorageService();

        try {
            const formData = new FormData();
            formData.append('ktp_photo', ktpPhoto);
            formData.append('selfie_photo', selfiePhoto);
            showWarn(t('form.waitMsg'));
            const response = await uploadKTP(formData, localStorageService.getItem('id', false, 'tokenVerification'), mail, localStorageService.getItem('user_id', false, 'tokenVerification'));
            await new Promise(r => setTimeout(r, 2000));
            if (response) {
                setNik(response.nik ? response.nik : '');
                setName(response.name ? response.name : '');
                if (response.gender) {
                    const lowerCaseGender = response.gender.toLowerCase();
                    if (lowerCaseGender == "perempuan") {
                        const selectedGender = genders.find(gender => gender.name === "PEREMPUAN" || gender.name === "FEMALE");
                        setGender(selectedGender);
                    } else {
                        const selectedGender = genders.find(gender => gender.name === "LAKI-LAKI" || gender.name === "MALE");
                        setGender(selectedGender);
                    }
                } else {
                    setGender('');
                }
                setCitizenship(response.citizenship ? response.citizenship : '');
                setReligion(response.religion ? response.religion : '');
                setMaritalStatus(response.marital_status ? response.marital_status : '');
                setOccupation(response.occupation ? response.occupation : '');
                setAddress(response.address != null ? response.address.replace(/[\n]+/g, ' ') : '');
                setRtRw(response.rtrw != null ? response.rtrw : '');
                setSD(response.sub_district != null ? response.sub_district : '');
                setD(response.district != null ? response.district : '');

                let formattedDOB = response.birth_date ? response.birth_date.replace(/[\s]/g, '-').replace(/[-]+/g, '-') : '';
                setDOB(formattedDOB);
                setOCRExtracted(true);
                setIsDataLoading(false);
                showSuccess(t('form.verify'));
                setIsAllowEdit(true);
            } else {
                showError('API ERROR X02');
                setIsDataLoading(false);
                setIsAllowEdit(false);
            }
        } catch (error) {
            showError('API ERROR X01');
            setIsDataLoading(false);
            setIsAllowEdit(false);
        }
    };

    const handleSubmitForm = async () => {

        const verification = await verifySession();
        if (verification) {
            if (verification.status == "failed") {
                window.location.reload();
                return
            }
        }

        setIsDataLoading(true);
        if (selfiePhoto && ktpPhoto) {
            const localStorageService = new LocalstorageService();

            let address_body = {
                'address': address,
                'rtrw': (isClientJulo) ? '-' : RtRw,
                'sub_district': (isClientJulo) ? '-' : SD,
                'district': (isClientJulo) ? '-' : D
            }

            let current_address_body = {
                'address': livesInSameAddress ? '' : currentAddress,
                'rtrw': (isClientJulo && !livesInSameAddress) ? '-' : livesInSameAddress ? '' : currentRtRw,
                'sub_district': (isClientJulo && !livesInSameAddress) ? '-' : livesInSameAddress ? '' : currentSD,
                'district': (isClientJulo && !livesInSameAddress) ? '-' : livesInSameAddress ? '' : currentD
            }


            let req_body = {
                'id': localStorageService.getItem('id', false, 'tokenVerification'),
                'nik': nik,
                'name': name,
                'dob': dob,
                'gender': gender.name,
                'citizenship': citizenship,
                'religion': religion,
                'maritalStatus': maritalStatus,
                'occupation': occupation,
                'address': address_body,
                'placeOfBirth': placeOfBirth,
                'education': education.name,
                'companyEstablished': companyEstablished,
                'employeeCount': employeeCount,
                'currentAddress': current_address_body
            }
            const response = await verifyKTP(req_body);
            if (response) {
                if (response?.detail?.message == "Transaction already created for this reference_id") {
                    showError(t("selectPartnerPage.KYC.invalidSession"));
                    setIsDataLoading(false);
                    setIsSuccess(true);
                } else {
                    setIsSuccess(true);
                    showSuccess('KYC Verified');
                    setIsDataLoading(false);
                    onFormSuccess(true);
                }
            } else {
                setIsDataLoading(false);
            }
        } else {
            showError('Please upload images');
            setIsDataLoading(false);
        }
    };

    const handleSelfieCapture = async (image, imageRaw) => {
        const localStorageService = new LocalstorageService();
        setSelfieRaw(image);

        const file = new File([imageRaw], `${localStorageService.getItem('id', false, 'tokenVerification')}.jpeg`, { type: 'image/jpeg' });
        setSelfiePhoto(file);
    };

    const handleKtpCapture = async (image, imageRaw) => {
        const localStorageService = new LocalstorageService();
        setKtpRaw(image);

        const file = new File([imageRaw], `${localStorageService.getItem('id', false, 'tokenVerification')}.jpeg`, { type: 'image/jpeg' });
        setKtpPhoto(file);
    }

    const handleFormSubmit = () => {
        if (selfiePhoto && ktpPhoto && (mail != '')) {
            handleAutoSubmit();
        }
    };

    const formRender = () => {
        return (
            <div className="info-p info-p-n info-p-n-m" style={{ textAlign: 'center' }}>
                <div className="info-inner-p" ></div>
                <div className="form-p" style={
                    {
                        "paddingBottom": "6rem"
                    }
                }>
                    <div>
                        <label className="scl ">{t('selectPartnerPage.KYC.KTP')}
                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.KTP')}</div>} /> */}
                        </label>

                        {
                            isAllowEdit ? (
                                <div className="input-p input-f-p1" disabled>
                                    <div role="presentation" tabIndex={0} className="form-control ddrop-div">
                                        <div>
                                            <img src={ktpRaw} alt="Captured Image" />
                                        </div>
                                    </div>
                                </div>
                            ) : (<IdCapture onCapture={handleKtpCapture} ComponentType={"KTP"} />)
                        }

                    </div>

                    <div style={{ paddingTop: '30px' }}>
                        <label className="scl ">{t('selectPartnerPage.KYC.selfie')}
                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.selfie')}</div>} /> */}
                        </label>

                        {
                            isAllowEdit ? (
                                <div className="input-p input-f-p1" disabled>
                                    <div role="presentation" tabIndex={0} className="form-control ddrop-div">
                                        <div>
                                            <img src={selfieRaw} alt="Captured Image" />
                                        </div>
                                    </div>
                                </div>
                            ) : (<SelfieCapture onCapture={handleSelfieCapture} />)
                        }
                    </div>

                    <div style={{ paddingTop: '30px' }}>
                        <label className="scl">E-Mail
                            {/* <InfoButton message={<div>NIK</div>} /> */}
                        </label>

                        <div className="input-p">
                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                <input type="email" id="mail" className="form-control" placeholder="E-Mail" name="mail"
                                    disabled={isAllowEdit}
                                    value={mail}
                                    onChange={handleMailChange}
                                />
                            </span>

                        </div>
                    </div>

                    {
                        isAllowEdit
                            ? (
                                <>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl">NIK
                                            {/* <InfoButton message={<div>NIK</div>} /> */}
                                        </label>

                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="NIK" className="form-control" placeholder="NIK" name="NIK"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={nik}
                                                    onChange={handleNikChange}
                                                    style={!nik ? { border: '1px solid red' } : {}}
                                                    maxLength={16}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl "> {t('selectPartnerPage.KYC.name')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.name')}</div>} /> */}
                                        </label>

                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="name" className="form-control" placeholder={t('selectPartnerPage.KYC.name')} name="name"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={name}
                                                    onChange={handleNameChange}
                                                    style={!name ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl "> {t('selectPartnerPage.KYC.dob')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="dob" className="form-control" placeholder={t('selectPartnerPage.KYC.dob')} name="dob"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={dob}
                                                    onChange={handleDOBChange}
                                                    style={!dob ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.pob')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="pob" className="form-control" placeholder={t('selectPartnerPage.KYC.pob')} name="pob"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={placeOfBirth}
                                                    onChange={handlePlaceOfBirthChange}
                                                    style={!placeOfBirth ? { border: '1px solid red' } : {}}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.gender')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">

                                            {/* {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null} */}
                                            <Dropdown
                                                disabled={!isAllowEdit || isSuccess}
                                                value={gender}
                                                onChange={(e) => setGender(e.value)}
                                                options={genders} optionLabel="name"
                                                placeholder={t('selectPartnerPage.KYC.gender')}
                                                style={!gender ? { border: '1px solid red', width: '100%', textAlign: 'start' } : { width: '100%', textAlign: 'start' }}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.citizenship')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="citizenship" className="form-control" placeholder={t('selectPartnerPage.KYC.citizenship')} name="citizenship"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={citizenship}
                                                    onChange={handleCitizenshipChange}
                                                    style={!citizenship ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.religion')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="religion" className="form-control" placeholder={t('selectPartnerPage.KYC.religion')} name="religion"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={religion}
                                                    onChange={handleReligionChange}
                                                    style={!religion ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.maritalS')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="maritalStatus" className="form-control" placeholder={t('selectPartnerPage.KYC.maritalS')} name="maritalStatus"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={maritalStatus}
                                                    onChange={handleMaritalStatusChange}
                                                    style={!maritalStatus ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.occupation')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="occupation" className="form-control" placeholder={t('selectPartnerPage.KYC.occupation')} name="occupation"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={occupation}
                                                    onChange={handleOccupationChange}
                                                    style={!occupation ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.education')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                <Dropdown
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={education}
                                                    onChange={(e) => {
                                                        setEducation(e.value)
                                                    }}
                                                    options={educationList} optionLabel="name"
                                                    placeholder={t('selectPartnerPage.KYC.education')}
                                                    style={!education ? { border: '1px solid red', width: '100%', textAlign: 'start' } : { width: '100%', textAlign: 'start' }}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.companyEstablished')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="companyEstablished" className="form-control" placeholder={t('selectPartnerPage.KYC.companyEstablished')} name="companyEstablished"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={companyEstablished}
                                                    pattern="[0-9]*"
                                                    onChange={(e) => {
                                                        if (e.target.validity.valid) setCompanyEstablished(e.target.value)
                                                    }}
                                                    style={!companyEstablished ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.employee')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <input type="text" id="employee" className="form-control" placeholder={t('selectPartnerPage.KYC.employee')} name="employee"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    value={employeeCount}
                                                    pattern="[0-9]*"
                                                    onChange={(e) => {
                                                        if (e.target.validity.valid) setEmployeeCount(e.target.value)
                                                    }}
                                                    style={!employeeCount ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.title')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={address}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.addr')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={handleAddressChange}
                                                    style={!address ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    {!isClientJulo && (<>
                                        <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.rtrw')}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={RtRw}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.Address.rtrw')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={handleRTRWChange}
                                                    style={!RtRw ? { border: '1px solid red' } : {}}
                                                />
                                            </span>
                                        </div>
                                    </div>


                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.sd')}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={SD}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.Address.sd')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={handleSDChange}
                                                    style={!SD ? { border: '1px solid red' } : {}}
                                                />
                                            </span>
                                        </div>
                                    </div>


                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.d')}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={D}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.Address.d')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={handleDChange}
                                                    style={!D ? { border: '1px solid red' } : {}}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    </>)}

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {/* {t('selectPartnerPage.KYC.addr')} */}
                                            {t('selectPartnerPage.KYC.Address.residentialQuestion')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <div className="flex flex-wrap  gap-3" style={{ display: 'flex' }}>
                                                <div className="flex align-items-center checkBoxItem">
                                                    <Checkbox inputId="ingredient1" name="pizza" value="yes"
                                                        disabled={!isAllowEdit || isSuccess}
                                                        checked={livesInSameAddress}
                                                        onChange={(e) => {
                                                            setLivesInSameAddress(e.checked)
                                                            setDoesntLiveInSameAddress(!e.checked)
                                                        }}
                                                    />
                                                    <label htmlFor="ingredient1" className="ml-2">
                                                        {t('selectPartnerPage.KYC.Address.y')}
                                                    </label>
                                                </div>
                                                <div className="flex align-items-center checkBoxItem">
                                                    <Checkbox inputId="ingredient2" name="pizza" value="no"
                                                        disabled={!isAllowEdit || isSuccess}
                                                        checked={doesntLiveInSameAddress}
                                                        onChange={(e) => {
                                                            setLivesInSameAddress(!e.checked)
                                                            setDoesntLiveInSameAddress(e.checked)
                                                        }}
                                                    />
                                                    <label htmlFor="ingredient2" className="ml-2">
                                                        {t('selectPartnerPage.KYC.Address.n')}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {doesntLiveInSameAddress && (<>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.home')}
                                            {/* <InfoButton message={<div>{t('selectPartnerPage.KYC.dob')}</div>} /> */}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={currentAddress}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.Address.home')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={(e) => setcurrentAddress(e.target.value)}
                                                    style={!currentAddress ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    {!isClientJulo && (<>
                                        <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.rtrw2')}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={currentRtRw}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.Address.rtrw2')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={(e) => setcurrentRtRw(e.target.value)}
                                                    style={!currentRtRw ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.sd2')}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={currentSD}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.Address.sd2')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={(e) => setcurrentSD(e.target.value)}
                                                    style={!currentSD ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <label className="scl ">
                                            {t('selectPartnerPage.KYC.Address.d2')}
                                        </label>
                                        <div className="input-p">
                                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                                <InputTextarea autoResize rows={5} cols={30} value={currentD}
                                                    type="text" id="address" className="form-control" placeholder={t('selectPartnerPage.KYC.Address.d2')} name="address"
                                                    disabled={!isAllowEdit || isSuccess}
                                                    onChange={(e) => setcurrentD(e.target.value)}
                                                    style={!currentD ? { border: '1px solid red' } : {}}
                                                />
                                            </span>

                                        </div>
                                    </div>
                                    </>)}
                                        
                                    </>)}

                                </>

                            ) : (null)
                    }


                </div>
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="partner-page-form-n">
                <div>
                    <h3 className="title-p title-p-p1 title-p-n">
                        KTP Identity Data
                    </h3>
                    {formRender()}
                </div>
                {/* a small message here, center alignment and red color to say verify your details before submit, it should be just above the submit button*/}


                <div className="verification-message" style={isOCRExtracted && !isSuccess ? { color: 'red' } : { display: 'none' }}>
                    {
                        t('selectPartnerPage.KYC.verify')
                    }
                </div>

                <div className="verification-message" style={isSuccess ? { color: 'green' } : { display: 'none' }}>
                    {
                        t('form.submited')
                    }
                </div>

                <div className="btn-div-p-n">
                    <div className="back-btn-pp back-btn-pp-n btn nav-btn-pwc next-btn" style={isMobileScreen ? {} : { display: 'none' }} onClick={back}>
                        {t('form.back')}
                    </div>
                    <div className="buttons-bottom-div">
                        <button type="submit" className="btn nav-btn-pwc next-btn btn-n btn-next-partner-n"
                            disabled={isDataLoading || !(ktpPhoto && selfiePhoto && mail != '') ||
                                (
                                    isOCRExtracted && (
                                        nik == '' || name == '' || dob == '' || occupation == '' || gender == '' || citizenship == '' || religion == '' || maritalStatus == '' || address == '' || placeOfBirth == '' ||
                                        education == '' || companyEstablished == '' || employeeCount == '' || (doesntLiveInSameAddress && currentAddress == '' || (livesInSameAddress == doesntLiveInSameAddress)))
                                ) || isSuccess}
                            onClick={!isAllowEdit ? handleFormSubmit : handleSubmitForm}
                        >{t('form.submit')} {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}</button>
                    </div>
                </div>
                {isMobileScreen && <BrandBadge option={"footer"} />}
            </div>
        </>
    );
}

export default KYC;