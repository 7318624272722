import axios from 'axios';
import LocalstorageService from './localstorage.service';

const isProduction = window.location.href.includes('https://auth.powercred.io')||window.location.href.includes('https://docs.julo.co.id');
const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

export const verifySession = async () => {
    try {
        let localstorageService = new LocalstorageService();
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        const clientID = localstorageService.getItem('client_id', false, 'tokenVerification');
        const accessToken = localstorageService.getItem('token', false, 'tokenVerification');
        
        const url = `${baseURL}/auth/token/verify?apikey=${apiKey}&client_id=${clientID}&access_token=${accessToken}`;
        const response = await axios.post(url);
        return response.data;
        
    } catch (error) {
        console.error('Error Returning URL:', error);
        return null;
    }
}
