import React, {useEffect} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
// import Home from './Widget/Home';
// import Response from './pages/Response';
// import Response from './components/Response';
import BlibliStoreBindReference from './pages/BlibliStoreBindReference';
import ShopeeReference from './pages/ShopeeReference';
import TokopediaReference from './pages/TokopediaReference';
// import BukalapakStoreBindingReference from './pages/BukalapakStoreBindingReference';
import ClientHome from './token/ClientHome';
import Callback from './token/Callback';
import NewCallback from './token/NewCallback';
import { I18nextProvider } from 'react-i18next';
import i18n from './services/i18n.service';
import LocalstorageService from './services/localstorage.service';

function App() {
  const [isJulo, setIsJulo] = React.useState(false);

  useEffect(() => {
    const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
    let localstorageService = new LocalstorageService();
    const appKey = localstorageService.getItem('client_id', false, 'tokenVerification');
    if (JuloList.includes(appKey)){
      document.title = "Julo";
      setIsJulo(true);
    } else {
      document.title = "Powercred Widget";
      setIsJulo(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {/* <Route exact path="/" element={ <ClientHome /> } /> */}
          <Route exact path="/" element={ <I18nextProvider i18n={i18n}><Home isJulo={isJulo} /></I18nextProvider> } />
          <Route exact path="/callback" element={ <Callback /> } />
          <Route exact path="/:partner/callback" element={<NewCallback />} />
          <Route path="/BlibliStoreBindReference" element={ <BlibliStoreBindReference /> } />
          <Route path="/shopeeReference" element={ <ShopeeReference /> } />
          <Route path="/tokopediaReference" element={ <TokopediaReference /> } />
          {/* <Route path="/Response" element={ <Response /> } /> */}
          {/* <Route path="/BukalapakStoreBindingReference" element={ <BukalapakStoreBindingReference /> } /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;