/*
  DOCUMENTATION:

  Key = tokenVerification
  Value = {"token":"ze0THIeiRs276xgSjjPNZEvKZeEq","id":"46eefc153b4f43d8bcb9bd905bb5ea90","client_id":"iiV0pirSZpnKudtQX4MCrNgAHbUTAnOwOSQM4BOfVrfwUqyp","user_id":"7981186029"}
  
  Key = UIRZAjbTSn
  Value = {"token": "ENCODED_VALUE", "id": "ENCODED_VALUE", "client_id": "ENCODED_VALUE", "user_id": "ENCODED_VALUE"}

  ------------------------------------------------------------

  const localStorageService = new LocalstorageService();

  localStorageService.init();

  localStorageService.setItem('token', 'ACTUAL_TOKEN'); (Similar to "localStorage.setItem('token', 'ACTUAL_TOKEN')")
  localStorageService.setItem('id', 'ACTUAL_ID');
  localStorageService.setItem('client_id', 'ACTUAL_CLIENT_ID');
  localStorageService.setItem('user_id', 'ACTUAL_USER_ID');
  
  localStorageService.getItem('token')  (Similar to "localStorage.getItem('token')" )

  localStorageService.removeItem('token'); <- remove a specific item from the dict 

  localStorageService.verify() 

  localStorageService.kill() 

  ------------------------------------------------------------
  
  localStorageService.getItem('token', false, 'tokenVerification') 


*/ 

export default class LocalstorageService {
  constructor() {
    this.storageKey = 'UIRZAjbTSn';
  }
  
  init() {
    if (!localStorage.getItem(this.storageKey)) {
      localStorage.setItem(this.storageKey, '');
    }
  }
  
  kill() {
    localStorage.removeItem(this.storageKey);
  }
  
  setItem(key, item) {
    let _key = this.encoder(key);
    let _item = this.encoder(item);
    
    try {
      const storageData = localStorage.getItem(this.storageKey);
      let dataObject = {};
    
      if (storageData) {
        dataObject = JSON.parse(storageData);
      }
    
      dataObject[_key] = _item;
    
      localStorage.setItem(this.storageKey, JSON.stringify(dataObject));
    } catch (error) {
      console.error('Error setting item:', error);
    }
  }
  
  getItem(key, shouldDecode = true, customKey = this.storageKey) {
    let _key = shouldDecode ? this.encoder(key) : key;
  
    try {
      const storageData = localStorage.getItem(customKey);
  
      if (storageData) {
        const dataObject = JSON.parse(storageData);
        const value = dataObject[_key];
        if (shouldDecode) {
          return this.decoder(value);
        } else {
          return value;
        }
      }
  
      return null;
    } catch (error) {
      console.error('Error getting item:', error);
      return null;
    }
  }
  
  removeItem(key) {
    let _key = this.encoder(key);
    
    try {
      const storageData = localStorage.getItem(this.storageKey);
    
      if (storageData) {
        const dataObject = JSON.parse(storageData);
        delete dataObject[_key];
        localStorage.setItem(this.storageKey, JSON.stringify(dataObject));
      }
    } catch (error) {
      console.error('Error removing item:', error);
    }
  }
  
  verify() {
    return localStorage.getItem(this.storageKey) ? true : false;
  }
  
  encoder(value) {
    let encodedValue = btoa(JSON.stringify(value));
    encodedValue = encodedValue.replace(/=/g, '+');
    encodedValue = encodedValue.substring(encodedValue.length / 2) + encodedValue.substring(0, encodedValue.length / 2);
    encodedValue = encodedValue.split('').reverse().join('');
    return encodedValue;
  }
  
  decoder(encodedValue) {
    encodedValue = encodedValue.split('').reverse().join('');
    encodedValue = encodedValue.substring(encodedValue.length / 2) + encodedValue.substring(0, encodedValue.length / 2);
    encodedValue = encodedValue.replace(/\+/g, '=');
    return JSON.parse(atob(encodedValue));
  }
}
