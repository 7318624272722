import { useEffect, useState } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import FullPageLoader from '../components/FullPageLoader';

const NewCallback = () => {
    const [tokenVerification, setTokenVerification] = useState(JSON.parse(localStorage.getItem('tokenVerification')) || {id: undefined, apikey: undefined});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    const { partner } = useParams();

    const isProduction = window.location.href.includes('https://auth.powercred.io')||window.location.href.includes('https://docs.julo.co.id');
    const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

    const partners = {
        'shopee': {
            config: {
                method: 'GET',
                url: `/shopee/callback`,
                baseURL,
                params: {
                    id: tokenVerification.id,
                    apikey: tokenVerification.client_id,
                    code: searchParams.get("code"),
                    shop_id: searchParams.get("shop_id")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'mokapos': {
            config: {
                method: 'GET',
                url: `/mokapos/callback`,
                baseURL,
                params: {
                    id: tokenVerification.id,
                    apikey: tokenVerification.client_id,
                    code: searchParams.get("code")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'instagram': {
            config: {
                method: 'GET',
                url: `/instagram/callback`,
                baseURL,
                params: {
                    apikey: tokenVerification.client_id,
                    state: searchParams.get("state"),
                    code: searchParams.get("code"),
                    error_reason: searchParams.get("error_reason")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'tiktok': {
            config: {
                method: 'GET',
                url: `/tiktok/callback`,
                baseURL,
                params: {
                    apikey: tokenVerification.client_id,
                    state: searchParams.get("state"),
                    code: searchParams.get("code")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'jurnal': {
            config: {
                method: 'GET',
                url: `/jurnal/auth/callback`,
                baseURL,
                params: {
                    id: tokenVerification.id,
                    apikey: tokenVerification.client_id,
                    access_token: searchParams.get("access_token")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'accurate': {
            config: {
                method: 'GET',
                url: `/accurate/callback`,
                baseURL,
                params: {
                    id: tokenVerification.id,
                    apikey: tokenVerification.client_id,
                    code: searchParams.get("code"),
                    state: searchParams.get("state"),
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'quickbooks': {
            config: {
                method: 'GET',
                url: `/quickbooks/callback`,
                baseURL,
                params: {
                    id: tokenVerification.id,
                    apikey: tokenVerification.client_id,
                    code: searchParams.get("code"),
                    state: searchParams.get("state"),
                    realmId: searchParams.get("realmId")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'xero': {
            config: {
                method: 'GET',
                url: `/xero/callback`,
                baseURL,
                params: {
                    apikey: tokenVerification.client_id,
                    state: searchParams.get("state"),
                    code: searchParams.get("code"),
                    scope: searchParams.get("scope")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        },
        'lazada': {
            config: {
                method: 'GET',
                url: `/lazada/auth/callback`,
                baseURL,
                params: {
                    apikey: tokenVerification.client_id,
                    state: searchParams.get("state"),
                    code: searchParams.get("code")
                },
                headers: {'Content-Type': 'application/json'}
            },
            successMessage() { return 'Authentication Successful, Please close the window.'; },
            errorMessage(response) { return response.detail ? response.detail : 'Authentication failed, Please re-authorize!'; },
        }
    };

    const callBackendAPI = async () =>  {
        setIsLoading(true);

        const wind = window.open('', '_self');
        try{
            const response = await axios(partners[partner].config);
            console.log(response);

            if(response.data && response.data.status_code){
                throw response.data;
            }
            
            wind.document.body.innerHTML = partners[partner].successMessage(response.data);
            sendMessage(true);
        }
        catch(e){
            console.log('error ', e);
            wind.document.body.innerHTML = partners[partner].errorMessage(e);
            sendMessage(false);
        }

        setIsLoading(false);
    };

    function sendMessage(isSuccess){
        if(!tokenVerification.id){
            return;
        }
        const bc = new BroadcastChannel("oAuth_channel");
        bc.postMessage(isSuccess ? 'callback-process-success' : 'callback-process-fail');
    }

    useEffect(() => {
        callBackendAPI();
        // console.log(partners['partner'].config.params);
    },[]);

    return (
        <>
            <div></div>
            {isLoading && <FullPageLoader />}
        </>
    );
}
 
export default NewCallback;