import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
// import { XMarkIcon } from "@heroicons/react/24/solid"

const Dropzone = ({ className, changeFile }) => {
  const [files, setFiles] = useState([])
  const [rejected, setRejected] = useState([])
  
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length) {
      setFiles(previousFiles => {
        acceptedFiles = acceptedFiles.filter(file => {
          return !previousFiles.find(innerfile => innerfile.name == file.name)
        })
        return[
        ...previousFiles,
        ...acceptedFiles
        ]
      })
    }

    if (rejectedFiles?.length) {
      setRejected(previousFiles => [...previousFiles, ...rejectedFiles])
    }
    setFiles(previousFiles => {
      changeFile(previousFiles)
      return previousFiles
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop, 
    accept: {
    'application/pdf': ['.pdf']
    } 
  });

  
  
  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  const removeFile = name => {
    setFiles(files => {
      files = files.filter(file => file.name !== name)
      changeFile(files)
      return files
    })
  }

  return (
    <div>
    {/* // <form> */}
        <div {...getRootProps({
          className: className + " ddrop-div"
        })}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
              </svg>
              <p className="ddrop-hp"> Drop items here or Browse files...</p>
            </div>
          )}
      </div>
      <ul className="ddrop-ul">
        {files.map(file => (
          <li key={file.name} className="ddrop-li">
            <div className="ddrop-li-div">
              {/* <div> */}
              <p className="ddrop-p">{file.name}<br/> <span className="ddrop-span">{Math.round((file.size/1024 + Number.EPSILON) * 100) / 100} KB</span></p>
              {/* </div> */}
              <div className="ddrop-del-div">
                <a className="ddrop-a" onClick={() => removeFile(file.name)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                  </svg>
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    {/* </form> */}
    </div>
  )
}
export default Dropzone;