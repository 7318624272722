
const Submit = (props) => {
    const { isSubmitChecked, changeSubmitCheckbox, submitData, showConfirmModal } = props;
    return (
        <div className='col-xs-12 pad_0 gsp' style={{position: 'relative'}}>
            <div className='col-xs-12'>
                <div className="form-check form-check-inline">
                    <input name="submit_group" type="checkbox" id="submit_check" checked={isSubmitChecked} onChange={changeSubmitCheckbox} className="form-check-input" />
                    <label htmlFor="submit_check" className="form-check-label padl_16">
                        By checking this, you agrees to <b>Powercred's</b> <a href='https://www.powercred.io/terms-of-service' className="a_master_cb" target="_blank">terms of service</a> and <a href='https://www.powercred.io/privacy-policy' className="a_master_cb" target="_blank">privacy policies</a> and authorize <b>{'companyName'}</b> to extract my information from listed data partners for better credit.
                    </label>
                </div>
            </div>
            <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 padt_15'>                            
                <button type="button" className='btn nav_btn' onClick={submitData} >
                    Next
                </button>
            </div>
            <button type="button" className='btn nav-btn-pwc back-btn' onClick={showConfirmModal}>
                Back
            </button>
        </div> 
    );
}
 
export default Submit;