import axios from 'axios';
import LocalstorageService from './localstorage.service';

const isProduction = window.location.href.includes('https://auth.powercred.io')||window.location.href.includes('https://docs.julo.co.id');
const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

const wantLocal = false; // Mandatory change to false before pushing to gitlab


export const getOTP = async (id, phone) => {
  try {
    let localstorageService = new LocalstorageService();
    const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

    const url = wantLocal ? `http://127.0.0.1:8000/auth?apikey=${apiKey}&id=${id}&contact=${phone}` : `${baseURL}/gopay/auth?apikey=${apiKey}&id=${id}&contact=${phone}`;
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error('Error Completing Gopay:', error);
    return null;
  }
};

export const verifyOTP = async (id, otp) => {
  try {
    let localstorageService = new LocalstorageService();
    const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

    const url = wantLocal ? `http://127.0.0.1:8000/otp?apikey=${apiKey}&id=${id}&otp=${otp}` : `${baseURL}/gopay/otp?apikey=${apiKey}&id=${id}&otp=${otp}`;
    const response = await axios.post(url);
    if (response.status === 202) {
      return response.data.detail;
    } else {
      return response.data;
    }
  } catch (error) {
    console.error('Error Completing Gopay:', error);
    return null;
  }
};

export const verifyPIN = async (id, pin) => {  
    try {
      let localstorageService = new LocalstorageService();
      const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

      const url = wantLocal ? `http://127.0.0.1:8000/pin?apikey=${apiKey}&id=${id}&pin=${pin}` : `${baseURL}/gopay/pin?apikey=${apiKey}&id=${id}&pin=${pin}`;
      const response = await axios.post(url);
      return response.data;
    } catch (error) {
        console.error('Error Completing Gopay:', error);
        return null;
    }
};
