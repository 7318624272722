import { useEffect } from "react";
import axios from 'axios';
import { useState } from 'react';
import FullPageLoader from '../components/FullPageLoader';

const Callback = () => {
    const [isLoading, setIsLoading] = useState(false);

    const call = async (code) => {
        const tokenVerification = localStorage.getItem('tokenVerification');

        let params;

        if(tokenVerification){
            let { id, client_id } = JSON.parse(tokenVerification);
            params = {
                id: id,
                apikey: client_id,
                code: code
            };
        }
        else{
            params = {
                code: code
            };
        }
        // window.open(`https://dev.powercred.io/mokapos/callback?id=${id}&code=${code}&apikey=${client_id}`, '_self');
        // return;
        setIsLoading(true);
        const config = {
            method: 'GET',
            url: `https://dev.powercred.io/mokapos/callback`,
            params,
            headers: {'Content-Type': 'application/json'}
        };
        let response;
        const wind = window.open('', '_self');
        try{
            response = await axios(config);
            console.log(response);
            if(response.data && response.data.status_code){
                throw response.data;
            }
            
            wind.document.body.innerHTML = response.data;
            // console.log(response.data);
        }
        catch(e){
            console.log('error ', e);
            wind.document.body.innerHTML = e.detail;
        }
        setIsLoading(false);
    };

    const callShopee = async (code, shop_id) => {
        const tokenVerification = localStorage.getItem('tokenVerification');

        let params;

        if(tokenVerification){
            let { id, client_id } = JSON.parse(tokenVerification);
            params = {
                id: id,
                apikey: client_id,
                code: code,
                shop_id: shop_id
            };
        }
        else{
            params = {
                code: code,
                shop_id: shop_id
            };
        }
        // window.open(`https://dev.powercred.io/mokapos/callback?id=${id}&code=${code}&apikey=${client_id}`, '_self');
        // return;
        setIsLoading(true);
        const config = {
            method: 'GET',
            url: `https://dev.powercred.io/shopee/callback`,
            params,
            headers: {'Content-Type': 'application/json'}
        };
        let response;
        const wind = window.open('', '_self');
        try{
            response = await axios(config);
            console.log(response);
            if(response.data && response.data.status_code){
                throw response.data;
            }
            
            wind.document.body.innerHTML = 'Authentication Successful, Please close the window.';
            // console.log(response.data);
        }
        catch(e){
            console.log('error ', e);
            wind.document.body.innerHTML = 'Authentication Successful, Please close the window.';
        }
        setIsLoading(false);
    };

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code'); 
        let shop_id = params.get('shop_id');  
        if(shop_id){
            callShopee(code, shop_id);
        }
        else{
            call(code);
        }
    },[]);

    return ( 
        <>
            <div></div>
            {isLoading && <FullPageLoader />}
        </>
    );
}
 
export default Callback;