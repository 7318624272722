import axios from 'axios';
import LocalstorageService from './localstorage.service';

const isProduction = window.location.href.includes('https://auth.powercred.io')||window.location.href.includes('https://docs.julo.co.id');
const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';
const wantLocal = false; // Mandatory change to false before pushing to gitlab

export const uploadNPWP = async (formData, id, email, user_id) => {
  try {
    let localstorageService = new LocalstorageService();
    const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

    const url = wantLocal ?
    `http://127.0.0.1:8000/ocr/document?apikey=${apiKey}&id=${id}&email=${encodeURIComponent(email)}&user_id=${encodeURIComponent(user_id)}&is_ocr=true&is_verification=false&apikey=iiV0pirSZpnKudtQX4MCrNgAHbUTAnOwOSQM4BOfVrfwUqyp&secret=lUw4TtQUHSklXDEta2A5wk5vaczJIIlrdgIO4p7niPHyo8GQAlPcpKAIGONMiFNx&appid=d73840c6-52c6-409b-a6a2-c792c024dcdf&developer_email=shrinivas.deshmukh11%40gmail.com&appname=powercred` : 
    `${baseURL}/npwp/ocr/document?apikey=${apiKey}&id=${id}&email=${encodeURIComponent(email)}&user_id=${encodeURIComponent(user_id)}&is_ocr=true&is_verification=false`;

    const response = await axios.post(url, formData);
    if (response.status === 200) {
        console.log(response.data)
      return response.data;
    } else {
      throw new Error('Failed to upload NPWP. Status: ' + response.status);
    }
  } catch (error) {
    if (error.response && error.response.status) {
      console.error('Error uploading NPWP:', error);
    }
    return error.response.data;
  }
};