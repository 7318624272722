import React, { useRef, useState } from 'react';
import powercredLogo from '../../components/images/powercred_logo.jpg';
import './brandBadge.widget.css';

const BrandBadge = ({option}) => {
    if(option == "footer"){
        return (
            <>
                <div className="footer">
                    <div>
                        <span className='powerb'>Powered by</span> 
                        <a href="https://www.powercred.io" target="_blank" rel="noopener noreferrer">
                            <img src={powercredLogo} alt="Powercred Logo" style={{height:".9rem"}}  />
                        </a>
                    </div>
                </div>
            </>
        )
    }

    else {
        return (
            <>
                <div>
                    <span className='powerb'>Powered by</span> 
                    <a href="https://www.powercred.io" target="_blank" rel="noopener noreferrer">
                        <img src={powercredLogo} alt="Powercred Logo" style={{height:".9rem"}}  />
                    </a>
                </div>
            </>
        )
    }

}

export default BrandBadge;