import { useState } from 'react';
import Partner from './Partner';
import PartnerNew from './PartnerNew';
import Partner2 from './Partner2';
import Partner3 from './Partner3';
import './main.css';

const Home = (params) => {
    const {ui, responseT, setHomeWrongToken, token, idT, client_id, backToHome, categoryResponse, logoResponse, appsSettingsResponse, isUserOnboardingDemo, isJulo } = params;

    const [partnerPage, setPartnerPage] = useState(true);

    const [isTranslated, setIsTranslated] = useState(true);

    const setHome = () => {
        setPartnerPage(true);
    };

    const translate = (lang) => {
        setIsTranslated(lang == 'en' ? false : true);

        // let ind = 1;
        // if(lang == 'en'){
        //     if(!isTranslated){  //document.getElementsByClassName('goog-te-combo')[0].options[0].value == ''){
        //         return;
        //     }
        //     ind = 0;
        // }
        // else if(isTranslated){
        //     return;
        // }
        // setIsTranslated(ind ? true : false);
       
        // document.getElementsByClassName('goog-te-combo')[0].options[ind].selected = true;
        // var evt = document.createEvent("HTMLEvents");
        // evt.initEvent('change', true, true ); // event type,bubbling,cancelable
        // return !document.getElementsByClassName('goog-te-combo')[0].options[ind].dispatchEvent(evt);
        
    };
    
    if(partnerPage){
        if(ui == '1'){
            // return <Partner setHome={setHome} categoryResponse={categoryResponse} logoResponse={logoResponse} isTranslated={isTranslated} translate={translate} responseT={responseT} client_id={client_id} token={token} idT={idT} setHomeWrongToken={setHomeWrongToken} backToHome={backToHome}/>
            return <PartnerNew isUserOnboardingDemo={isUserOnboardingDemo} setHome={setHome} appsSettingsResponse={appsSettingsResponse} categoryResponse={categoryResponse} logoResponse={logoResponse} isTranslated={isTranslated} translate={translate} responseT={responseT} client_id={client_id} token={token} idT={idT} setHomeWrongToken={setHomeWrongToken} backToHome={backToHome} isJulo={isJulo}/>
        }
        else if(ui == '2'){
            return <Partner2 setHome={setHome} isTranslated={isTranslated} translate={translate}/>
        }
        else{
            return <Partner3 setHome={setHome} isTranslated={isTranslated} translate={translate}/>
        }
        // return <Partner/>;
        /*
        document.getElementsByClassName('goog-te-combo')[0].firstElementChild.addEventListener('click', function (e) {
            document.getElementsByClassName('goog-te-combo')[0].options[1].selected = true;
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent('change', true, true ); // event type,bubbling,cancelable
            return !document.getElementsByClassName('goog-te-combo')[0].firstElementChild.dispatchEvent(evt);
        });
        */
    }

    return ( 
        <div>
            <div>
                <nav className="">
                    {/* <div className="container"> */}
                        <img src={require('../components/images/images/powercred_logo_2.png')} alt="powercred_logo" height="60" className='pwc-logo' />
                        {/* <div className="navbar-brand" id="google_translate_element_i"></div> */}
                    {/* </div> */}
                    
                </nav>
                {/* <div className='menu-icon'>
                    <div className='menu-icon-row'></div>
                    <div className='menu-icon-row'></div>
                    <div className='menu-icon-row'></div>
                </div> */}
                <div className="google-translate">
                    <div className={`notranslate google-translate-btn google-translate-en ${!isTranslated ? 'google-translate-btn-selected' : ''}`} onClick={() => translate('en')}> EN </div>
                    <div className={`notranslate google-translate-btn ${isTranslated ? 'google-translate-btn-selected' : ''}`}  onClick={() => translate('id')}> ID </div>
                </div>    
            </div>
            {/* {
                partnerPage && <Partner setHome={setHome} isTranslated={isTranslated} translate={translate}/>
            } */}
            {
                !partnerPage && (
                    <div className="main-conatiner">
                        <div className="main-class-nui">
                            <h3 className='to-heading-nui'>{'{Company Name}'} uses Powercred to connect your account</h3>
                            <h4 className='to-heading2-nui'>{'{Company Name}'} does not have access to your account or be able to access your data without your consent</h4>
                            <div>
                                <div className='xyzq'>
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M6 6c0-3.311 2.689-6 6-6s6 2.688 6 6v4h3v14h-18v-14h3v-4zm14 5h-16v12h16v-12zm-13-5v4h10v-4c0-2.76-2.24-5-5-5s-5 2.24-5 5z"/></svg>
                                    {/* <img src={require('../components/images/powercred_logo.jpg')} alt="powercred_logo" height="40" /> */}
                                    <span>Data Security</span>
                                </div>
                                <div className='xyzq'>
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c-3.371 2.866-5.484 3-9 3v11.535c0 4.603 3.203 5.804 9 9.465 5.797-3.661 9-4.862 9-9.465v-11.535c-3.516 0-5.629-.134-9-3zm0 1.292c2.942 2.31 5.12 2.655 8 2.701v10.542c0 3.891-2.638 4.943-8 8.284-5.375-3.35-8-4.414-8-8.284v-10.542c2.88-.046 5.058-.391 8-2.701zm5 7.739l-5.992 6.623-3.672-3.931.701-.683 3.008 3.184 5.227-5.878.728.685z"/></svg>
                                    {/* <img src={require('../components/images/powercred_logo.jpg')} alt="powercred_logo" height="40" /> */}
                                    <span>Privacy Protected</span>
                                </div>
                                <div className='xyzq'>
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M23.621 9.012c.247.959.379 1.964.379 3 0 6.623-5.377 11.988-12 11.988s-12-5.365-12-11.988c0-6.623 5.377-12 12-12 2.581 0 4.969.822 6.927 2.211l1.718-2.223 1.935 6.012h-6.58l1.703-2.204c-1.62-1.128-3.582-1.796-5.703-1.796-5.52 0-10 4.481-10 10 0 5.52 4.48 10 10 10 5.519 0 10-4.48 10-10 0-1.045-.161-2.053-.458-3h2.079zm-7.621 7.988h-8v-6h1v-2c0-1.656 1.344-3 3-3s3 1.344 3 3v2h1v6zm-5-8v2h2v-2c0-.552-.448-1-1-1s-1 .448-1 1z"/></svg>
                                    {/* <img src={require('../components/images/powercred_logo.jpg')} alt="powercred_logo" height="40" /> */}
                                    <span>Password Protected</span>
                                </div>
                            </div>
                            <h4 className='to-heading3-nui'>
                                <span>
                                    Why connect with Powercred? Know more
                                    <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                    <div className='partner-info-icon-message'>
                                        PowerCred helps you leverage the power of your alternate data by enabling (company name) to gain access and insights from your consented data. This helps you gain access to credit and other financial services in a quick and hassle-free manner, without compromising on your data security and privacy.
                                    </div>
                                </span>
                            </h4>
                            <button type="button" className='btn nav-btn-pwc continue-btn' onClick={() => setPartnerPage(true)}>
                                CONTINUE
                            </button>
                            <h5 className='to-heading4-nui'>By clicking on continue you accept <a href='https://www.powercred.io/terms-of-service' className="a_master_cb" target="_blank">Terms of service</a> and <a href='https://www.powercred.io/privacy-policy' className="a_master_cb" target="_blank">Privacy policies</a>  of Powercred</h5>
                            <h6 className='to-heading5-nui'>
                                Registered by Kominfo
                                <img src={require('../components/images/images/kominfo.png')} alt="kominfo_logo" height="35" className='kominfo-logo' />
                            </h6>
                        </div>
                    </div>
                )
            }
            
            {/* {isLoading && <FullPageLoader />}
            {isError && <ErrorModal closeResponse={closeResponse} errorMessage={errorMessage}/>}
            {isSuccess && <Success closeResponse={closeSuccess}/>}
            {message && <Message message={message} title={title} closeMessage={closeMessage}/>}
            {showConfirmModal && <Confirmation confirmed={setHome} closeMessage={() => setShowConfirmModal(false)} message='Are you sure you want to go back and cancel current process?'/>}
            {showOTPModal && <OTPModal index={showOTPModal.index} eCommerce={showOTPModal.eCommerce} updateParamsOTP={updateParamsOTP} close={() => setShowOTPModal(false)}/>} */}
        </div>
     );
}
 
export default Home;