import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IdCapture from '../../Widget/idCapture/idCapture.widget';
import LocalstorageService from '../../services/localstorage.service';
import { verifySession } from '../../services/verify.service';
import { uploadNPWP} from '../../services/npwp.service';
import { Toast } from 'primereact/toast';
import './npwp.component.css';
import BrandBadge from '../../Widget/BrandBadge/brandBadge.widget';

const NPWP = ({ back, onFormSuccess }) => {

    const toast = useRef(null);
    const { t } = useTranslation();
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isAllowEdit, setIsAllowEdit] = useState(false);
    const [npwpPhoto, setNpwpPhoto] = useState(null);
    const [npwpRaw, setNpwpRaw] = useState(null);
    const [mail, setMail] = useState('');
    
    const [isClientJulo, setClientJulo] = useState(false);

    useEffect(() => {
        let localstorageService = new LocalstorageService();
        const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        if (JuloList.includes(apiKey)){ setClientJulo(true); }

        const handleResize = () => {
            if (window.innerWidth < 577) {
                setIsMobileScreen(true);
            } else {
                setIsMobileScreen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleMailChange = (e) => {
        setMail(e.target.value);
    }

    // #region Notification Handlers
    const showSuccess = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
    }
    const showError = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 6000 });
    }
    const showWarn = (message) => {
        toast.current.show({ severity: 'warn', summary: message, life: 3000 });
    }
    // #endregion Notification Handlers

    const handleAutoSubmit = async () => {
        
        const verification = await verifySession();
        if (verification) {
            if (verification.status === "failed") {
                window.location.reload();
                return
            }
        }
        setIsDataLoading(true);
        const localStorageService = new LocalstorageService();
        try {
            const formData = new FormData();
            formData.append('npwp_photo', npwpPhoto);
            showWarn(t('form.waitMsg'));
            const response = await uploadNPWP(formData, localStorageService.getItem('id', false, 'tokenVerification'), mail, localStorageService.getItem('user_id', false, 'tokenVerification'));
            await new Promise(r => setTimeout(r, 2000));
            if (!response) {
                showError(response.error);
                setIsDataLoading(false);
                setIsAllowEdit(false);          
            }else if(response.detail){
                if (response.detail === "Invalid document! Please upload a valid NPWP document with good clarity."){
                    showError(t('form.response'));
                }
                else{
                    showError(response.detail);
                }
                setIsDataLoading(false);
                setIsAllowEdit(false);
            }
            else{
                setIsDataLoading(false);
                showSuccess(t('form.submited'));
                onFormSuccess();
                setIsAllowEdit(false);
            }         
        } catch (error) {
            showError(t('form.connectionIssue'));
            setIsDataLoading(false);
            setIsAllowEdit(false);
        }
    };

    const handleSubmitForm = async () => {

        const verification = await verifySession();
        if (verification) {
            if (verification.status === "failed") {
                window.location.reload();
                return
            }
        }
        setIsDataLoading(true);    
    };

    const handleNpwpCapture = async (image, imageRaw) => {
        let fileType = imageRaw?.type;
        if(fileType) {
            setNpwpRaw(image);
            const file = new File([imageRaw], `${imageRaw.name}`, { type: `${imageRaw.type}` });
            setNpwpPhoto(file);   
        }else{
            setNpwpPhoto(null);   
        }
    }

    const handleFormSubmit = () => {
        if (npwpPhoto && (mail !== '')) {
            handleAutoSubmit();
        }
    };

    const formRender = () => {
        return (
            <div className="info-p info-p-n info-p-n-m" style={{ textAlign: 'center' }}>
                <div className="info-inner-p" ></div>
                <div className="form-p" style={
                    {
                        "paddingBottom": "6rem"
                    }
                }>
                    <div>
                        <label className="scl ">{t('selectPartnerPage.NPWP.NPWP_IMG')}
                            {/* <InfoButton message={<div>{t('selectPartnerPage.NPWP.NPWP_IMG')}</div>} /> */}
                        </label>

                        {
                            isAllowEdit ? (
                                <div className="input-p input-f-p1" disabled>
                                    <div role="presentation" tabIndex={0} className="form-control ddrop-div">
                                        <div>
                                            <img src={npwpRaw} alt="Captured Image" />
                                        </div>
                                    </div>
                                </div>
                            ) : (<IdCapture onCapture={handleNpwpCapture} ComponentType={"NPWP"} />)
                        }

                    </div>

                    <div style={{ paddingTop: '30px' }}>
                        <label className="scl">E-Mail
                            {/* <InfoButton message={<div>NIK</div>} /> */}
                        </label>

                        <div className="input-p">
                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                <input type="email" id="mail" className="form-control" placeholder="E-Mail" name="mail"
                                    disabled={isAllowEdit}
                                    value={mail}
                                    onChange={handleMailChange}
                                />
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="partner-page-form-n">
                <div>
                    <h3 className="title-p title-p-p1 title-p-n">
                        NPWP Identity Data
                    </h3>
                    {formRender()}
                </div>
                {/* a small message here, center alignment and red color to say verify your details before submit, it should be just above the submit button*/}

                <div className="btn-div-p-n">
                    <div className="back-btn-pp back-btn-pp-n btn nav-btn-pwc next-btn" style={isMobileScreen ? {} : { display: 'none' }} onClick={back}>
                        {t('form.back')}
                    </div>
                    <div className="buttons-bottom-div">
                        <button type="submit" className="btn nav-btn-pwc next-btn btn-n btn-next-partner-n"
                            disabled={isDataLoading || !(npwpPhoto && mail !== '')
                               }
                            onClick={!isAllowEdit ? handleFormSubmit : handleSubmitForm}
                        >{t('form.submit')} {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}</button>
                    </div>
                </div>
                {isMobileScreen && <BrandBadge option={"footer"} />}
            </div>
        </>
    );
}

export default NPWP;