import { useEffect, useState } from "react";
import Integrations from "./Integrations";
import Verification from "./Verification";
import WidgetHome from '../Widget/Home';
import axios from 'axios';
import FullPageLoader from '../components/FullPageLoader';
import HomeNewUi from '../newUi/Home';
import ErrorModal from "../newUi/ErrorModal";
import { useNavigate, useLocation } from 'react-router-dom';
import LocalstorageService from '../services/localstorage.service';
import { TriggerJuloEvent } from '../services/appscript.service';

const Home = () => {
    // console.log("Project: " + process.env.PROJECT);
    // console.log("Url: " + process.env.URL);
    // console.log("NODE_ENV: " + process.env.NODE_ENV)
    // console.log("env var: " + process.env.REACT_APP_PROJECT)
    const [page, setPage] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [developerSession, setDeveloperSession] = useState(false);
    const setHome = () => setPage(null);
    const handleConfirmModal = (bool) => setShowConfirmModal(bool);
    const [isJulo, setIsJulo] = useState(false);

    useEffect(async () => {
        const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
        let localstorageService = new LocalstorageService();
        const appKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        if (JuloList.includes(appKey)) {
            setIsJulo(true);
        } else {
            setIsJulo(false);
        }
    }, []);

    const addBackBtn = (root) => {
        const widgetRoot = document.getElementById(root);
        let div = document.createElement('div');
        div.innerHTML = `
            <button type="button" class='btn nav-btn-pwc back-btn'>
                Back
            </button>
        `;
        div = div.firstElementChild;
        div.addEventListener('click', () => handleConfirmModal(true));
        widgetRoot.appendChild(div);
    };

    // const location = useLocation();
    // location.pathname

    const isProduction = window.location.href.includes('https://auth.powercred.io') || window.location.href.includes('https://docs.julo.co.id');
    const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(true);
    const [isWrongToken, setIsWrongToken] = useState(false);
    const [response, setResponse] = useState(null);
    const [id, setId] = useState(null);
    const [client_id, setClient_id] = useState(null);
    const [user_id, setuser_id] = useState(null);

    const [categoryResponse, setCategoryResponse] = useState(null);
    const [appsSettingsResponse, setAppsSettingsResponse] = useState(null);
    const [backgroundResponse, setBackgroundResponse] = useState(null);
    const [logoResponse, setLogoResponse] = useState(null);

    const [isUserOnboardingDemo, setIsUserOnbordingDemo] = useState();

    const developerMode = (option) => {
        const localStorageService = new LocalstorageService();
        if (option) {
            localStorageService.init();
            setDeveloperSession(true);
            localStorageService.setItem('developer', 'true');
            window.id = localStorageService.getItem('id', false, 'tokenVerification');
            window.session = localStorage.getItem('tokenVerification');
            return "Developer Mode Enabled"
        } else {
            localStorageService.kill();
            setDeveloperSession(false);
            delete window.id;
            delete window.session;
            return "Developer Mode Disabled"
        }
    };

    useEffect(() => {
        window.developerMode = developerMode;
        return () => {
            delete window.developerMode;
        };
    }, []);

    const setHomeWrongToken = async () => {
        setPage(null)
        const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
        let localstorageService = new LocalstorageService();
        const appKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        if (JuloList.includes(appKey)) { await TriggerJuloEvent(12); }
        setIsWrongToken(true);
        setLoading(false);
    };

    const mapProduct = product => {
        let partners = {
            "mokapos": 'mokapos',
            "plncheck": 'plncheck',
            "instagram": 'instagram',
            "tiktok": 'tiktok',
            "gopay": 'gopay',
            "blibli": 'blibli',
            "shopeeverification": 'shopeeVerification',
            "tokopedia": 'tokopedia',
            "lazada": 'lazada',
            "jurnal": 'jurnal',
            "location": 'location',
            "xero": 'xero',
            "shopee": 'shopee',
            "Ovo": 'ovo',
            "bankconnect-verification": 'bankVerification',
            "bank-connect": 'bankconnect',
            "altconnect": 'altconnect',
            "TokopediaVerification": 'tokopediaVerification',
            "accurate": 'accurate',
            "quickbooks": 'quickbooks',
            "identity": "identity",
            // "finfini-ocr": 'finfini-ocr',
            "bank-ocr-upload": "bank-ocr-upload",
            // "BcaEStatement": "bca-ocr",
            // "MandiriEStatement": "mandiri-ocr",
            // "BriEStatement": "bri-ocr",
            // "BniEStatement": "bni-ocr"
            "telco": "telco",
            "npwp": "npwp"
        };
        product = product.map(p => partners[p]);
        return product;
    };

    const callCategory = async (appIDVariable) => {
        const config = {
            method: 'GET',
            url: `https://onboarding-sog3xlfxxq-et.a.run.app/category/get?app_id=${appIDVariable}`,
        };
        try {
            let response = await axios(config);
            response = response.data;
            setCategoryResponse(response);
        }
        catch (e) {
            console.log('error ', e);
            // error case ???
        }
    };

    const callAppsSettings = async (appIDVariable, apikey) => {
        const config = {
            method: 'GET',
            // baseURL,
            url: `https://dev.powercred.io/ui/edit/app/settings/get?app_id=${appIDVariable}&apikey=${apikey}`,
        }
        try {
            let response = await axios(config);
            response = response.data;
            setAppsSettingsResponse(response);
        }
        catch (e) {
            console.log('error ', e);
            // error case ???
        }
    }

    const callBackground = async (appIDVariable) => {
        const config = {
            method: 'GET',
            url: `https://onboarding-sog3xlfxxq-et.a.run.app/background/get?app_id=${appIDVariable}`,
        };
        try {
            let response = await axios(config);
            response = response.data;
            setBackgroundResponse(response);

            // const root = document.querySelector(':root');

            // if(response.type == "color"){
            //     root.style.setProperty('--r-bgc', response.value); 
            //     root.style.setProperty('--r-bgi', "none"); 

            //     // document.getElementById('root').style.backgroundColor = response.value;
            //     // document.getElementById('root').style.backgroundImage = "none";
            // }
            // if(response.type == "image"){
            //     root.style.setProperty('--r-bgc', "none"); 
            //     root.style.setProperty('--r-bgi', `url(${response.value})`); 
            //     root.style.setProperty('--r-bgs', 'cover'); 

            //     // document.getElementById('root').style.backgroundColor = "none";
            //     // document.getElementById('root').style.backgroundImage = `url(${response.value})`;
            //     // document.getElementById('root').style.backgroundSize = 'cover';
            // }

        }
        catch (e) {
            console.log('error ', e);
            // error case ???
        }
    }

    const callLogo = async (appIDVariable) => {
        const config = {
            method: 'GET',
            url: `https://onboarding-sog3xlfxxq-et.a.run.app/logo/get?app_id=${appIDVariable}`,
        };
        try {
            let response = await axios(config);
            response = response.data;
            setLogoResponse(response);
        }
        catch (e) {
            console.log('error ', e);
            // error case ???
        }
    }

    const callFonts = async (appIDVariable) => {
        const config = {
            method: 'GET',
            url: `https://onboarding-sog3xlfxxq-et.a.run.app/font/get?app_id=${appIDVariable}`,
        };
        try {
            let response = await axios(config);
            response = response.data;

            if (!response || response == 'false') {
                throw 'no font selected';
            }

            // const googleFontLink = document.createElement('div');
            // googleFontLink.innerHTML = `<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=${response.family}">`;
            // document.head.appendChild(googleFontLink.firstElementChild);

            // // document.getElementsByTagName('body')[0].style.fontFamily = response.family;
            // // document.getElementsByTagName('body')[0].style.color = response.color_code;

            // const root = document.querySelector(':root'); 

            // root.style.setProperty('--body-fc', response.color_code); 
            // root.style.setProperty('--body-ffm', response.family); 

            // if(response.right_tile.font_color != "default"){
            // }

            // let docs = document.getElementsByClassName('partner-info-icon-message');
            // for(let i = 0; i < docs.length; i++){
            //     docs[i].style.color = response.color_code;
            // }
            // docs = document.getElementsByClassName('search-partner-category');
            // for(let i = 0; i < docs.length; i++){
            //     docs[i].style.color = response.color_code;
            // }

        }
        catch (e) {
            console.log('error ', e);
            // error case ???
        }
    }

    const callCards = async (appIDVariable) => {
        const config = {
            method: 'GET',
            url: `https://onboarding-sog3xlfxxq-et.a.run.app/card/settings/get?app_id=${appIDVariable}`,
        };
        try {
            let response = await axios(config);
            response = response.data;
            // setBackgroundResponse(response);

            const root = document.querySelector(':root');

            if (response.right_tile.font_color != "default") {
                root.style.setProperty('--partner-input-fc', response.right_tile.font_color);
            }
            if (response.right_tile.background_color != "default") {
                root.style.setProperty('--partner-input-bgc', response.right_tile.background_color);
            }
            if (response.left_tile.font_color != "default") {
                root.style.setProperty('--partner-container-fc', response.left_tile.font_color);
            }
            if (response.left_tile.background_color != "default") {
                root.style.setProperty('--partner-container-bgc', response.left_tile.background_color);
            }
            if (response.left_tile.partners_background != "default") {
                root.style.setProperty('--partner-container-partner-bgc', response.left_tile.partners_background);
            }
        }
        catch (e) {
            console.log('error ', e);
            // error case ???
        }
    }

    const callAllData = async (appIDVariable, apikey) => {
        const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
        let localstorageService = new LocalstorageService();
        const appKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        if (JuloList.includes(appKey)) {
            setIsJulo(true);
            await TriggerJuloEvent(1);
        } else {
            setIsJulo(false);
        }

        const config = {
            method: 'GET',
            // baseURL,
            url: `https://dev.powercred.io/ui/edit/data/get?app_id=${appIDVariable}&apikey=${apikey}`,
        };
        try {
            let response = await axios(config);
            response = response.data;
            if (!response) {
                throw 'error';
            }
            const responseFull = response;

            const root = document.querySelector(':root');

            response = responseFull.card_settings;
            if (response) {
                if (response.right_tile.font_color != "default") {
                    root.style.setProperty('--partner-input-fc', response.right_tile.font_color);
                }
                if (response.right_tile.background_color != "default") {
                    root.style.setProperty('--partner-input-bgc', response.right_tile.background_color);
                }
                if (response.left_tile.font_color != "default") {
                    root.style.setProperty('--partner-container-fc', response.left_tile.font_color);
                }
                if (response.left_tile.background_color != "default") {
                    root.style.setProperty('--partner-container-bgc', response.left_tile.background_color);
                }
                if (response.left_tile.partners_background != "default") {
                    root.style.setProperty('--partner-container-partner-bgc', response.left_tile.partners_background);
                }
            }

            response = responseFull.fonts;
            if (response) {
                if (response.family != 'default' && response.family != "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif") {
                    const googleFontLink = document.createElement('div');
                    googleFontLink.innerHTML = `<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=${response.family}">`;
                    document.head.appendChild(googleFontLink.firstElementChild);
                }
                root.style.setProperty('--body-fc', response.color_code == 'default' ? root.style.getPropertyValue('--d-body-fc') : response.color_code.replace('%23', '#'));
                root.style.setProperty('--body-ffm', response.family == 'default' ? root.style.getPropertyValue('--d-body-ffm') : response.family);
            }

            response = responseFull.background;
            if (response) {
                setBackgroundResponse(response);
                if (response.value == "default") {
                    root.style.setProperty('--r-bgc', "none");
                    root.style.setProperty('--r-bgi', root.style.getPropertyValue('--d-r-bgi'));
                    root.style.setProperty('--r-bgs', root.style.getPropertyValue('--d-r-bgs'));
                }
                else if (response.type == "color") {
                    root.style.setProperty('--r-bgc', response.value.replace('%23', '#'));
                    root.style.setProperty('--r-bgi', "none");
                }
                else if (response.type == "image") {
                    root.style.setProperty('--r-bgc', "none");
                    root.style.setProperty('--r-bgi', `url(${response.value})`);
                    root.style.setProperty('--r-bgs', 'cover');
                }
            }

            response = responseFull.logo;
            if (response) {
                setLogoResponse(response.value);
            }

            response = responseFull.app_settings;
            if (response) {
                setAppsSettingsResponse(response);
            }

            setCategoryResponse({ 'categories': responseFull.categories, 'headers': responseFull.headers });
        }
        catch (e) {
            // console.log('error ', e);
            // error case ???
            setAppsSettingsResponse({
                "callback_url": null,
                "verification_step": false,
                "redirect_url": null
            });

            setCategoryResponse({
                'categories': {
                    "accounting": "Accounting & POS",
                    "ecommerce": "E-Commerce",
                    "social": "Social",
                    "retail_banking": "Retail Banking",
                    "corporate_banking": "Corporate Banking",
                    "payments": "E-Wallets"
                },
                'headers': {
                    "search_text": "",
                    "header_text": "",
                    "search_type": "default",
                    "header_type": "default"
                }
            });
        }
    }

    function isNumeric(value) {
        return /^\d+$/.test(value);
    }

    const callTelco = async (client_id, id, user_id) => {
        try {
            const config = {
                method: 'POST',
                url: `/telco/insights`,
                baseURL,
                params: {
                    id: id,
                    apikey: client_id,
                    phone_number: user_id
                },
                headers: { 'Content-Type': 'application/json' }
            };
            let response = await axios(config);
        }
        catch (e) {
            console.log('error calling telco', e);
        }
    }

    const getAPIProducts = async (client_id, id, isError, user_id) => {
        const config = {
            method: 'POST',
            url: `/auth/token/profile?apikey=${client_id}&id=${id}`,
            baseURL,
        };
        try {
            setLoading(true);
            let response = await axios(config);

            let appIDVariable = response.data.application_name;
            // appIDVariable = 'df321b92-91a2-46b6-af18-c42f09a753cf'; // for testing
            if (isError) {
                await callAppsSettings(appIDVariable, client_id);
                const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
                let localstorageService = new LocalstorageService();
                const appKey = localstorageService.getItem('client_id', false, 'tokenVerification');
                if (JuloList.includes(appKey)) { await TriggerJuloEvent(12); }
                setIsWrongToken(true);
                setLoading(false);
                return;
            }
            
            try{
                const localstorageService = new LocalstorageService();
                localstorageService.init();
                localstorageService.removeItem("product_list");
                localstorageService.setItem(
                    "product_list",
                    response.data.api_product_list_json
                );
            } catch(e){
                console.log('');
            }

            response = mapProduct(response.data.api_product_list_json);
            setResponse(response);

            if (response.find(product => product === 'telco' && isNumeric(user_id))) {
                callTelco(client_id, id, user_id);
            }
            // callFonts(appIDVariable);
            // callCategory(appIDVariable);
            // callAppsSettings(appIDVariable, client_id);
            // callCards(appIDVariable);
            // callBackground(appIDVariable);
            // callLogo(appIDVariable);

            await callAllData(appIDVariable, client_id);
            setLoading(false);
        }
        catch (e) {
            console.log('error ', e);
            const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
            let localstorageService = new LocalstorageService();
            const appKey = localstorageService.getItem('client_id', false, 'tokenVerification');
            if (JuloList.includes(appKey)) { await TriggerJuloEvent(12); }
            setIsWrongToken('error in getting product list');
            setLoading(false);
        }
    };

    const verifytoken = async (token, client_id, id, user_id) => {
        const config = {
            method: 'post',
            url: `/auth/token/verify?apikey=${client_id}&client_id=${client_id}&access_token=${token}`,
            baseURL,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        let error;
        let response;
        try {
            response = await axios(config);
            // console.log(response.data);
            if (response.data.status == 'success') {
                error = false;
            }
            else {
                error = true;
            }
        }
        catch (e) {
            console.log('error ', e);
            error = true;
        }

        if (!error) {
            // response = {
            //     'api_product_list_json': [
            //         'jurnal',
            //         'location',
            //         'xero',
            //         'ovo',
            //         'gopay',
            //         'blibli',
            //         'lazada',
            //         'tokopedia',
            //         'instagram',
            //         'mokapos'
            //     ],
            //     'client_id': 'iiV0pirSZpnKudtQX4MCrNgAHbUTAnOwOSQM4BOfVrfwUqyp'
            // };
            // setResponse(response);
            await getAPIProducts(client_id, id, undefined, user_id);
        }
        else {
            await getAPIProducts(client_id, id, true, user_id);
            // setIsWrongToken(true);
        }
    };

    const navigate = useNavigate();

    const closeResponse = (isAfterSubmit) => {
        if (appsSettingsResponse && appsSettingsResponse['callback_url']) {
            window.open(appsSettingsResponse['callback_url'], '_self');
        }
        else {
            if (client_id == 'Cmvog9nUcRPtFTJlczrGKVAHe8oBftTAQNq5kbZjYRiUlkRV') { // bnc
                window.open(isProduction ? 'https://bnc.powercred.io/' : 'https://bnc-auth-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'iiV0pirSZpnKudtQX4MCrNgAHbUTAnOwOSQM4BOfVrfwUqyp') { //pwc
                window.open(isProduction ? 'https://sandbox.powercred.io/' : 'https://pwc-auth-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '2Q9xJ4G4LyPAb1sbgz8srSUeaGSMu8du2burNWj8tMfOhUuX') { // finku
                window.open(isProduction ? 'https://finku.powercred.io/' : 'https://finku-auth-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'kl4wA1TAmZHPTMGtaCE8n4EyifZ4KkKxaMt8rMIvEPLMBqc3') { // djoin
                window.open(isProduction ? 'https://djoin.powercred.io/' : 'https://djoin-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'YdLkX3PmIX7PjeIQdYNkj7i1WYWaXcm7RK6xVhxmB49enrAC') { // KW-IL
                if (isAfterSubmit) {
                    window.top.postMessage('powercred-submit', '*');
                }
                else {
                    window.top.postMessage('powercred-back', '*');
                }
                // window.close();
                //if(isAfterSubmit){
                //    window.open('https://ils-207-testing-new-powercred-api.d35woqr13rmmzt.amplifyapp.com/en/connect-account', '_self');
                //}
                //else{
                //    window.open('https://inl-dc.koinworks.com/connect-account', '_self');
                //}
            }
            else if (client_id == 'oAcrhKq2ibD6TIyLEcTpj1CDsCsAQIURm3GMm8vPpbwbNmQp') { // akseleran productive
                window.open(isProduction ? 'https://akseleran-productive.powercred.io/' : 'https://akseleran-productive-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'hCYq8W1CY7OhmcrsYyqmwktXEit7kZityarqassZUAAtfxiH') { // akseleran consumptive
                window.open(isProduction ? 'https://akseleran-consumptive.powercred.io/' : 'https://akseleran-consumptive-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS') { //pwc
                window.open(isProduction ? 'https://www.julo.co.id/blog/data-kamu-aman-dengan-JULO?in_app=true' : 'https://julo.powercred.io/', '_self');
            }
            else if (client_id == 'qSVVoCD5JltQSmWdbKRVgFDybZ025LU0') { //pwc
                window.open(isProduction ? 'https://getmo.powercred.io/' : 'https://getmo-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '1lsrgN6t0kgCGZMzX1UIwTslAEYpEjfTfNqDGmC517DQfrHJ') { //pwc
                window.open(isProduction ? 'https://oto.powercred.io/' : 'https://oto-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'OaPvxBVKMx1q8LazyTzRFnDRCyr6Latfa3ZFUzp7wZ5Snep4') { //pwc
                window.open(isProduction ? 'https://skbf-fleet.powercred.io/' : 'https://skbf-fleet-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'BMMucXWtPGE2lfMzxBHL22PQs6XfrmT10nZOCVPYOCcB75hr') { //pwc
                window.open(isProduction ? 'https://skbf-retail.powercred.io/' : 'https://skbf-retail-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'qTdmH558T9gbsTAwiTJutzssDFMbZ7hDGDL3jWBwnjQ4S16Z') { //pwc
                window.open(isProduction ? 'https://ojk.powercred.io/' : 'https://ojk-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '26rACXFTtLjlrjihY85kx0zFMrvIlHA0HVulQlMbKGULanJg') { //pwc
                window.open(isProduction ? 'https://semaai.powercred.io/' : 'https://semaai-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'laLAwpAWC37bNPLToBNyNkZeEkPSeKJHfxQHOPApnkdBJlBP') { //pwc
                window.open(isProduction ? 'https://gandengtangan.powercred.io/' : 'https://gandengtangan-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '7YN8BWX9uNB6fgHOYLaTNRN8ueHeXb12bE7sDQd8xPS1W9RP') { //pwc
                window.open(isProduction ? 'https://akseleran-ecommerce.powercred.io/' : 'https://akseleran-ecommerce-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'a7VsRAW3g13sAoCuSkfMI3vmKE1vGG1UWrjBIWAXa6wyATAW') { //pwc
                window.open(isProduction ? 'https://danasyariah.powercred.io/' : 'https://danasyariah-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '8ScI2X6ynbGOnTlqh5YAkwtNeyZpqAapszPe4lAGdGmdAHEu') { //pwc
                window.open(isProduction ? 'https://komunal.powercred.io/' : 'https://komunal-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'CsMc3mrrJ1GGTABffZr8iz86qq0WaoL43nBWUolw1li0I4N0') { //pwc
                window.open(isProduction ? 'https://sumsub.powercred.io/' : 'https://sumsub-sog3xlfxxq-et.a.run.app/', '_self');
            }
            
            else if (client_id == 'ZRCRR9Eo92iYE6KqPUjk6n1p7b6pHZXXIKpo0E2WabPSDg3l') { //pwc
                window.open(isProduction ? 'https://aktivaku.powercred.io/' : 'https://aktivaku-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'iZ1gYY5fcHeN4JZPnxbW9JEpiL1csZ1BYT5lBdgeMyqAGHAR') { //pwc
                window.open(isProduction ? 'https://saison.powercred.io/' : 'https://saison-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '1U6hAe8YEGZVoqdDavD9ADx6xcCm6YvShZz14zEzmyOLD2yB') { //pwc
                window.open(isProduction ? 'https://muf.powercred.io/' : 'https://muf-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'Ff2XUdnwDAFVwRuGjpeGw0wRnXpcDKJ25Hp7JJVxpGsuJTQQ') { //pwc
                window.open(isProduction ? 'https://credable.powercred.io/' : 'https://credable-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'jG9aOIDec21qfd5b2975Sz3J7C20mRHQvTU1S8io4YER4bin') { //pwc
                window.open(isProduction ? 'https://fs-309734996493.asia-southeast1.run.app' : 'https://fs.powercred.io/', '_self');
            }
            else if (client_id == 'AuJyLAMXqsdif7lnoy2TJZLBKMovZJF9GPqrLIHAMVERXj70') { //pwc
                window.open(isProduction ? 'https://boost-id.powercred.io/' : 'https://myboost-indo-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'g5PSMLwHV2tl6c2aWI8HNoMqAHq0GbJ2DLqtSwl8ejMB2TWS') { //pwc
                window.open(isProduction ? 'https://maucash.powercred.io/' : 'https://maucash-sog3xlfxxq-et.a.run.app/', '_self');
            }
            
            else if (client_id == 'ckeqG0yGGT48P0F0d2WPcYkJTqijr9xvtC621OYE72MAAoAr') { //pwc
                window.open(isProduction ? 'https://mtf-fleet.powercred.io/' : 'https://mtf-fleet-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'gWeVzxpllSVeu8Tcl69A4AEmcY2WerO5yTpt03yKjW9JNVZA') { //pwc
                window.open(isProduction ? 'https://oto-consumer.powercred.io/' : 'https://oto-consumer-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '5w4trAKZQoRJo13DYyFqQUaTlenhsXF52tY5VflHY58amLyX') { //pwc
                window.open(isProduction ? 'https://mtf-retail.powercred.io/' : 'https://mtf-retail-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'G7EZqEGV0suLxkvaMpcBKjWN7XkV6OA3ac2CpIxnSChYeWvq') { //pwc
                window.open(isProduction ? 'https://gajigesa.powercred.io/' : 'https://gajigesa-sog3xlfxxq-et.a.run.app/', '_self');
            }
            
            else if (client_id == 'A7dMUJcSAAuEfoRR6x67lGST1UAMoGPujBaEwhFqkh82ofq1') { //pwc
                window.open(isProduction ? 'https://batumbu1.powercred.io/' : 'https://batumbu1-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'Cs5Gh0ZEBEhjbbxGGsNkCUe4cHkJFjUisA5u1X8XtAHcq2FU') { //pwc
                window.open(isProduction ? 'https://batumbu2.powercred.io/' : 'https://batumbu2-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'z7HtoUFcXEqwCTJRzoJ2zjkGH3ZEuHaiJgg5J1Q3w5GaCCZD') { //pwc
                window.open(isProduction ? 'https://batumbu3.powercred.io/' : 'https://batumbu3-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'VvlWGQHaBJTGOFNDLSfJttY6ZYeERpDF0lBXuLfbxH0xDugC') { //pwc
                window.open(isProduction ? 'https://batumbu4.powercred.io/' : 'https://batumbu4-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'Nrmz0AO8XRwyFIq6Mc2cr4oTyXBXqGqTkgv9W5AVguqkNS6a') { //pwc
                window.open(isProduction ? 'https://batumbu5.powercred.io/' : 'https://batumbu5-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '7BbeV94DtYSD9mMLFpyziF8MCC4dlUIzlYZnLfeTL6Qya3oR') { //pwc
                window.open(isProduction ? 'https://batumbu6.powercred.io/' : 'https://batumbu6-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'i0x7JhAQvhsYlYShnet4OZVzMRG1oMdzfZPb8IyDkryXo9q5') { //pwc
                window.open(isProduction ? 'https://moladin.powercred.io/' : 'https://moladin-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '3fcZTpYlBaG6pGrAkKwAsYaIqbti8x4qXHmNE7TA8Ugr58yI') { //pwc
                window.open(isProduction ? 'https://finpoc.powercred.io/' : 'https://fin-poc-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'm9DxeRbHkzwDtcd5YpP33ZL7Y2Nf3WCvvk0hT0I3UnutIy1P') { //pwc
                window.open(isProduction ? 'https://ebb.powercred.io/' : 'https://cimb-powercred-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'lr2LkC6LmvCUb5SvvOqmwHZQqfBKc5sZsMUDjMc3FuHqbn93') { //pwc
                window.open(isProduction ? 'https://arthaprimafinance.powercred.io/' : 'https://arthaprimafinance-sog3xlfxxq-et.a.run.app/', '_self');
            }
            
            else if (client_id == 'qWZtdmAaznW7ewseSLTQq2uyEN5uNPR42yBhcWeFtW9TaV1v') { //pwc
                window.open(isProduction ? 'https://astrapay.powercred.io/' : 'https://astrapay-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '9CGoHurAP9YBv2sv13IUvLAjZeZp7SHpG7GBTmHdoVAI9VGE') { //pwc
                window.open(isProduction ? 'https://buanafinance.powercred.io/' : 'https://buanafinance-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'GAetU5eOFE66hfBGYVJInPFH5NlEHhIPR8uiEMRv3kWEhE9d') { //pwc
                window.open(isProduction ? 'https://koinworks.powercred.io/' : 'https://koinworks-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '43YwY2cvMxdilNYfEb1SD2qof4wKbGrEzhsHaULMYbl4Txh9') { //pwc
                window.open(isProduction ? 'https://ajaib.powercred.io/' : 'https://ajaib-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'WAGgmc8MT2o5zFWFyMyxpBtR7YeTOCyurL0yKWZuHkPZroo0') { //pwc
                window.open(isProduction ? 'https://finfra.powercred.io/' : 'https://finfra-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == '3r3K4ALP0sxr3BZsMAEKiDmxJqGxO8gfA3Rl7BbB1Ipjyfuy') { //pwc
                window.open(isProduction ? 'https://bnifinance.powercred.io/' : 'https://bnifinance-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'j8xbvAAeOsjuJa85B0s704vjmxgnRf89jz7OtvxMqkpeQizS') { //pwc
                window.open(isProduction ? 'https://valdo.powercred.io/' : 'https://valdo-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'zhS0WHRl1vDR7yPW60jkHs7bGk5ruRdEPKoRBkTe5nuj2bA7') { //pwc
                window.open(isProduction ? 'https://bukuwarung.powercred.io/' : 'https://bukuwarung-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'TVbmrJP4yjONnXNTQFSAngR3TN5647Xwbo1Ys4sG4iyIv5ZY') { //pwc
                window.open(isProduction ? 'https://trustdecision.powercred.io/' : 'https://trustdecision-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'oO8A4GgadeTQBGg6iqClyWW9WcHYGrRNg64OAKzh2NYvqdLJ') { //pwc
                window.open(isProduction ? 'https://hana.powercred.io/' : 'https://hana-sog3xlfxxq-et.a.run.app/', '_self');
            }
            else if (client_id == 'CkbG6lNuxBTlTx13fvIuMGqzgE2k1mYBIw9rsxA0LgN8Ir0A') { //pwc
                window.open(isProduction ? 'https://nikel.powercred.io/' : 'https://nikel-sog3xlfxxq-et.a.run.app/', '_self');
            }
        }

        localStorage.removeItem('tokenVerification');
        localStorage.removeItem('connectedPartners');
        // navigate('/');
        // window.open('', '_self');
    };

    const setUserOnbording = e => {

        const root = document.querySelector(':root');

        if (e.target.id == 'card-settings-left-tile-background-color') {
            root.style.setProperty('--partner-container-bgc', e.target.value == 'default' ? root.style.getPropertyValue('--d-partner-container-bgc') : e.target.value);
        }
        else if (e.target.id == 'card-settings-left-tile-font-color') {
            root.style.setProperty('--partner-container-fc', e.target.value == 'default' ? root.style.getPropertyValue('--d-partner-container-fc') : e.target.value);
        }
        else if (e.target.id == 'card-settings-left-tile-partners-background') {
            root.style.setProperty('--partner-container-partner-bgc', e.target.value == 'default' ? root.style.getPropertyValue('--d-partner-container-partner-bgc') : e.target.value);
        }
        else if (e.target.id == 'card-settings-right-tile-background-color') {
            root.style.setProperty('--partner-input-bgc', e.target.value == 'default' ? root.style.getPropertyValue('--d-partner-input-bgc') : e.target.value);
        }
        else if (e.target.id == 'card-settings-right-tile-font-color') {
            root.style.setProperty('--partner-input-fc', e.target.value == 'default' ? root.style.getPropertyValue('--d-partner-input-fc') : e.target.value);
        }

        if (e.target.id == 'fonts-family' && root.style.getPropertyValue('--body-ffm') != e.target.value) {
            if (e.target.value != 'default') {
                const googleFontLink = document.createElement('div');
                googleFontLink.innerHTML = `<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=${e.target.value}">`;
                document.head.appendChild(googleFontLink.firstElementChild);
            }
            root.style.setProperty('--body-ffm', e.target.value == 'default' ? root.style.getPropertyValue('--d-body-ffm') : e.target.value);
        }
        else if (e.target.id == 'fonts-color-code') {
            root.style.setProperty('--body-fc', e.target.value == 'default' ? root.style.getPropertyValue('--d-body-fc') : e.target.value);
        }

        if (e.target.id == 'background-color-code') {
            root.style.setProperty('--r-bgc', e.target.value);
            root.style.setProperty('--r-bgi', "none");
        }
        else if (e.target.id == 'background-image-value' && root.style.getPropertyValue('--r-bgi') != e.target.value) {
            try {
                const url = URL.createObjectURL(e.target.value.get('background_image'));
                e.target.value = url;
            }
            catch {
            }
            root.style.setProperty('--r-bgc', "none");
            root.style.setProperty('--r-bgi', e.target.value == 'default' ? root.style.getPropertyValue('--d-r-bgi') : `url(${e.target.value})`);
            root.style.setProperty('--r-bgs', e.target.value == 'default' ? root.style.getPropertyValue('--d-r-bgs') : 'cover');
        }

        if (e.target.id == 'logo-value') {
            try {
                const url = URL.createObjectURL(e.target.value.get('company_logo'));
                e.target.value = url;
            }
            catch {
            }
            setLogoResponse(e.target.value);
        }

        if (e.target.id == 'header-text') {
            setCategoryResponse(categoryResponse => {
                categoryResponse.headers.header_text = e.target.value;
                return { ...categoryResponse };
            });
        }

        if (e.target.id == 'search-text') {
            setCategoryResponse(categoryResponse => {
                categoryResponse.headers.search_text = e.target.value;
                return { ...categoryResponse };
            });
        }

        if (e.target.id == 'categories-name') {
            setCategoryResponse(categoryResponse => {
                categoryResponse.categories = e.target.value;
                return { ...categoryResponse };
            });
        }

        if (e.target.id == 'verification-step') {
            setAppsSettingsResponse(appsSettingsResponse => {
                appsSettingsResponse.verification_step = e.target.value == 'false' ? false : true;
                return { ...appsSettingsResponse };
            });
        }
    }

    useEffect(async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('access_token');
        let id = params.get('id');
        let client_id = params.get('client_id');
        let user_id = params.get('user_id');
        user_id = user_id && user_id[0] === ' ' ? user_id.slice(1) : user_id;

        setIsUserOnbordingDemo(params.get('user_onboarding'));

        if (token && id && client_id) {
            setToken(token);
            setId(id);
            setClient_id(client_id);
            setuser_id(user_id);
            verifytoken(token, client_id, id, user_id);

            localStorage.setItem('tokenVerification', JSON.stringify({
                token,
                id,
                client_id,
                user_id
            }));
            localStorage.removeItem('connectedPartners');
            params.delete('access_token');
            params.delete('id');
            params.delete('client_id');
            params.delete('user_id');

            window.history.pushState({}, document.title, window.location.pathname);

        }
        else {
            let tokenVerification = localStorage.getItem('tokenVerification');

            if (tokenVerification == null) {
                tokenVerification = '{}'
            }
            let { token, id, client_id, user_id } = JSON.parse(tokenVerification);

            // console.log('from local storage',token, id, client_id);

            if (token && id && client_id) {
                setToken(token);
                setId(id);
                setClient_id(client_id);
                setuser_id(user_id);
                verifytoken(token, client_id, id, user_id);
            }
            else {
                const JuloList = ['h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS', 'zzmbOJpzVocGezUdcmnER5r4f6VpAVv93TEixwIXnL6gbV1i']
                let localstorageService = new LocalstorageService();
                const appKey = localstorageService.getItem('client_id', false, 'tokenVerification');
                if (JuloList.includes(appKey)) { await TriggerJuloEvent(12); }
                setIsWrongToken(true);
                setLoading(false);
            }
        }

        window.addEventListener('message', event => {
            if (event.data && event.data.message == 'set-user-onbording') {
                setUserOnbording(event.data);
            }
            // console.log("Message received from the parent: " + event.data); // Message received from parent
        });
    }, []);

    if (loading) {
        return (
            <FullPageLoader />
        );
    }
    if (isWrongToken) {
        return (
            <ErrorModal closeResponse={closeResponse} errorMessageToken={`Wrong/Expired token. You will be redirect to client's Home page`} />
        );
    }
    if (page == 'integration') {
        return <WidgetHome responseT={response} client_id={client_id} token={token} idT={id} setHomeWrongToken={setHomeWrongToken} partners={['instagram', 'lazada', 'shopee', 'blibli', 'jurnal', 'xero', 'location', 'tokopedia', 'bankconnect', 'altconnect', 'mokapos', 'plncheck', 'ovo', 'gopay']} setHome={setHome} />
        // return <Integrations setHome={setHome} showConfirmModal={showConfirmModal} handleConfirmModal={handleConfirmModal} addBackBtn={addBackBtn}/>;
    }
    else if (page == 'verification') {
        return <WidgetHome responseT={response} client_id={client_id} token={token} id={id} setHomeWrongToken={setHomeWrongToken} partners={['bankVerification', 'shopeeVerification', 'tokopediaVerification']} setHome={setHome} />
        // return <Verification setHome={setHome} showConfirmModal={showConfirmModal} handleConfirmModal={handleConfirmModal} addBackBtn={addBackBtn}/>;
    }
    return <HomeNewUi ui='1' isUserOnboardingDemo={isUserOnboardingDemo} appsSettingsResponse={appsSettingsResponse} categoryResponse={categoryResponse} logoResponse={logoResponse} responseT={response} client_id={client_id} token={token} idT={id} setHomeWrongToken={setHomeWrongToken} backToHome={closeResponse} isJulo={isJulo} />;

    return (
        <div className="selection-page">
            <h2>Select</h2>
            <div className="btn-container">
                <button className='btn nav_btn home-btn' onClick={() => setPage('integration')}>Transactions</button>
                <span className="middle-word">or</span>
                <button className='btn nav_btn home-btn' onClick={() => setPage('verification')}>Validations</button>
            </div>
        </div>
    );
}

export default Home;
