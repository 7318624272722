import { useLayoutEffect } from 'react';
import './reference.css';

const ShopeeReference = () => {
    useLayoutEffect(() => {
        document.getElementsByClassName('App')[0].style.overflow = 'scroll';
    }, []);

    return ( 
        <div className="reference-docs">
            <div className="container pad_15">
                <h4><u>How to Connect a Shopee Account successfully.</u></h4>
                <div className='container'>
                    <h5 className='padt_15'>
                        Steps to follow:
                    </h5>
                    <ol className='bsar_list'>
                        <li className='padb_7'>
                            First, the user will need to use either his email address or phone number to sign in to Power UI.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference1.jpg')} alt="shopee-reference1" />  
                            </div>
                        </li>
                        <li className='padb_7'>
                            Users will be logged on to the Power UI.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference2.jpg')} alt="shopee-reference2" />
                                <div className='reference-text-2'>Once logged on to Power UI, users will need to select Shopee (highlighted with blue border) and click on BERIKUTNYA (Next)</div>
                                <div className='reference-text-2'>Users then will be directed to Shopee sign in page where they will need to enter their username and password for Shopee.</div>
                            </div>
                        </li>
                        <li className='padb_7'>
                            User enters his Shopee login details(username/phone and password) and logs in.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference3.jpg')} alt="shopee-reference3" />
                            </div>
                        </li>
                        <li className='padb_7'>
                            Users will need to click on confirm authorization. Without confirming the authorization user’s Shopee account will not be connected successfully. 
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference4.jpg')} alt="shopee-reference4" />
                            </div>
                        </li>
                        <li className='padb_7'>
                            User will be redirected to the Power UI where he will have the option to verify the Shopee account and bank account number. 
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference5.jpg')} alt="shopee-reference5" />
                            </div>
                            <ol style={{ listStyleType: "upper-alpha", fontWeight: 'normal' }}>
                                <li className='padb_7'>
                                    If the user chooses skip.
                                    <div className='reference-text-2'>
                                        If the user chooses to skip this part, the user will get the following pop-up confirming his Shopee account has been linked successfully.
                                    </div> 
                                    <div>
                                        <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference6.jpg')} alt="shopee-reference6" />
                                    </div>
                                </li>
                                <li className='padb_7'>
                                    If the user chooses to verify bank account.
                                    <div className='reference-text-2'>
                                        If the user chooses to verify a bank account, the user will need to enter his Shopee username, password and bank account number to verify.
                                    </div> 
                                    <div>
                                        <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference7.jpg')} alt="shopee-reference7" />
                                    </div>

                                    <div className='reference-text-2'>
                                        Users will then need to select a preferred way to verify/authenticate either Whatsapp or sms and a link will be sent to them on their preferred platform.
                                    </div> 
                                    <div>
                                        <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference8.jpg')} alt="shopee-reference8" />
                                        <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference9.jpg')} alt="shopee-reference9" />
                                    </div>

                                    <div className='reference-text-2'>
                                        As soon as the user verifies the link, after a few seconds the user will receive the following pop-up on their screen confirming their account is connected.
                                    </div> 
                                    <div>
                                        <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference10.jpg')} alt="shopee-reference10" />
                                    </div>
                                </li>
                            </ol>
                        </li>
                        <li className='padb_7'>
                            Users will need to click on AJUKAN (Submit) to exit the PowerCred window/widget and receive the following pop-up thanking them for linking their account.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference11.jpg')} alt="shopee-reference11" />
                            </div>
                            <div className='reference-text-2'>
                                This will mark the end of the journey for the users.
                            </div>
                        </li>
                    </ol>
                </div>
                <h4 className='reference-main-heading'><u>Errors or Problems the Users might face while linking their Shopee accounts</u></h4>
                <div className='container'>
                    <ol className='bsar_list'>
                        <li className='padb_7'>
                            Invalid email address or invalid phone number.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference12.jpg')} alt="shopee-reference12" />
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference13.jpg')} alt="shopee-reference13" />
                            </div>
                            <div className='reference-text-2'>
                                Make sure the users are using the right email address or phone numbers to sign in.
                            </div>
                        </li>
                        <li className='padb_7'>
                            Users might just select Shopee on the Power UI and click on AJUKAN(Submit).
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference14.jpg')} alt="shopee-reference14" />
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference15.jpg')} alt="shopee-reference15" />
                            </div>
                            <div className='reference-text-2'>
                                You will need to advise and guide the users by using above mentioned steps to complete the journey successfully.
                            </div>
                        </li>
                        <li className='padb_7'>
                            The process has not been completed successfully, please try again.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference16.jpg')} alt="shopee-reference16" />
                                <div className='reference-text-2'>
                                    This simply means that the user just logged onto the shopee page and closed the pop-up window without authorizing. Users will need to click on confirm authorization after they login into their Shopee accounts. Use the above steps to complete the authorisation process explained in Step 4.
                                </div> 
                            </div>
                        </li>
                        <li className='padb_7'>
                            Unable to process the data, please try again!
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/shopee-reference17.jpg')} alt="shopee-reference17" />
                                <div className='reference-text-2'>
                                    Users may get this error due to a couple of reasons:
                                    <ol>
                                        <li>users may have not received the link to verify.</li>
                                        <li>user may have entered an incorrect contact number and due to this did not receive this link.</li>
                                        <li>users may actually have not clicked the link at all.</li>
                                    </ol>
                                    Guide user to switch the preferred platform (whatsapp or SMS) or ask the user to check if the Shopee user is correct or advise the user to click on the link to verify if he has not clicked already.
                                </div> 
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
     );
}
 
export default ShopeeReference;