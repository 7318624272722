import { Modal } from 'react-bootstrap';


const ErrorModal = (props) => {
    const { closeResponse, errorMessage } = props;
    errorMessage.body = JSON.parse(errorMessage.body);
    return ( 
        <div>
            <Modal 
                show={true}
                onHide={closeResponse}
                backdrop="static"
                keyboard={false}
                className="resmodal"
            >
                <Modal.Header>
                    <div className='icon-box danger_backcolor' onClick={closeResponse}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className='bi bi-x' viewBox="0 0 16 16">
                            <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/>
                        </svg>
                    </div>
                    <Modal.Title className='w-100' align="center">
                        {
                            errorMessage.head !== undefined ? errorMessage.head : 'Sorry!'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p className="mbody_text">
                        {
                            typeof errorMessage.body === 'object' && !Array.isArray(errorMessage.body) && errorMessage.body !== null ?
                            (
                                Object.keys(errorMessage.body).map(key => {
                                    let count = 0;
                                    count = count + 1
                                    console.log("count: ", count)
                                    return (
                                        <div>
                                            <span>
                                                {count}. "{key}": 
                                            </span>
                                            <span>
                                                {errorMessage.body(key)}
                                            </span>
                                        </div>
                                    )
                                })
                            ) : (
                                errorMessage.body !== undefined ? errorMessage.body : 'Unable to process data, please try again!'
                            )
                        }
                    </p>
                </Modal.Body>
            </Modal>
        </div>
     );
}
 
export default ErrorModal;