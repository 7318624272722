import {useEffect, useState } from 'react';
import {useLocation, Link} from 'react-router-dom';
import "../pages/Home.css";
import "../pages/JsonToHtml.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { JSONToHTMLTable } from './JsonToHtmlTable';


const Response = (props) => {
    const widget_full_res = props.widget_res.gateway_res;
    const { obj, goBack } = props;
    // widget_full_res['instagram'] = {'key': 'value'};
    // console.log(props);
    // const datapartners_data = useLocation();
    // const widget_full_res = datapartners_data.state.data.gateway_res;
    // const obj = datapartners_data.state.obj;
    
    const partnersName = {
        'instagram': 'Instagram',
        'lazada': 'Lazada',
        'shopee': 'Shopee',
        'blibli': 'Blibli',
        'xero': 'Xero',
        'location': 'Google Location',
        'tokopedia': 'Tokopedia',
        'bankconnect': 'Retail/Personal Banking', 
        'bankVerification': 'Bank Verification',
        'altconnect': 'Corporate Banking', 
        'shopeeVerification': 'Shopee Verification',
        'tokopediaVerification': 'Tokopedia Verification',
        'jurnal': 'Jurnal',
        'mokapos': 'Mokapos',
        'plncheck': 'PlnCheck',
        'ovo': 'Ovo',
        'gopay': 'Gopay',
    };
    const [isloadData, setLoadData] = useState(false);
    const [fetched, setFetched] = useState({});
    const [widgetResponse, setWidgetResponse] = useState(widget_full_res);
    const [pwc, setPwc] = useState(obj);
    
    const getResponse = async (code, isAuto) => {
        const response = await pwc.getProfileData(code);
        if(!response.isError && (response.data.status_code == undefined || response.data.status_code != 202)){
            console.log('fetched', code);
            const widgetResponseNew = {...widgetResponse};
            widgetResponseNew[code] = response.data;
            setWidgetResponse(widgetResponseNew);
            
            const fetchedNew = {...fetched};
            fetchedNew[code] = {...fetchedNew[code]};
            fetchedNew[code].isfetched = true;
            fetchedNew[code].showAlert = true;
            setFetched(fetchedNew);
        }
        else{
            console.log('fetching', code);

            if(isAuto !== false){
                setTimeout(() => getResponse(code), 5000);
            }
        }
    };

    const hideAlert = code => {
        const fetchedNew = {...fetched};
        fetchedNew[code] = {...fetchedNew[code]};
        fetchedNew[code].showAlert = false;
        setFetched(fetchedNew);
    };

    const handleRequest = code => {    
        const fetchedNew = {...fetched};
        fetchedNew[code] = {...fetchedNew[code]};
        fetchedNew[code].isRequested = true;
        setFetched(fetchedNew);

        getResponse(code, false);
        
        setTimeout(() => { 
            setFetched((fetched) => {
                if(fetched[code].isfetched){
                    return fetched;
                }
                const fetchedNew = {...fetched};
                fetchedNew[code] = {...fetchedNew[code]};
                fetchedNew[code].isRequested = false;
                return fetchedNew;
            });
        }, 10000);
    };

    useEffect(() => {
        const fetchedNew = Object.keys(widgetResponse).reduce((prev, code) => {
            prev[code] = { showAlert: false, isfetched: true, isRequested: false };
            if(widgetResponse[code].status_code && widgetResponse[code].status_code == 202 && false){
                prev[code].isfetched = false;
            }
            return prev;
        }, {});
        setFetched(fetchedNew);
    }, []);

    return ( 
        <div>
            <div className="container pad_15">
                <h4>Response from data partners</h4>
                <div className="row">
                    { !isloadData &&
                       <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 padt_15'>                            
                            <button type="button" className='btn nav_btn' onClick={() => setLoadData(true)} >
                                View data
                            </button>
                        </div>
                    }   

                    { isloadData && 
                        (
                            Object.keys(widgetResponse).length 
                            ?  
                            <div className='col-xs-12 pad_15'>
                                {
                                    Object.keys(widgetResponse).map(code => {
                                        // !fetched[code].isfetched && !fetched[code].isRequested && handleRequest(code);
                                        return (
                                            <div key={code}>
                                                {
                                                    fetched[code].showAlert && (
                                                        <div className="alert alert-success alert-dismissible fade show" role="alert" style={{marginTop: '0px', marginBottom: '0px'}}>
                                                            {partnersName[code]} has been added
                                                            <button type="button" className="btn-close" aria-label="Close" onClick={() => hideAlert(code)}></button>
                                                        </div>
                                                    )
                                                }
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item" id={`${code}-div`}>
                                                        <h2 className="accordion-header" id={`${code}Heading`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${code}CollapseOne`} aria-expanded="true" aria-controls={`${code}CollapseOne`}>
                                                                {partnersName[code]} Response
                                                            </button>
                                                        </h2>
                                                        <div id={`${code}CollapseOne`} className="accordion-collapse collapse" aria-labelledby={`${code}Heading`} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                {
                                                                    !fetched[code].isfetched ?
                                                                    // <p> Fetching the response, Please Wait...</p> 
                                                                    <div>
                                                                        {
                                                                            fetched[code].isRequested ?
                                                                            (
                                                                                <>
                                                                                    <p>Fetching...</p>
                                                                                    <div style={{display: 'inline-block', width: '200px'}}>
                                                                                        <button type="button" className='btn nav-btn-pwc' onClick={() => handleRequest(code, false)} disabled={fetched[code].isRequested}>Fetch</button>
                                                                                    </div>
                                                                                </>
                                                                            ) :
                                                                            (
                                                                                <>
                                                                                    <p>Data is processing. For fetching the response again, Click the fetch button </p>
                                                                                    <div style={{display: 'inline-block', width: '200px'}}>
                                                                                        <button type="button" className='btn nav-btn-pwc' onClick={() => handleRequest(code, false)} disabled={fetched[code].isRequested}>Fetch</button>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    :
                                                                    (
                                                                        <>
                                                                            <ReactHTMLTableToExcel
                                                                                id="test-table-xls-button"
                                                                                className="download-table-xls-button btn btn-success mb-3"
                                                                                table={`table-to-${code}_xls`}
                                                                                filename={`${code}_xls`}
                                                                                sheet={`${code}_xls`}
                                                                                buttonText="Export Data to Excel Sheet"
                                                                            />
                                                                            <JSONToHTMLTable data={widgetResponse[code]} tableClassName="table table-sm" tableId={`table-to-${code}_xls`}/>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                
                            </div> 
                            : 
                            <div className='col-xs-12'>
                                <p>
                                    No response, please try again later...
                                </p>
                            </div>

                        )  
                    }

                    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 padt_15'>
                        {/* <Link className="btn nav_btn" to={{ pathname: "/" }} > Back </Link> */}
                        <button className="btn nav_btn" onClick={goBack}> Back </button>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Response;