import { Modal, Button } from 'react-bootstrap';

const Confirmation = (props) => {
    const { partner, confirmed, closeMessage, message } = props;
    
    return ( 
        <Modal 
            show={true}
            onHide={closeMessage}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    message ? message : `Are you sure you want to unselect ${partner}?`
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeMessage}>
                    Close
                </Button>
                <Button type="submit" variant="primary" onClick={confirmed}> 
                    Yes 
                </Button>
            </Modal.Footer>
        </Modal>
     );
}
 
export default Confirmation;