/*
    1: "Accessing microsite",
    2: "Viewing sample file",
    3: "Selecting 'PDF' for upload",
    3: "Selecting 'Image' for upload",
    4: "Selecting file to upload",
    5: "Uploading file",
    6: "Canceling file upload",
    7: "Entering password",
    8: "Selecting bank",
    9: `${req_body.bank} bank selected`,
    10: "Redirected to Julo Site",
    11: "Token Expired / Wrong Token"
*/


import LocalstorageService from './localstorage.service';
const localStorageService = new LocalstorageService();
const isProduction = window.location.href.includes('https://auth.powercred.io') || window.location.href.includes('https://docs.julo.co.id');

export const TriggerJuloEvent = async (event, selectedBank = '') => {
    try {
        const developerMode = localStorageService.getItem('developer');
        const isDeveloper = Boolean(developerMode);

        if (isProduction || isDeveloper) {
            const url = "https://script.google.com/macros/s/AKfycbwYshTNIthTiys7yixkTZfZRwL3EYcRQpje4FdZxcO-HzmgOGEPoyniBqCCcKMCWlnh/exec"

            const localstorageService = new LocalstorageService();
            const id = localstorageService.getItem('id', false, 'tokenVerification');
            const client_id = localstorageService.getItem('client_id', false, 'tokenVerification');
            const user_id = localstorageService.getItem('user_id', false, 'tokenVerification');
            const data = { 'id': id, 'event': event, 'client_id': client_id, 'user_id': user_id, 'bank': selectedBank };
            const maxRetries = 2;

            if (Boolean(id)) {
                for(let i = 0; i < maxRetries; i++) {
                    let resp;

                    await fetch(url, {
                        redirect: "follow",
                        mode: "no-cors",
                        headers: {
                        "Content-Type": "text/plain",
                        },
                        method: "POST",
                        body: JSON.stringify(data)
                    }).then((res) => {
                        resp = res;
                    })
                    .catch((err) => {
                        console.log(err);
                    })

                    if (resp.status == 0) {
                        // console.log(resp);
                        break;
                    } else {
                        console.log('Retrying event trigger');
                    }
                }
            }
        }

    } catch (error) {
        console.error('Error Sending Event:', error);
    }
}
