import { useLayoutEffect } from 'react';
import './reference.css';

const TokopediaReference = () => {
    useLayoutEffect(() => {
        document.getElementsByClassName('App')[0].style.overflow = 'scroll';
    }, []);

    return ( 
        <div className="reference-docs">
            <div className="container pad_15">
                <h4><u>Connecting Tokopedia account successfully.</u></h4>
                <div className='container'>
                    <h5 className='padt_15'>
                        Steps to follow:
                    </h5>
                    <ol className='bsar_list'>
                        <li className='padb_7'>
                            Users sign-in either using his/her email address or phone number to sign in to Power UI.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref.jpg')} alt="tokopedia-ref" />  
                            </div>
                        </li>
                        <li className='padb_7'>
                            Users will be logged on to the Power UI.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref2.jpg')} alt="tokopedia-ref2" />
                                <div className='reference-text-2'>Once logged on to Power UI, users will need to select tokopedia (highlighted with blue border) and enter their shop url..    </div>
                                <div className='reference-text-2'>Then click on BERIKUTNYA (Next).</div>
                                <div className='reference-text-2'>A verification email will be sent to the email address of the user which registered to his tokopedia account.</div>
                            </div>
                        </li>
                        <li className='padb_7'>
                            User will need to click verify in the verification email.
                        </li>
                        <li className='padb_7'>
                            Power UI will refresh and  Hubungkan Akun Tokopedia will have a green tick above it.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref3.jpg')} alt="tokopedia-ref3" />
                            </div>
                        </li>
                        <li className='padb_7'>
                            User can either choose to enter a bank account number or skip.
                            <ol style={{ listStyleType: "upper-alpha", fontWeight: 'normal' }}>
                                <li className='padb_7'>
                                    If the user chose skip.  
                                    <div className='reference-text-2'>
                                        If the user chooses to skip this part, the user will get the following pop-up confirming his tokopedia account has been linked successfully.
                                    </div> 
                                    <div>
                                        <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref4.jpg')} alt="tokopedia-ref4" />
                                        <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref5.jpg')} alt="tokopedia-ref5" />
                                    </div>
                                </li>
                                <li className='padb_7'>
                                    If the user chooses to, he will need to enter his bank account number.
                                    <div className='reference-text-2'>
                                        User will then need to click on BERIKUTNYA (Next) and get the following pop-up confirming bank account added successfully.
                                    </div> 
                                    <div>
                                        <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref6.jpg')} alt="tokopedia-ref6" />
                                    </div>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <h4 className='reference-main-heading'><u>Errors or Problems the Users might face while linking their Tokopedia accounts</u></h4>
                <div className='container'>
                    <ol className='bsar_list'>
                        <li className='padb_7'>
                            If the customer enters the wrong account number, the following message will be displayed.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref7.jpg')} alt="tokopedia-ref7" />
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref8.jpg')} alt="tokopedia-ref8" />
                            </div>
                        </li>
                        <li className='padb_7'>
                            If the customer uses an incorrect shop url, the following message will be displayed.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref9.jpg')} alt="tokopedia-ref9" />
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref10.jpg')} alt="tokopedia-ref10" />
                            </div>
                        </li>
                        <li className='padb_7'>
                            Invalid email address or invalid phone number.
                            <div>
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref11.jpg')} alt="tokopedia-ref11" />
                                <img className='padt_10 refernce-image' src={require('../components/images/tokopedia-ref12.jpg')} alt="tokopedia-ref12" />
                                <div className='reference-text-2'>
                                    Make sure the users are using the right email address or phone numbers to sign in.
                                </div> 
                            </div>
                        </li>
                        <li className='padb_7'>
                            A user may not click verify in the verification email in that case tokopedia account will not be added and we won’t be able to fetch the data.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
      );
}
 
export default TokopediaReference;