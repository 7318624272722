import React from 'react'
import { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom'
import "./Home.css";
import './reference.css';

const BlibliStoreBindReference = () => {
    useLayoutEffect(() => {
        document.getElementsByClassName('App')[0].style.overflow = 'scroll';
    }, []);

    return (
        <div>
            <div className="container pad_15">
                <h4><u>Bind your Blibli seller account reference</u></h4>
                <div className='container'>
                    <h5 className='padt_15'>
                        Steps to follow:
                    </h5>
                    <ol className='bsar_list'>
                        <li className='padb_7'>
                            Login to the <a href="https://seller.blibli.com/sign-in">Blibli Seller Center.</a>
                        </li>
                        <li className='padb_7'>
                            How to bind your Blibli seller store to PowerCred API.
                            <ol style={{ listStyleType: "upper-alpha", fontWeight: 'normal' }}>
                                <li className='padb_7'>
                                    Go to <a href="https://seller.blibli.com/api-integration/client-manager">API client manager</a> page and enter "mta-api-powercred-91ecf" in API client ID field under "Bind API client" section as shown in image below.
                                    <img className='padt_10' src={require('../components/images/blibli_bind_api_cid.png')} alt="blibli_bind_api_cid_image" width="100%" />
                                </li>
                            </ol>
                        </li>
                        <li className='padb_7'>
                            If you have set the Signature key, please turn it off before authorization, otherwise cannot be authorized.
                            <ol style={{ listStyleType: "upper-alpha", fontWeight: 'normal' }}>
                                <li className='padb_7'>
                                    Go to <a href="https://seller.blibli.com/api-integration/client-manager">API client manager</a> page and click on "Revoke" button of "Signature key" section as shown in image below.
                                    <img className='padt_10' src={require('../components/images/blibli_signature_revoke.png')} alt="blibli_signature_revoke_image" width="100%" />
                                </li>
                            </ol>
                        </li>
                        <li className='padb_7'>
                            How to get store id ?
                            <ol style={{ listStyleType: "upper-alpha", fontWeight: 'normal' }}>
                                <li className='padb_7'>
                                    Go to <a href="https://seller.blibli.com/store/info">store info</a> page and copy your "store id" as shown in image below.
                                    <img className='padt_10' src={require('../components/images/blibli_store_id.png')} alt="blibli_store_id_image" width="100%" />
                                </li>
                            </ol>
                        </li>
                        <li className='padb_7'>
                            How to get API seller key ?
                            <ol style={{ listStyleType: "upper-alpha", fontWeight: 'normal' }}>
                                <li className='padb_7'>
                                    Go to <a href="https://seller.blibli.com/api-integration/client-manager">API client manager</a> page, click on generate button to create your "API seller key" and copy it as shown in image below.
                                    <img className='padt_10' src={require('../components/images/blibli_api_skey.png')} alt="blibli_api_skey_image" width="100%" />
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default BlibliStoreBindReference