import { useEffect, useState, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Dropzone from "./Dropzone";
import KYC from '../components/KYC/kyc.component';
import NPWP from '../components/NPWP/npwp.component';
import Gopay from '../components/Gopay/gopay.component';
import MultiBanking from '../components/Banking/banking.component';
import BrandBadge from '../Widget/BrandBadge/brandBadge.widget';

const PartnerPageNew = (props) => {
    const { index, bankIndex, eCommerceList, updateParams, updateParamsOTP, back, categoriesData, isMobileView, isTranslated, processSuccess, showError, verificationStep, isJulo } = props;
    // console.log(index, bankIndex);
    let { code, icon, name, params, modalInfo } = eCommerceList[index];

    const nextStepIndex = eCommerceList[index].isNextStepInitiated ? eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.code == eCommerceList[index].nextStepCode)) : -1;

    modalInfo = eCommerceList[index].isNextStepInitiated ? eCommerceList[nextStepIndex].modalInfo : modalInfo;

    params = eCommerceList[index].isNextStepInitiated ? eCommerceList[nextStepIndex].params : params;

    const [paramsLocal, setParamsLocal] = useState(params);

    const [paramsLocalOTP, setParamsLocalOTP] = useState(eCommerceList[index].paramsOTP);

    const [isDisabled, setIsDisabled] = useState(eCommerceList[index].showOTP || eCommerceList[index].isInitiated ? true : false);

    const [isMobileScreen, setIsMobileScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 577) {
                setIsMobileScreen(true);
            } else {
                setIsMobileScreen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const changeValue = (key, value) => {
        const newArray = [...paramsLocal];
        let index = paramsLocal.indexOf(paramsLocal.find(obj => obj.key == key));
        newArray[index] = {...newArray[index], value}; 
        if(newArray[index].isPrefixT && value.indexOf(newArray[index].isPrefixT) !== 0){
            return;
        }
        if(newArray[index].type == 'number' && (value != '' && !isNumeric(value))){
            return;
        }
        setParamsLocal(newArray);
    }

    const changeFile = (key, file) => {
        // console.log('file before', file)
        if(eCommerceList[index].ismultipleocr && file.length > 30){
            showError(isTranslated ? 'Maksimal 30 E-Statement' : 'Only upto 30 E-Statements are allowed');
            // document.getElementById(key+code).value = ''
            return;
        }
       if(!eCommerceList[index].ismultipleocr && file.length > 15) {
            showError(isTranslated ? 'Maksimal 15 E-Statement' : 'Only upto 15 E-Statements are allowed');
            // document.getElementById(key+code).value = ''
            return;
        }
        const newArray = [...paramsLocal];
        let index = paramsLocal.indexOf(paramsLocal.find(obj => obj.key == key));
        let data = new FormData();
        let total_size = 0;
        for (let i = 0; i < file.length; i++) {
            // console.log('upload file', file[i]);
            total_size = total_size + file[i].size/1024
            // Checking 1 Mb case
            if(file[i].size/1024 > 10240) {
                showError(isTranslated ? 'Ukuran individual file tidak boleh melebihi 10MB' : 'Individual size of the file should not exceed 10MB');
                return;
            }
            data.append('files', file[i])
        };
        console.log(eCommerceList[index].ismultipleocr)
        // if(eCommerceList[index].ismultipleocr && total_size > 10240){
        //     showError(isTranslated ? 'Ukuran individual file tidak boleh melebihi 10MB' : 'Individual size of the file should not exceed 10MB');
        //     // document.getElementById(key+code).value = ''
        //     return;
        // }
        if(!eCommerceList[index].ismultipleocr && total_size > 10240) {
            showError(isTranslated ? 'Ukuran file maksimal 10MB' : 'Total size of all the files should not exceed 10 MB');
            // document.getElementById(key+code).value = ''
            return;
        }
        // console.log('files', data)
        // console.log('files size', total_size)
        newArray[index] = {...newArray[index], 'value': data}; 
        setParamsLocal(newArray);
    }

    const checkFileLength = (file, length, total_size, isFormData) => {
        if(isFormData && file){
            const new_file = []
            for (const value of file.values()) {
                new_file.push(value)
            }
            file = new_file
        }
        if(eCommerceList[index].ismultipleocr && file.length > 30){
            showError(isTranslated ? 'Maksimal 30 E-Statement' : 'Only upto 30 E-Statements are allowed');
            // document.getElementById(key+code).value = ''
            return;
        }
        if(!eCommerceList[index].ismultipleocr && file.length > length) {
            showError(isTranslated ? 'Maksimal 15 E-Statement' : 'Only upto 15 E-Statements are allowed');
            return false
        }
        

        let size = 0;
        for (let i = 0; i < file.length; i++) {
            size = size + file[i].size/1024
            if(file[i].size/1024 > 10240) {
                showError(isTranslated ? 'Ukuran individual file tidak boleh melebihi 10MB' : 'Individual size of the file should not exceed 10MB');
                return;
            }
        };
        // if(eCommerceList[index].ismultipleocr && size > 10240){
        //     showError(isTranslated ? 'Ukuran individual file tidak boleh melebihi 10MB' : 'Individual size of the file should not exceed 10MB');
        //     // document.getElementById(key+code).value = ''
        //     return;
        // }
        if(!eCommerceList[index].ismultipleocr && size > total_size) {
            showError(isTranslated ? 'Ukuran file maksimal 10MB' : 'Total size of all the files should not exceed 10 MB');
            return false
        }
        return true
    }

    const updateFile = (key, file) => {
        // console.log('updateFile before', file)
        const newArray = paramsLocal;
        let index = paramsLocal.indexOf(paramsLocal.find(obj => obj.key == key));
        let data = new FormData();
        for (let i = 0; i < file.length; i++) {
            data.append('files', file[i])
        };
        checkFileLength(file, 15, 10240)
        newArray[index].value = data; 
        setParamsLocal(newArray);
    }

    function isNumeric(value){
        return /^\d+$/.test(value);
    }

    // const onDrop = (file) => {
    //     console.log('file - ', file); 
    //     changeFile(obj.key, file)
    // }

    const __onDrop = useCallback((acceptedFiles) => {
        console.log('ondrop called');
        acceptedFiles.map((file) => {
          const reader = new FileReader();
          reader.onload = function (e) {
            // setImages((prevState) => [
            //   ...prevState,
            //   { id: cuid(), src: e.target.result },
            // ]);
            changeFile('file', e.target.result)
          };
          reader.readAsDataURL(file);
          return file;
        });
    }, []);

    function _onDrop(acceptedFiles) {
        console.log('ondrop called');
        acceptedFiles.forEach(file => {
            console.log('file called - ', file); 
            changeFile('file', file);
        })
    }

    const changeValueOTP = (key, value) => {
        const newArray = [...paramsLocalOTP];
        let index = paramsLocalOTP.indexOf(paramsLocalOTP.find(obj => obj.key == key));
        if(newArray[index].type == 'number' && (value != '' && !isNumeric(value))){
            return;
        }
        if(newArray[index].maxLength && value.length > newArray[index].maxLength){
            return;
        }
        newArray[index] = {...newArray[index], value};
        setParamsLocalOTP(newArray);
    }
    // console.log('partner page new called', nextStepIndex, paramsLocal );
    const submit = (e, isResend) => {
        e.preventDefault();
        for(let i = 0; i < paramsLocal.length; i++){
            if(paramsLocal[i].isPrefixT && paramsLocal[i].value == paramsLocal[i].isPrefixT){
                showError(isTranslated ? paramsLocal[i].errorMessageT : paramsLocal[i].errorMessage);
                return;
            }
            else if(paramsLocal[i].key == 'files' && eCommerceList[index].isBankingOcr){
                if(!checkFileLength(paramsLocal[i].value, 15, 10240, true) || !paramsLocal[i].value){
                    return
                }
            }
        }

        if(eCommerceList[index].showOTP && !isResend){
            updateParamsOTP(index, paramsLocalOTP);
        }
        else{
            updateParams(nextStepIndex != -1 ? nextStepIndex : index, paramsLocal, bankIndex);
        }
        let bank_name = {};
        if(eCommerceList[index].banks){
            bank_name = {'bank_name': bankIndex !== undefined ? eCommerceList[index].banks[bankIndex].name : ''};
        }
        window.gtag('event', 'partner_form_submitted', { 'partner_name': eCommerceList[index].name, ...bank_name });
    };

    useEffect(() => {
        // if(params.length == 0){
        //     console.log('no params required for ', eCommerceList[index].name);
        //     updateParams(index, [], bankIndex);
        // }
        setParamsLocal(params);
        setParamsLocalOTP(eCommerceList[index].paramsOTP);
        // console.log({index, bankIndex}); // --
    },[index, bankIndex, eCommerceList]);


    const handlerSuccessKYC = () => {
        eCommerceList[index].isSelected = true;
        processSuccess(index, bankIndex);
    }
    const handlerSuccessNPWP = () => {
        eCommerceList[index].isSelected = true;
        processSuccess(index, bankIndex);
    }

    const handlerSuccessGopay = () => {
        eCommerceList[index].isSelected = true;
        processSuccess(index, bankIndex);
    }

    const handlerSuccessBanking = () => {
        eCommerceList[index].isSelected = true;
        processSuccess(index, bankIndex);
    }

    if(eCommerceList[index].code == 'NPWP'){
        return (
            <div className="partner-page-n">
                <NPWP back={back} onFormSuccess={handlerSuccessNPWP} />
            </div>
        )
    }

    if(eCommerceList[index].category == 'Identity Data'){
        return (
            <div className="partner-page-n">
                <KYC back={back} onFormSuccess={handlerSuccessKYC} />
            </div>
        )
    }

    if(eCommerceList[index].code == 'gopay'){
        return (
            <div className="partner-page-n">
                <Gopay back={back} onFormSuccess={handlerSuccessGopay} isTranslated={isTranslated}/>
            </div>
        )
    }

    if(eCommerceList[index].code == 'multi-bank-ocr'){
        return (
            <div className="partner-page-n">
                <MultiBanking back={back} onFormSuccess={handlerSuccessBanking} isTranslated={isTranslated} body={eCommerceList[index]} clientJulo={isJulo}/>
            </div>
        )
    }

    if((eCommerceList[index].isSelected && !eCommerceList[index].banks) || (eCommerceList[index].banks && ((eCommerceList[index].isSelected && bankIndex === undefined) || (bankIndex !== undefined && eCommerceList[index].banks[bankIndex].isSelected)))){
        return (
            <div className="partner-page-n">
                <div className="partner-page-form-n">
                    <div className="">
                        <h3 className='title-p title-p-p1 title-p-n'>
                            {
                                categoriesData[eCommerceList[index].category].getHeading(eCommerceList[index].name, isTranslated)
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                            <div className='partner-info-icon-message'>
                                {
                                    categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].getText(eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].name, isTranslated)
                                }
                                {
                                    categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].getData(eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].code, isTranslated).map((data, indexI) => {
                                        return (
                                            <div key={indexI}> 
                                                {
                                                    // isTranslated ? `${indexI+1}. ${categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].dataT[indexI]}` : `${indexI+1}. ${data}`
                                                    `${indexI+1}. ${data}`
                                                } 
                                            </div>);
                                    })
                                }
                            </div>
                        </h3>
                        {
                            eCommerceList[index].steps && (
                                <div className='partner-steps'>
                                    <div className="stepper-wrapper">
                                        <div className={`stepper-item completed`}>
                                            <div className="step-counter"></div>
                                            <img style={ nextStepIndex == -1 ? {display: 'none'} : {}} src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n step-complete-image'/>
                                            <div className="step-name"> {isTranslated ? eCommerceList[index].steps.first.textT : eCommerceList[index].steps.first.text} </div>
                                            <img src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n step-complete-image'/>
                                        </div>
                                        <div className={`stepper-item completed`}>
                                            <div className="step-counter"></div>
                                            <div className="step-name"> {isTranslated ? eCommerceList[index].steps.second.textT : eCommerceList[index].steps.second.text} </div>
                                            <img src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n step-complete-image'/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className='partner-page-n-connected'>
                            {
                                isTranslated 
                                ? `${eCommerceList[index].name} telah Terhubung, Silakan pilih mitra lain.`
                                : `${eCommerceList[index].name} has been Connected, Please select another partner.`
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (  
        <div className="partner-page-n">
            <form onSubmit={submit} className="partner-page-form-n">
                <div>
                    <h3 className='title-p title-p-p1 title-p-n'>
                        {/* {
                            isTranslated 
                            ? `Hubungkan ke Akun ${eCommerceList[index].name}`
                            : `Connect to ${eCommerceList[index].name} Account`
                        } */}
                        {
                            categoriesData[eCommerceList[index].category].getHeading(eCommerceList[index].name, isTranslated)
                        }
                        <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                        <div className='partner-info-icon-message'>
                            {
                                categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].getText(eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].name, isTranslated)
                            }
                            {
                                categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].getData(eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].code, isTranslated).map((data, indexI) => {
                                    return (
                                        <div key={indexI}> 
                                            {
                                                // isTranslated ? `${indexI+1}. ${categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].dataT[indexI]}` : `${indexI+1}. ${data}`
                                                `${indexI+1}. ${data}`
                                            } 
                                        </div>);
                                })
                            }
                        </div>
                    </h3>
                    {
                        eCommerceList[index].steps && (
                            <div className='partner-steps'>
                                <div className="stepper-wrapper">
                                    <div className={`stepper-item ${nextStepIndex != -1 ? 'completed' : ''} ${nextStepIndex == -1 ? 'active' : ''}`}>
                                        <div className="step-counter"></div>
                                        <div className="step-name"> {isTranslated ? eCommerceList[index].steps.first.textT : eCommerceList[index].steps.first.text} </div>
                                        <img src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n step-complete-image'/>
                                    </div>
                                    <div className={`stepper-item ${eCommerceList[index].isSelected ? 'completed' : ''} ${nextStepIndex != -1 ? 'active' : ''}`}>
                                        <div className="step-counter"></div>
                                        <div className="step-name"> {isTranslated ? eCommerceList[index].steps.second.textT : eCommerceList[index].steps.second.text} </div>
                                        <img src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n step-complete-image'/>
                                    </div>
                                    {/* {
                                        eCommerceList[index].steps.map((obj, index) => (
                                            <div className={`stepper-item ${obj.isCompleted || obj.isActive ? 'completed' : ''} ${obj.isActive ? 'active' : ''}`}>
                                                <div className="step-counter"></div>
                                                <div className="step-name"> {obj.text} </div>
                                            </div>
                                        ))
                                    } */}
                                </div>
                            </div>
                        )
                    }
                    <div className={`info-p info-p-n ${eCommerceList[index].steps ? 'info-p-n-wm' : 'info-p-n-m'}`}>
                        {/* <h5 className='info-p-header'>
                            {
                                isTranslated 
                                ? 'Mitra Data'
                                : 'Data Partners'
                            }
                        </h5> */}
                        <div className='info-inner-p'>
                            {/* {
                                isTranslated 
                                ? `Anda diminta untuk mengotorisasi akun ${eCommerceList[index].name}. Anda akan terhubung ke Akun ${eCommerceList[index].name} Anda dan ini akan memberi kami akses ke data berikut:`
                                : `You are asked to authorize ${eCommerceList[index].name} account. You will be connecting to your ${eCommerceList[index].name} Account and this will give us access to the following data:`
                            } */}
                            {/* {
                                categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].getText(eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].name, isTranslated)
                            }
                            <div className='list-p'>
                                {
                                    categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].getData(eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].code, isTranslated).map((data, indexI) => {
                                        return (
                                            <div key={indexI}> 
                                                {
                                                    // isTranslated ? `${indexI+1}. ${categoriesData[eCommerceList[nextStepIndex != -1 ? nextStepIndex : index].category].dataT[indexI]}` : `${indexI+1}. ${data}`
                                                    `${indexI+1}. ${data}`
                                                } 
                                            </div>);
                                    })
                                }
                            </div> */}
                        </div>
                        <div className='form-p'>
                            
                                    {
                                        modalInfo.specialNote && ( <p className="note_msg note_msg-n"> { isTranslated ? modalInfo.specialNoteT : modalInfo.specialNote} </p> )
                                    }
                                    {
                                        paramsLocal.filter(param => isMobileView && param.isOnlyDesktopView ? false : true).length == 0 && (
                                            <div>{ isTranslated ? 'Silakan klik tombol berikutnya untuk menghubungkan akun Anda.' : 'Please click on next button to connect your account.'} </div>
                                        )
                                    }
                                    {
                                        paramsLocal.filter(param => isMobileView && param.isOnlyDesktopView ? false : true).map((obj, index) => (
                                            <div key={obj.key+code} style={index ? {'paddingTop': '10px'}: {}}>
                                                <label className={`scl ${obj.type == 'radio' ? 'scl-radio-n' : ''}`}>
                                                    {
                                                        isTranslated ? obj.labelT : obj.label
                                                    }
                                                    <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                    </svg>
                                                    <div className='partner-info-icon-message'>
                                                        {
                                                            isTranslated ? obj.infoMessageT : obj.infoMessage
                                                        }
                                                    </div>
                                                </label>
                                                {
                                                    obj.type == 'options' ?
                                                    (
                                                        <Form.Select aria-label="Default select example" className='input-p' onChange={(e) => changeValue(obj.key, e.target.value)}>
                                                            {
                                                                obj.options.map(element => (
                                                                    <option value={element} key={element}>{element}</option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    ) : (
                                                        obj.type == 'radio' ?
                                                        (
                                                            <div className='radio-bank-n'>
                                                                {/* <input type="radio" id="bankconnect" name="bankType" value="bankconnect"/>
                                                                
                                                                <input type="radio" id="altconnect" name="bankType" value="altconnect"/>
                                                                <label for="altconnect">Corporate Banking</label><br></br> */}
                                                                {
                                                                   obj.values.map(objI => (
                                                                       <div className='radio-option-n' key={objI.name}>
                                                                            <input
                                                                                type="radio"
                                                                                name={objI.name}
                                                                                id={objI.name}
                                                                                value={objI.value}
                                                                                checked={obj.value == objI.value}
                                                                                onChange={(e) => changeValue(obj.key, e.target.value)}
                                                                            />
                                                                            <label htmlFor={objI.name} className='radio-label'>{objI.name}</label><br></br>
                                                                        </div>
                                                                    )) 
                                                                }
                                                            </div>
                                                        ) : (
                                                                obj.type == 'file' && obj.isMultipleFile ?
                                                                (
                                                                    <div className='input-p input-f-p1'>
                                                                        {/* <Dropzone onDropAccepted={onDrop} changeFile={changeFile} accept={".pdf"}/> */}
                                                                        <Dropzone className='form-control' changeFile={(files) => updateFile(obj.key, files)}/>
                                                                        {/* <input
                                                                            type={"file"}
                                                                            id={obj.key+code}
                                                                            className='form-control'
                                                                            accept=".pdf"
                                                                            placeholder={isTranslated ? obj.placeHolderT : obj.placeHolder}
                                                                            name={obj.key}
                                                                            onChange={(e) => changeFile(obj.key, e.target.files)}
                                                                            // value={obj.value}
                                                                            multiple
                                                                            required
                                                                            disabled={isDisabled}
                                                                        /> */}
                                                                    </div>
                                                                ) : 
                                                                ( 
                                                                    obj.type == 'file' && !obj.isMultipleFile ?
                                                                    (
                                                                        <div className='input-p input-f-p1'>
                                                                            {/* <Dropzone onDropAccepted={onDrop} changeFile={changeFile} accept={".pdf"}/> */}
                                                                            <input
                                                                                type={"file"}
                                                                                id={obj.key}
                                                                                className='form-control'
                                                                                accept=".pdf"
                                                                                placeholder={isTranslated ? obj.placeHolderT : obj.placeHolder}
                                                                                name={obj.key}
                                                                                onChange={(e) => changeFile(obj.key, e.target.files)}
                                                                                // value={obj.value}
                                                                                required
                                                                                disabled={isDisabled}
                                                                            />
                                                                        </div>
                                                                    ) : 
                                                                    (
                                                                        <div className={obj.isPhoneNo ? "form-group-pn mt-2 input-p" : (obj.isPrefixT && false ? 'input-p prefixT' : 'input-p')}>
                                                                            {
                                                                                obj.isPhoneNo && <span className="border-end country-code country-code-n">+62</span>
                                                                            }
                                                                            {
                                                                                obj.isPrefixT && false && <span className="tokopedia-prefix">https://www.tokopedia.com/</span>
                                                                            }
                                                                            <input
                                                                                type={obj.type && obj.type != 'number' ? obj.type : "text"}
                                                                                id={obj.key}
                                                                                className='form-control'
                                                                                placeholder={isTranslated ? obj.placeHolderT : obj.placeHolder}
                                                                                name={obj.key}
                                                                                onChange={(e) => changeValue(obj.key, e.target.value)}
                                                                                value={obj.value}
                                                                                required={obj.isOptional ? false : true}
                                                                                disabled={isDisabled}
                                                                            />
                                                                        </div>
                                                                    )
                                                                )
                                                            )
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        eCommerceList[index].showOTP && (
                                            paramsLocalOTP.map((obj, index) => (
                                                <div key={obj.key}>
                                                    <label className="scl" style={{'marginTop': '10px'}}>
                                                        {
                                                            isTranslated ? obj.labelT : obj.label
                                                        }
                                                        <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                        </svg>
                                                        <div className='partner-info-icon-message'>
                                                            {
                                                                isTranslated ? obj.infoMessageT : obj.infoMessage
                                                            }
                                                        </div>
                                                    </label>
                                                    {
                                                        (
                                                            <div className={'input-p'}>
                                                                <input
                                                                    type={obj.type && obj.type != 'number' ? obj.type : "text"}
                                                                    id={obj.key}
                                                                    className='form-control'
                                                                    placeholder={isTranslated ? obj.placeHolderT : obj.placeHolder}
                                                                    name={obj.key}
                                                                    onChange={(e) => changeValueOTP(obj.key, e.target.value)}
                                                                    value={obj.value}
                                                                    required
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        )
                                    }
                                    {
                                        eCommerceList[index].isBankingOcr && (
                                             <p className="note_msg note_msg-n note_msg-ocr-n"> { isTranslated ? <>
                                                <span>Note:</span>
                                                <br />
                                                <span>1. Bank yang didukung adalah BCA BRI, Mandiri, BNI</span>
                                                <br />
                                                <span>2. Ukuran individual file tidak boleh melebihi 10MB</span>
                                                <br />
                                                <span>3. Hanya maksimal 30 E-Statement yang diperbolehkan</span>
                                                </>
                                             
                                             :
                                            <>
                                             <span>Note:</span>
                                             <br />
                                             <span>1. Supported banks are BCA BRI, Mandiri, BNI</span>
                                             <br />
                                             <span>2. Individual size of the file should not exceed 10MB</span>
                                             <br />
                                             <span>3. Only up to 30 E-Statements are allowed</span>
                                             </>
                                              }
                                              
                                               </p> 

                                            

                                        )
                                    }
                                    {
                                        modalInfo.link && (
                                            <div className='col-xs-12 padt_15'>
                                                <Link to={modalInfo.linkURL} target="_blank" className="bdm_link">
                                                    <u>
                                                        {
                                                            isTranslated ? modalInfo.linkT : modalInfo.link 
                                                        }
                                                    </u>
                                                </Link>
                                            </div>
                                        )
                                    }
                                    
                        </div>
                    </div>
                    {/* <div className='btn-div-p'>
                        <button type="button" className='btn nav-btn-pwc back-btn-p' onClick={back}>
                            Back
                        </button>
                        <button type="button" className='btn nav-btn-pwc next-btn' onClick={submit}>
                            NEXT
                        </button>
                    </div> */}
                </div>
                <div className='btn-div-p-n'>
                    <div className='back-btn-pp back-btn-pp-n btn nav-btn-pwc next-btn' onClick={back}>
                        {
                            isTranslated ? 'KEMBALI' : 'BACK'
                        }
                    </div>
                    {/* <button type="button" className='btn nav-btn-pwc back-btn-p' onClick={back}>
                        Back
                    </button> */}
                    <div className='buttons-bottom-div'>
                        <button type="submit" className='btn nav-btn-pwc next-btn btn-n btn-next-partner-n'>
                            {
                                eCommerceList[index].getButtonText ?  eCommerceList[index].getButtonText(isTranslated) : (isTranslated ? 'BERIKUTNYA' : 'NEXT')
                            }
                        </button>
                        <button type="button" className='btn back-btn-p-m' style={nextStepIndex != -1 && !verificationStep ? {display:'inline-block'} : {display:'none'}} onClick={() => processSuccess(nextStepIndex)}>
                            {
                                isTranslated ? 'LEWATKAN' : 'SKIP'
                            }
                        </button>
                        <button type="button" className='btn back-btn-p-m resend-btn-p-m' style={eCommerceList[index].isResend ? {display:'inline-block'} : {display:'none'}} onClick={(e) => submit(e, true)}>
                            {
                                isTranslated ? 'Kirim Ulang OTP' : 'Resend OTP'
                            }
                        </button>
                    </div>
                </div>
                {isMobileScreen && <BrandBadge option={"footer"} />}
            </form>
        </div>
    );
}
 
export default PartnerPageNew;