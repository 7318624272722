import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const OTPModal = (props) => {
    const { index, eCommerce, close, updateParamsOTP  } = props;
    
    const { code, paramsOTP, modalInfo } = eCommerce;

    const [paramsLocal, setParamsLocal] = useState(paramsOTP);

    const changeValue = (index, value) => {
        const newArray = [...paramsLocal];
        newArray[index] = {...newArray[index], value};
        setParamsLocal(newArray);
    }

    const submit = (e) => {
        e.preventDefault();
        updateParamsOTP(index, paramsLocal);
        close();
        window.gtag('event', 'OTP_submit', {
            'partner_name': code
        });
    };

    return ( 
        <Modal
            show={true}
            onHide={close}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {modalInfo.title}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body>
                    {
                        paramsLocal.map((obj, index) => (
                            <div key={obj.key}>
                                <label className="scl" style={index ? {'marginTop': '10px'}: {}}>
                                    {obj.label}
                                </label>
                                <input
                                    type={obj.type ? obj.type : "text"}
                                    id={obj.key}
                                    className='form-control'
                                    placeholder={obj.placeHolder}
                                    name={obj.key}
                                    onChange={(e) => changeValue(index, e.target.value)}
                                    value={obj.value}
                                    required
                                />
                            </div>
                        ))
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
     );
}
 
export default OTPModal;
