/*

*/ 

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../assets/locales/en.json';
import inTranslation from '../assets/locales/in.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  in: {
    translation: inTranslation,
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'in',
  fallbackLng: 'in',
  interpolation: {
    escapeValue: false,
  }
})

export default i18n;