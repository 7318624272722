import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const InputModal = (props) => {
    const { index, eCommerce, bankIndex, close, updateParams  } = props;
    
    const { code, icon, name, params, modalInfo } = eCommerce;

    const [paramsLocal, setParamsLocal] = useState(params);

    const changeValue = (index, value) => {
        const newArray = [...paramsLocal];
        newArray[index] = {...newArray[index], value};
        setParamsLocal(newArray);
    }

    const submit = (e) => {
        e.preventDefault();
        updateParams(index, paramsLocal, bankIndex);
        close();
        const bank_code = eCommerce.banks ? { bank: eCommerce.banks[bankIndex].code } : {};
        window.gtag('event', 'widget_submit', {
            'partner_name': code,
            ...bank_code
        });
    };

    return ( 
        <Modal
            show={true}
            onHide={close}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {modalInfo.title}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={submit}>
                <Modal.Body>
                    {
                        modalInfo.specialNote && ( <p className="note_msg"> {modalInfo.specialNote} </p> )
                    }
                    {
                        paramsLocal.map((obj, index) => (
                            <div key={obj.key}>
                                <label className="scl" style={index ? {'marginTop': '10px'}: {}}>
                                    {obj.label}
                                </label>
                                {
                                    obj.type == 'options' ?
                                    (
                                        <Form.Select aria-label="Default select example" onChange={(e) => changeValue(index, e.target.value)}>
                                            {
                                                obj.options.map(element => (
                                                    <option value={element} key={element}>{element}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    ) : (
                                        <div className={obj.isPhoneNo ? "form-group-pn mt-2" : ''}>
                                            {
                                                obj.isPhoneNo && <span className="border-end country-code">+62</span>
                                            }
                                            <input
                                                type={obj.type ? obj.type : "text"}
                                                id={obj.key}
                                                className='form-control'
                                                placeholder={obj.placeHolder}
                                                name={obj.key}
                                                onChange={(e) => changeValue(index, e.target.value)}
                                                value={obj.value}
                                                {...obj.multiple}
                                                required
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        ))
                    }
                    {
                        modalInfo.link && (
                            <div className='col-xs-12 padt_15'>
                                <Link to={modalInfo.linkURL} target="_blank" className="bdm_link">
                                    <u>{modalInfo.link }</u>
                                </Link>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
     );
}
 
export default InputModal;
