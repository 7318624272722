import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const PartnerPage = (props) => {
    const { index, bankIndex, eCommerceList, updateParams, updateParamsOTP, back, categoriesData, isTranslated, showError } = props;
    
    const { code, icon, name, params, modalInfo } = eCommerceList[index];

    const [paramsLocal, setParamsLocal] = useState(params);

    const [paramsLocalOTP, setParamsLocalOTP] = useState(eCommerceList[index].paramsOTP);

    const [isDisabled, setIsDisabled] = useState(eCommerceList[index].showOTP || eCommerceList[index].isInitiated ? true : false);

    const changeValue = (index, value) => {
        const newArray = [...paramsLocal];
        newArray[index] = {...newArray[index], value};
        if(newArray[index].isPrefixT && value.indexOf(newArray[index].isPrefixT) !== 0){
            return;
        }
        setParamsLocal(newArray);
    }

    const changeValueOTP = (index, value) => {
        const newArray = [...paramsLocalOTP];
        newArray[index] = {...newArray[index], value};
        setParamsLocalOTP(newArray);
    }

    const submit = (e) => {
        e.preventDefault();
        for(let i = 0; i < paramsLocal.length; i++){
            if(paramsLocal[i].isPrefixT && paramsLocal[i].value == paramsLocal[i].isPrefixT){
                showError(isTranslated ? paramsLocal[i].errorMessageT : paramsLocal[i].errorMessage);
                return;
            }
        }
        if(eCommerceList[index].showOTP){
            updateParamsOTP(index, paramsLocalOTP);
        }
        else{
            updateParams(index, paramsLocal, bankIndex);
        }
        let bank_name = {};
        if(eCommerceList[index].banks){
            bank_name = {'bank_name': eCommerceList[index].banks[bankIndex].name};
        }
        window.gtag('event', 'partner_form_submitted', { 'partner_name': eCommerceList[index].name, ...bank_name });
    };

    useEffect(() => {
        // if(params.length == 0){
        //     console.log('no params required for ', eCommerceList[index].name);
        //     updateParams(index, [], bankIndex);
        // }
    },[]);
    return (  
        <div className="partner-page">
            <h3 className='title-p title-p-p1'>
                {
                    isTranslated 
                    ? ( eCommerceList[index].partnerPageHeadingT !== undefined
                        ? ( eCommerceList[index].banks 
                            ? eCommerceList[index].banks[bankIndex].partnerPageHeadingT 
                            : eCommerceList[index].partnerPageHeadingT 
                          ) 
                        : `Hubungkan ke Akun ${eCommerceList[index].name}`
                      )
                    : `Connect to ${eCommerceList[index].name} Account`
                }
            </h3>
            <div className='info-p'>
                <h5 className='info-p-header'>
                    {
                        isTranslated 
                        ? 'Mitra Data'
                        : 'Data Partners'
                    }
                </h5>
                <div className='info-inner-p'>
                    {
                        isTranslated
                        ? ( eCommerceList[index].partnerPageMainMessageT !== undefined
                            ? ( eCommerceList[index].banks 
                                ? eCommerceList[index].banks[bankIndex].partnerPageMainMessageT 
                                : eCommerceList[index].partnerPageMainMessageT 
                              )  
                            : `Anda diminta untuk mengotorisasi akun ${eCommerceList[index].name}. Anda akan terhubung ke Akun ${eCommerceList[index].name} Anda dan ini akan memberi kami akses ke data berikut:`
                          )
                        : `You are asked to authorize ${eCommerceList[index].name} account. You will be connecting to your ${eCommerceList[index].name} Account and this will give us access to the following data:`
                    }
                    <div className='list-p'>
                        {
                            categoriesData[eCommerceList[index].category].data.map((data, indexI) => {
                                return (
                                    <div key={indexI}> 
                                        {
                                            isTranslated ? `${indexI+1}. ${categoriesData[eCommerceList[index].category].dataT[indexI]}` : `${indexI+1}. ${data}`
                                        } 
                                    </div>);
                            })
                        }
                    </div>
                </div>
                <div className='form-p'>
                    <form onSubmit={submit}>
                            {
                                modalInfo.specialNote && ( <p className="note_msg"> { isTranslated ? modalInfo.specialNoteT : modalInfo.specialNote} </p> )
                            }
                            {
                                paramsLocal.map((obj, index) => (
                                    <div key={obj.key}>
                                        <label className={code == 'blibli' ? 'scl scl-blibli' : `scl ${obj.type == 'radio' ? 'scl-radio-n' : ''}`} style={index ? {'marginTop': '10px'}: {}}>
                                            {
                                                isTranslated ? obj.labelT : obj.label
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                            </svg>
                                            <div className='partner-info-icon-message'>
                                                {
                                                    isTranslated ? obj.infoMessageT : obj.infoMessage
                                                }
                                            </div>
                                        </label>
                                        {
                                            obj.type == 'options' ?
                                            (
                                                <Form.Select aria-label="Default select example" className='input-p' onChange={(e) => changeValue(index, e.target.value)}>
                                                    {
                                                        obj.options.map(element => (
                                                            <option value={element} key={element}>{element}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            ) : (
                                                obj.type == 'radio' ?
                                                (
                                                    <div className='radio-bank-n'>
                                                        {/* <input type="radio" id="bankconnect" name="bankType" value="bankconnect"/>
                                                        
                                                        <input type="radio" id="altconnect" name="bankType" value="altconnect"/>
                                                        <label for="altconnect">Corporate Banking</label><br></br> */}
                                                        {
                                                           obj.values.map(objI => (
                                                               <div className='radio-option-n' key={objI.name}>
                                                                    <input
                                                                        type="radio"
                                                                        name={objI.name}
                                                                        id={objI.name}
                                                                        value={objI.value}
                                                                        checked={obj.value == objI.value}
                                                                        onChange={(e) => changeValue(index, e.target.value)}
                                                                    />
                                                                    <label htmlFor={objI.name} className='radio-label'>{objI.name}</label><br></br>
                                                                </div>
                                                            )) 
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className={obj.isPhoneNo ? "form-group-pn mt-2 input-p" : (obj.isPrefixT && false ? 'input-p prefixT' : 'input-p')}>
                                                        {
                                                            obj.isPhoneNo && <span className="border-end country-code">+62</span>
                                                        }
                                                        {
                                                            obj.isPrefixT && false && <span className="tokopedia-prefix">https://www.tokopedia.com/</span>
                                                        }
                                                        <input
                                                            type={obj.type ? obj.type : "text"}
                                                            id={obj.key}
                                                            className='form-control'
                                                            placeholder={isTranslated ? obj.placeHolderT : obj.placeHolder}
                                                            name={obj.key}
                                                            onChange={(e) => changeValue(index, e.target.value)}
                                                            value={obj.value}
                                                            required
                                                            disabled={isDisabled}
                                                        />
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>
                                ))
                            }
                            {
                                eCommerceList[index].showOTP && (
                                    paramsLocalOTP.map((obj, index) => (
                                        <div key={obj.key}>
                                            <label className="scl" style={{'marginTop': '10px'}}>
                                                {
                                                    isTranslated ? obj.labelT : obj.label
                                                }
                                                <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                </svg>
                                                <div className='partner-info-icon-message'>
                                                    {
                                                        isTranslated ? obj.infoMessageT : obj.infoMessage
                                                    }
                                                </div>
                                            </label>
                                            {
                                                (
                                                    <div className={'input-p'}>
                                                        <input
                                                            type={obj.type ? obj.type : "text"}
                                                            id={obj.key}
                                                            className='form-control'
                                                            placeholder={isTranslated ? obj.placeHolderT : obj.placeHolder}
                                                            name={obj.key}
                                                            onChange={(e) => changeValueOTP(index, e.target.value)}
                                                            value={obj.value}
                                                            required
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))
                                )
                            }
                            {
                                modalInfo.link && (
                                    <div className='col-xs-12 padt_15'>
                                        <Link to={modalInfo.linkURL} target="_blank" className="bdm_link">
                                            <u>
                                                {
                                                    isTranslated ? modalInfo.linkT : modalInfo.link 
                                                }
                                            </u>
                                        </Link>
                                    </div>
                                )
                            }
                            <div className='btn-div-p'>
                                <div className='back-btn-pp' onClick={back}>
                                    {
                                        isTranslated ? 'Kembali' : 'Back'
                                    }
                                </div>
                                {/* <button type="button" className='btn nav-btn-pwc back-btn-p' onClick={back}>
                                    Back
                                </button> */}
                                <button type="submit" className='btn nav-btn-pwc next-btn'>
                                    {
                                        isTranslated ? 'Selanjutnya' : 'Next'
                                    }
                                </button>
                                {/* <button type="button" className='btn nav-btn-pwc back-btn-p-m' onClick={back}>
                                    Back
                                </button> */}
                            </div>
                    </form>
                </div>
            </div>
            {/* <div className='btn-div-p'>
                <button type="button" className='btn nav-btn-pwc back-btn-p' onClick={back}>
                    Back
                </button>
                <button type="button" className='btn nav-btn-pwc next-btn' onClick={submit}>
                    NEXT
                </button>
            </div> */}
        </div>
    );
}
 
export default PartnerPage;