import { Modal, Button } from 'react-bootstrap';

const Message = (props) => {
    const { message, closeMessage, title } = props;
    return ( 
        <div>
            <Modal 
                show={true}
                onHide={closeMessage}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{ title === null ? 'Data Confirmation' : title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { message }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeMessage}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
     );
}
 
export default Message;