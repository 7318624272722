import React from 'react';

function styleInject(css, ref) {
  if ( ref === void 0 ) ref = {};
  var insertAt = ref.insertAt;

  if (!css || typeof document === 'undefined') { return; }

  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';

  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild);
    } else {
      head.appendChild(style);
    }
  } else {
    head.appendChild(style);
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

var css = "";
styleInject(css);

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

var JSONToHTMLTable = function JSONToHTMLTable(props) {
  var data = props.data,
      wrapperClassName = props.wrapperClassName,
      tableClassName = props.tableClassName,
      tableId = props.tableId;

  return React.createElement(
    'div',
    { className: wrapperClassName },
    React.createElement(
      'table',
      { className: tableClassName, id: tableId },
      React.createElement(
        'tbody',
        null,
        Object.keys(data).map(function (k) {
          return React.createElement(
            'tr',
            { key: k },
            !Array.isArray(data) && React.createElement(
              'td',
              null,
              k.replace(/_/g, ' ')
            ),
            function () {
              if (data[k] && _typeof(data[k]) === 'object') {
                return React.createElement(
                  'td',
                  null,
                  React.createElement(JSONToHTMLTable, { data: data[k], tableClassName: tableClassName, tableId: tableId })
                );
              }
              return React.createElement(
                'td',
                null,
                React.createElement('span', { dangerouslySetInnerHTML: { __html: data[k] } })
              );
            }()
          );
        })
      )
    )
  );
};

export { JSONToHTMLTable };
