import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { Form } from 'react-bootstrap';
// import PWC from 'library-javascript-pwc';
import PWC from '../token/PWC';

import Widget from "./Widget";
import Submit from "./Submit";
import ErrorModal from './ErrorModal';
import Success from './Success';
import Message from './Message';
import FullPageLoader from '../components/FullPageLoader';
import "../pages/Home.css";
import Response from '../components/Response';
import Confirmation from './Confirmation';
import OTPModal from './OTPModal';
import axios from 'axios';

const Home = (props) => {
    let { partners, setHome, responseT, setHomeWrongToken, token, idT, client_id } = props;
    partners = responseT;

    const initialECommerceList = [
        {
            code: 'instagram',
            name: 'Instagram',
            icon: 'images/instagram_icon.png',
            category: 'Social',
            isShowing: partners.find(partner => partner === 'instagram') !== undefined,
            isSelected: false,
            params: [
                { key: 'profile_id', value: '', label: 'Enter your profile id:', placeHolder: 'Enter profile id'}
            ],
            modalInfo: {
                title: 'Instagram Authorization'
            }
        },
        {
            code: 'lazada',
            name: 'Lazada',
            icon: 'images/lazada_icon.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'lazada') !== undefined,
            isSelected: false,
            params: [
                { key: 'region', value: '', label: 'Please select your region', type: 'options', options: ['Indonesia', 'Singapore', 'Malaysia', 'Thailand', 'Vietnam', 'Philippines']}
            ],
            modalInfo: {
                title: 'Authorize Lazada Seller'
            }
        },
        {
            code: 'shopee',
            name: 'Shopee',
            icon: 'images/shopee.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'shopee') !== undefined,
            isSelected: false,
            ___params: [
                { key: 'shop_name', value: '', label: 'Shopee shop name:', placeHolder: 'Enter your Shopee shop name'}
            ],
            modalInfo: {
                title: 'Shopee Authorization'
            }
        },
        {
            code: 'blibli',
            name: 'Blibli',
            icon: 'images/blibli.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'blibli') !== undefined,
            isSelected: false,
            params: [
                { key: 'username', value: '', label: 'Blibli seller registered email id', placeHolder: 'Enter your Blibli seller registered email id'},
                { key: 'store_code', value: '', label: 'Store id', placeHolder: 'Enter your Store id'},
                { key: 'api_seller_key', value: '', label: 'API seller key:', placeHolder: 'Enter your API seller key'}
            ],
            modalInfo: {
                title: 'Blibli Authorization',
                specialNote: '*Note: Seller have to bind our API Client ID before entering below details.',
                linkURL: '/BlibliStoreBindReference',
                link: 'How to bind and connect your Blibli store?'
            }
        },
        {
            code: 'xero',
            name: 'Xero',
            icon: 'images/xero.png',
            category: 'Accounting',
            isShowing: partners.find(partner => partner === 'xero') !== undefined,
            isSelected: false,
            params: [
                { key: 'business_name', value: '', label: 'Enter your organization name:', placeHolder: 'Enter organization name'}
            ],
            modalInfo: {
                title: 'Xero Authorization'
            }
        },
        {
            code: 'location',
            name: 'Google Location',
            icon: 'images/gl_icon.jpg',
            category: 'Social',
            isShowing: partners.find(partner => partner === 'location') !== undefined,
            isSelected: false,
            params: [
                { key: 'place_search_text', value: '', label: 'Enter place name', placeHolder: 'Ex: Google Singapore, Sarasbaug Pune'}
            ],
            modalInfo: {
                title: 'Google Location Fetching'
            }
        },
        {
            code: 'tokopedia',
            name: 'Tokopedia',
            icon: 'images/tokopedia.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'tokopedia') !== undefined,
            isSelected: false,
            params: [
                { key: 'shop_url', value: '', label: 'Enter your Shop URL', placeHolder: 'Enter Shop URL'}
            ],
            modalInfo: {
                title: 'Tokopedia Authorization'
            }
        },
        {
            code: 'bankconnect',
            name: 'Retail/Personal Banking',
            icon: '',
            category: 'Retail/Personal Banking',
            isShowing: partners.find(partner => partner === 'bankconnect') !== undefined,
            isSelected: false,
            params: [
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password'}
            ],
            modalInfo: {
                title: 'Retail/Personal Banking Authorization'
            },
            banks: [
                {
                    code: 'bca_bc',
                    name: 'BCA',
                    icon: 'images/BCA.png',
                    isSelected: false,
                    data: { institution_id: 2 }
                },
                {
                    code: 'bri_bc',
                    name: 'BRI',
                    icon: 'images/BRI.webp',
                    isSelected: false,
                    data: { institution_id: 5 }
                },
                {
                    code: 'bni_bc',
                    name: 'BNI',
                    icon: 'images/BNI.webp',
                    isSelected: false,
                    data: { institution_id: 4 }
                },
                {
                    code: 'mandiri_bc',
                    name: 'Mandiri',
                    icon: 'images/Mandiri.webp',
                    isSelected: false,
                    data: { institution_id: 3 }
                }
            ],
            data: { 
                institution_id: null 
            }
        },
        {
            code: 'bankVerification',
            name: 'Bank Verification',
            icon: '',
            category: 'Bank Verification',
            isShowing: partners.find(partner => partner === 'bankVerification') !== undefined,
            isSelected: false,
            params: [
                { key: 'name', value: '', label: 'Name', placeHolder: 'Enter Name'},
                { key: 'nik', value: '', label: 'NIK', placeHolder: 'Enter NIK'},
                { key: 'account_number', value: '', label: 'Account Number', placeHolder: 'Enter Account Number'}
            ],
            modalInfo: {
                title: 'Bank Verification'
            },
            banks: [
                {
                    code: 'bca_bcv',
                    name: 'BCA',
                    icon: 'images/BCA.png',
                    isSelected: false,
                    data: { bank_short_code: 'BCA' }
                },
                {
                    code: 'bri_bcv',
                    name: 'BRI',
                    icon: 'images/BRI.webp',
                    isSelected: false,
                    data: { bank_short_code: 'BRI' }
                },
                {
                    code: 'bni_bcv',
                    name: 'BNI',
                    icon: 'images/BNI.webp',
                    isSelected: false,
                    data: { bank_short_code: 'BNI' }
                },
                {
                    code: 'mandiri_bcv',
                    name: 'Mandiri',
                    icon: 'images/Mandiri.webp',
                    isSelected: false,
                    data: { bank_short_code: 'MANDIRI' }
                },
                {
                    code: 'cimb_bcv',
                    name: 'CIMB',
                    icon: 'images/CIMB.png',
                    isSelected: false,
                    data: { bank_short_code: 'CIMB_NIAGA' }
                },
                {
                    code: 'danamon_bcv',
                    name: 'Danamon',
                    icon: 'images/danamon.png',
                    isSelected: false,
                    data: { bank_short_code: 'DANAMON' }
                }
            ],
            data: { 
                bank_short_code: null 
            }
        },
        {
            code: 'altconnect',
            name: 'Corporate Banking',
            icon: '',
            category: 'Corporate Banking',
            isShowing: partners.find(partner => partner === 'altconnect') !== undefined,
            isSelected: false,
            params: [
                { key: 'identifier', value: '', label: 'Username', placeHolder: 'Enter Username'},
                { key: 'secret', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password'}
            ],
            modalInfo: {
                title: 'Corporate Banking Authorization'
            },
            banks: [
                {
                    code: 'bca_ac',
                    name: 'BCA',
                    icon: 'images/BCA.png',
                    isSelected: false,
                    data: { bank_code: 'BCA_CORPORATE' }
                },
                {
                    code: 'bni_ac',
                    name: 'BNI',
                    icon: 'images/BNI.webp',
                    isSelected: false,
                    data: { bank_code: 'BNI_CORPORATE' }
                },
                {
                    code: 'mandiri_ac',
                    name: 'Mandiri',
                    icon: 'images/Mandiri.webp',
                    isSelected: false,
                    data: { bank_code: 'MANDIRI_CORPORATE' }
                }
            ],
            data: { 
                bank_code: null 
            }
        },
        {
            code: 'shopeeVerification',
            name: 'Shopee Verification',
            icon: 'images/shopee.png',
            category: 'Shopee Verification',
            isShowing: partners.find(partner => partner === 'shopeeVerification') !== undefined,
            isSelected: false,
            params: [
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username'},
                { key: 'accountNo', value: '', label: 'Account Number', placeHolder: 'Enter Account Number'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password'}
            ],
            modalInfo: {
                title: 'Shopee Verification'
            }
        },
        {
            code: 'tokopediaVerification',
            name: 'Tokopedia Verification',
            icon: 'images/tokopedia.png',
            category: 'Tokopedia Verification',
            isShowing: partners.find(partner => partner === 'tokopediaVerification') !== undefined,
            isSelected: false,
            params: [
                { key: 'shop_url', value: '', label: 'Enter your Shop URL', placeHolder: 'Enter Shop URL'},
                { key: 'bank_acc', value: '', label: 'Enter your Bank account', placeHolder: 'Enter bank account'}
            ],
            modalInfo: {
                title: 'Tokopedia Authorization'
            }
        },
        {
            code: 'jurnal',
            name: 'Jurnal',
            icon: 'images/jurnal_icon.png',
            category: 'Accounting',
            isShowing: partners.find(partner => partner === 'jurnal') !== undefined,
            isSelected: false,
            params: [
                { key: 'user_email', value: '', type: "email", label: 'Enter your Email id:', placeHolder: 'Enter email id'}
            ],
            modalInfo: {
                title: 'Jurnal Authorization'
            }
        },
        {
            code: 'mokapos',
            name: 'Mokapos',
            icon: 'images/mokapos_icon.webp',
            category: 'Payment',
            isShowing: partners.find(partner => partner === 'mokapos') !== undefined,
            isSelected: false,
            params: [
                { key: 'business_name', value: '', label: 'Business name', placeHolder: 'Enter your business name'}
            ],
            modalInfo: {
                title: 'Mokapos Authorization'
            }
        },
        {
            code: 'plncheck',
            name: 'PlnCheck',
            icon: 'images/plncheck_icon.jpeg',
            category: 'Utility Bills',
            isShowing: partners.find(partner => partner === 'plncheck') !== undefined,
            isSelected: false,
            params: [
                { key: 'customername', value: '', label: 'Customer name', placeHolder: 'Enter your customer name'},
                { key: 'phonenumber', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number'},
                { key: 'accountnumber', value: '', label: 'Account number', placeHolder: 'Enter your account number'}
            ],
            modalInfo: {
                title: 'PlnCheck Authorization'
            }
        },
        {
            code: 'ovo',
            name: 'Ovo',
            icon: 'images/ovo_icon.jpeg',
            category: 'Payment',
            isShowing: partners.find(partner => partner === 'ovo') !== undefined,
            isSelected: false,
            params: [
                { key: 'username', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', isPhoneNo: true},
                { key: 'password', value: '', type: 'password', label: 'Pin', placeHolder: 'Enter your Pin'},
            ],
            paramsOTP: [
                { key: 'otp', value: '', type: 'number', label: 'OTP', placeHolder: 'Enter your otp'},
            ],
            modalInfo: {
                title: 'Ovo Authorization'
            },
            isOTP: true,
            errorMessage: {
                head: undefined,
                body: 'Authentication failed!'
            },
            paramsHandle(params){
                params['username'] = `+62${params['username']}`;
            }
        },
        {
            code: 'gopay',
            name: 'Gopay',
            icon: 'images/gopay_icon.jpeg',
            category: 'Payment',
            isShowing: partners.find(partner => partner === 'gopay') !== undefined,
            isSelected: false,
            params: [
                { key: 'username', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', isPhoneNo: true},
                { key: 'password', value: '', type: 'password', label: 'Pin', placeHolder: 'Enter your Pin'},
            ],
            paramsOTP: [
                { key: 'otp', value: '', type: 'number', label: 'OTP', placeHolder: 'Enter your otp'},
            ],
            modalInfo: {
                title: 'Gopay Authorization'
            },
            isOTP: true,
            errorMessage: {
                head: undefined,
                body: 'Authentication failed!'
            },
            paramsHandle(params){
                params['username'] = `+62${params['username']}`;
            }
        },
    ];

    const [eCommerceList, setECommerceList] = useState(initialECommerceList);

    const[isSubmitChecked, setSubmitChecked] = useState(false);
    
    const [isLoading, setLoader] = useState(false);
    
    const [isError, setIsError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);

    const [isSuccess, setIsSuccess] = useState(false);
    
    const [message, setMessage] = useState(null);

    const [title, setTitle] = useState(null);

    const changeSubmitCheckbox = () => setSubmitChecked(!isSubmitChecked);

    const closeResponse = () => {
        setIsError(false);
        setErrorMessage(false);
    }

    const closeSuccess = () => setIsSuccess(false);

    const closeMessage = () => setMessage(null);

    const navigate = useNavigate();

    const [pwc, setPwc] = useState(null);

    const categories = [ 'E-Commerce', 'Accounting', 'Payment', 'Social', 'Utility Bills', 'Retail/Personal Banking', 'Corporate Banking', 'Bank Verification', 'Shopee Verification', 'Tokopedia Verification'];

    const [category, setCategory] = useState({});

    const [widgetResponse, setWidgetResponse] = useState({});

    const [unprocessedCount, setUnprocessedCount] = useState([]);

    const [isTimeOut, setIsTimeOut] = useState(false);
    
    const [showOTPModal, setShowOTPModal] = useState(false);

    let widgetResponseVar = widgetResponse;
    let unprocessedCountVar = unprocessedCount;
    let isTimeOutVar = isTimeOut;

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [showResponse, setShowResponse] = useState(false);

    const processSuccess = (index, bankIndex, bankCode, result) => {
        const newECommerceList = [...eCommerceList];
        newECommerceList[index] = {...newECommerceList[index]};
        newECommerceList[index].isSelected = true;
        if(newECommerceList[index].banks){
            newECommerceList[index].banks = newECommerceList[index].banks.map(bank => {
                bank.isSelected = bank.code === bankCode;
                return {...bank};
            });
        }
        setECommerceList(newECommerceList);
        if(result.showMessage){
            setMessage(result.showMessage);
            setTitle('');
        }
        const bank_code = eCommerceList[index].banks ? { bank: eCommerceList[index].banks[bankIndex].code } : {};
        window.gtag('event', 'widget_success', {
            'partner_name': eCommerceList[index].code,
            ...bank_code
        });
    }

    const updateParams = async (index, params, bankIndex) => {
        if(!verifytoken()){
            return;
        }
        params = params.reduce((prev, curr) => {
            prev[curr.key] = curr.value;
            return prev;
        }, {});
        eCommerceList[index].paramsHandle && eCommerceList[index].paramsHandle(params);

        let bankCode;
        if(eCommerceList[index].banks){
            bankCode = eCommerceList[index].banks[bankIndex].code;
            eCommerceList[index].data = eCommerceList[index].banks[bankIndex].data;
        }
        setLoader(true);
        const result = await pwc.setParameters(eCommerceList[index].code, params, eCommerceList[index].data, bankCode);
        setLoader(false);

        if(result.isSuccess){
            if(eCommerceList[index].isOTP){
                setShowOTPModal({index, eCommerce: eCommerceList[index]});
            }
            else{
                processSuccess(index, bankIndex, bankCode, result);
            }
        }
        else{
            setIsError(true);
            eCommerceList[index].errorMessage && setErrorMessage(eCommerceList[index].errorMessage);
        }
    }

    const updateParamsOTP = async (index, params) => {
        if(!verifytoken()){
            return;
        }
        params = params.reduce((prev, curr) => {
            prev[curr.key] = curr.value;
            return prev;
        }, {});

        setLoader(true);
        const result = await pwc.setOtp(eCommerceList[index].code, params);
        setLoader(false);
        
        if(result.isSuccess){
            processSuccess(index, undefined, undefined, result);
        }
        else{
            setIsError(true);
            setErrorMessage({
                head: '',
                body: 'OTP Authentication failed!'
            });
        }
    }

    const unSelect = (index, bankIndex) => {
        if(!verifytoken()){
            return;
        }
        const bankCode = eCommerceList[index].banks ? eCommerceList[index].banks[bankIndex].code : undefined;
        
        const result = pwc.unselect(eCommerceList[index].code, bankCode);
        
        if(result.isSuccess){
            const newECommerceList = [...eCommerceList];
            newECommerceList[index] = {...newECommerceList[index]};
            newECommerceList[index].isSelected = false;
            if(newECommerceList[index].banks){
                newECommerceList[index].banks = newECommerceList[index].banks.map(bank => {
                    bank.isSelected = false;
                    return {...bank};
                });
            }
            setECommerceList(newECommerceList);
            return true;
        }
        return false;
    };
    
    const submitData = async () => {
        if(!verifytoken()){
            return;
        }
        window.gtag('event', 'submit_click', {});
        if(!isSubmitChecked){
            setMessage('Please authorize by checking the confirmation box');
            return;
        }
        if(eCommerceList.filter(eCommerce => eCommerce.isSelected).length === 0){
            setMessage('Please select atleast one data partner');
            return;
        }

        setLoader(true);
        const result = await pwc.submit(true);

        if(result.isSuccess){
            for(let partner of Object.keys(result.data.gateway_res)){
                const data = result.data.gateway_res;
                if(data[partner].status_code && data[partner].status_code == 202){
                    unprocessedCountVar.push(partner);
                    getResponse(partner);
                }
            }
            if(!unprocessedCountVar.length){
                success(result.data);
                return;
            }
            widgetResponseVar = result.data;
            setWidgetResponse(result.data);
            setUnprocessedCount(unprocessedCountVar);
            setTimeout(() => {
                if(unprocessedCountVar.length){
                    setIsError(true);
                    setIsTimeOut(true);
                    isTimeOutVar = true;
                    error({error: {data: unprocessedCountVar}}, true);
                }
            }, 200000);
        }
        else{
            error(result);
        }
    };

    // console.log('main body', unprocessedCountVar);

    const getResponse = async (code) => {
        if(!verifytoken()){
            return;
        }
        console.log(code, ' called')//, widgetResponse, widgetResponseVar, unprocessedCount, unprocessedCountVar);

        const response = await pwc.getProfileData(code);
        if(!response.isError && (response.data.status_code == undefined || response.data.status_code != 202)){
            const widgetResponseNew = {...widgetResponseVar};
            widgetResponseNew.gateway_res[code] = response.data;
            setWidgetResponse(widgetResponseNew);
            widgetResponseVar = widgetResponseNew;
            const index = unprocessedCountVar.indexOf(unprocessedCountVar.find(partner => partner == code));
            unprocessedCountVar.splice(index, 1);
            setUnprocessedCount(unprocessedCountVar);
            console.log(code, ' success') //, widgetResponseNew, unprocessedCountVar);
            if(!unprocessedCountVar.length){
                success(widgetResponseNew);
            }
        }
        else if(response.isError){
            console.log(code, ' error')
            error({error: {data: unprocessedCountVar}}, true);
        }
        else if(!isTimeOutVar){
            setTimeout(() => getResponse(code), 10000);
        }
    };

    const success = (data) => {
        setLoader(false);
        let newECommerceList = [...eCommerceList];
        newECommerceList = newECommerceList.map(eCommerce => {
            eCommerce.isSelected = false;
            if(eCommerce.banks){
                eCommerce.banks = eCommerce.banks.map(bank => {
                    bank.isSelected = false;
                    return {...bank};
                });
            }
            return {...eCommerce};
        });
        setECommerceList(newECommerceList);
        changeSubmitCheckbox();

        console.log(data);
        setShowResponse(data);
        window.gtag('event', 'submit_success', {});
        // navigate('/Response', {state:{data: result.data}});
    };

    const error = (result, isReset) => {
        setLoader(false);
        let newECommerceList = [...eCommerceList];
        if(isReset){
            newECommerceList = newECommerceList.map(eCommerce => {
                eCommerce.isSelected = false;
                if(eCommerce.banks){
                    eCommerce.banks = eCommerce.banks.map(bank => {
                        bank.isSelected = false;
                        return {...bank};
                    });
                }
                return {...eCommerce};
            });
            changeSubmitCheckbox();
            createPWCObject();
            setWidgetResponse({});
            setUnprocessedCount([]);
            setIsTimeOut(false);
        }
        else{
            result.error.data.forEach(code => {
                const eCommerce = newECommerceList.find(eCommerce => eCommerce.code === code);
                const index = newECommerceList.indexOf(eCommerce);
                newECommerceList[index] = {...newECommerceList[index]};
                newECommerceList[index].isSelected = false;
                if(newECommerceList[index].banks){
                    newECommerceList[index].banks = newECommerceList[index].banks.map(bank => {
                        bank.isSelected = false;
                        return {...bank};
                    });
                }
            });
        }
        setECommerceList(newECommerceList);
        setIsError(true);
        window.gtag('event', 'submit_error', {
            'partner_list': result.error.data
        });
    };

    const createPWCObject = () => {
        const apiKey = client_id;
        const pwc = new PWC({ 
            apiKey,
            id: idT,
            callback: (companyID) => {
                window.gtag('config', 'G-0WQWYRSVZK', {
                    'user_id': companyID
                });
            }
         });
        setPwc(pwc);
    };

    const verifytoken = async() => {
        const config = {
            method: 'post',
            url: `https://dev.powercred.io/auth/token/verify?apikey=${client_id}&client_id=${client_id}&access_token=${token}`,
            headers: { 
                'Authorization': `Bearer ${token}`
            }
        };
        let error;
        let response;
        try{
            response = await axios(config);
            console.log(response.data);
            if(response.data.status == 'success'){
                error = false;
            }
            else{
                error = true;
            }
        }
        catch(e){
            console.log('error ', e);
            error = true;
        }

        if(!error ){
            return true;
        }
        else{
            setHomeWrongToken();
            return false;
        }
    };

    useEffect(() => {
        createPWCObject();

        const category = eCommerceList.filter(eCommerce => eCommerce.isShowing).reduce((prev, eCommerce) => {
            if(eCommerce.category in prev){
                prev[eCommerce.category].push(eCommerce.code);
            }
            else{
                prev[eCommerce.category] = [eCommerce.code];
            }
            return prev;
        }, {});
        setCategory(category);
    }, []);

    if(showResponse){
        return <Response widget_res={showResponse} obj={pwc} goBack={() => {setShowResponse(null);}}/>;
    }
    return ( 
        <div>
            <div>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <img src={require('../components/images/powercred_logo.jpg')} alt="powercred_logo" height="40" />
                        <div className="navbar-brand" id="google_translate_element_i"></div>
                    </div>
                </nav>
            </div>
            <div className="container pad_15 widget-container">
                <h4>Alternate Business Details</h4>
                <h6 className='sp_title'>Select Partner(s) from each category</h6>
                <Form>
                    <div className='pad_0'>
                        {
                            categories.filter(key => key in category).map(key => {
                                return (
                                    <div key={key}>
                                        <p className="dpc_title">{key}</p>
                                        <div className="row">
                                        {
                                            key == 'Retail/Personal Banking' || key == 'Corporate Banking' || key == 'Bank Verification' 
                                            ?
                                            eCommerceList.find(eCommerce => eCommerce.code === category[key][0]).banks.map((bank, bankIndex) => {
                                                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code === category[key][0]);
                                                const index = eCommerceList.indexOf(eCommerce);
                                                return <Widget key={bank.code} eCommerce={eCommerce} index={index} updateParams={updateParams} removeSelected={unSelect} bankIndex={bankIndex}/>;
                                            })
                                            :
                                            category[key].map(code => {
                                                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code === code);
                                                const index = eCommerceList.indexOf(eCommerce);
                                                return <Widget key={code} eCommerce={eCommerce} index={index} updateParams={updateParams} removeSelected={unSelect}/>;
                                            })
                                        }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="row">
                        <Submit isSubmitChecked={isSubmitChecked} changeSubmitCheckbox={changeSubmitCheckbox} submitData={submitData} showConfirmModal={() => setShowConfirmModal(true)}/>
                    </div>
                </Form>
            </div>
            {isLoading && <FullPageLoader />}
            {isError && <ErrorModal closeResponse={closeResponse} errorMessage={errorMessage}/>}
            {isSuccess && <Success closeResponse={closeSuccess}/>}
            {message && <Message message={message} title={title} closeMessage={closeMessage}/>}
            {showConfirmModal && <Confirmation confirmed={setHome} closeMessage={() => setShowConfirmModal(false)} message='Are you sure you want to go back and cancel current process?'/>}
            {showOTPModal && <OTPModal index={showOTPModal.index} eCommerce={showOTPModal.eCommerce} updateParamsOTP={updateParamsOTP} close={() => setShowOTPModal(false)}/>}
        </div>
    );
}
 
export default Home;