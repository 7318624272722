import { useEffect, useState } from 'react';
import './Modal.css';

const ShowModel = (props) => {
    const { closeModal, submitBtn, issubmitted, isTranslated, fileData } = props;
    const [btnselect, setBtnselect] = useState(false)
    const handleClick = () => {
        closeModal();
    };

    const [selectedBanks, setSelectedBanks] = useState({});

    const handleBankChange = (fileName, bank) => {
        setBtnselect(true)
        console.log('Selected File:', fileName);
        console.log('Selected Bank:', bank);
        setSelectedBanks((prevState) => ({
            ...prevState,
            [fileName.name]: {
                fileName,
                bank,
              },
       
        }));
       
    };
    return (
        <div id="myModal" className="modal-new" onClick={(e) => { return; }}>
            <div className="modal-content-new">
                {!issubmitted && (
                    <>
                        <span className="close-new" onClick={handleClick}>&times;</span>
                        <div className="head-name">
                            {
                                isTranslated ? <h4>Sistem tidak bisa mendeteksi bank secara otomatis</h4> : <h4>System can't identify bank for file(s) below </h4>
                            }
                            {
                                isTranslated ? <h5>Tambahkan bank untuk file berikut</h5> : <h5>
                                    Please identify the bank as they belong to
                                </h5>
                            }

                        </div>
                        <div>
                            <table className='showtable'>
                                <thead>
                                    <tr>
                                        {
                                            isTranslated ? <th>Nama file</th> : <th>File Name</th>
                                        }
                                        <th>Bank</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileData.map((fileName) => (
                                        <tr key={fileName.name}>
                                            <td title={fileName.name}>{fileName.name}</td>
                                            <td>
                                                <select
                                                    value={selectedBanks[fileName.name]?.bank || 'default'}
                                                    onChange={(e) => handleBankChange(fileName, e.target.value)}
                                                >
                                                    <option value="default" disabled>
                                                        Select Bank
                                                    </option>
                                                    <option value="bca">BCA</option>
                                                    <option value="mandiri">Mandiri</option>
                                                    <option value="bni">BNI</option>
                                                    <option value="bri">BRI</option>
                                                </select>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>                           
                            <div className={`export-csv-btn download-excel-modal ${!btnselect ? 'fade-btn':''}`} onClick={() => submitBtn(selectedBanks)}>
                            Submit
                        </div>      
                    </>
                )
                }
                {
                    issubmitted && (
                        <>


                            {
                                isTranslated ? 
                                <>  <span className="close-new" onClick={handleClick} >&times;</span> 
                                    <div className="close-data">File berhasil dikirim!</div>
                                </> 
                                : 
                                <>
                                    <span className="close-new" onClick={handleClick} >&times;</span>
                                    <div className="close-data">File submitted successfully!</div>   
                                </>
                            }
                        </>
                    )

                }
            </div>
        </div>
    );
}

export default ShowModel;