import axios from 'axios';
import LocalstorageService from './localstorage.service';

const isProduction = window.location.href.includes('https://auth.powercred.io')||window.location.href.includes('https://docs.julo.co.id');
const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

const wantLocal = false; // Must be false in before deployment

export const getURL = async (id, bankname) => {
    try {
        let localstorageService = new LocalstorageService();
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        
        const url = wantLocal ? 
        `http://127.0.0.1:8000/stmt/upload/test?apikey=${apiKey}&id=${id}&bankname=${bankname}` : 
        `${baseURL}/bank/analysis/stmt/upload/img?apikey=${apiKey}&id=${id}&bankname=${bankname}`;
        
        return url;
    } catch (error) {
        console.error('Error Returning URL:', error);
        return null;
    }
}

export const uploadStatementsIMG = async (id, bankname, images) => {
    try {
        let localstorageService = new LocalstorageService();
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

        const url = wantLocal ? 
        `http://127.0.0.1:8000/stmt/upload/img?apikey=${apiKey}&id=${id}&bankname=${bankname}` : 
        `${baseURL}/bank/analysis/stmt/upload/img?apikey=${apiKey}&id=${id}&bankname=${bankname}`;

        const uploadPromises = images.map(image => {
            let formData = new FormData();
            formData.append('files', image);
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        });

        await Promise.all(uploadPromises);
        return { status: 200 }
    } catch (error) {
        console.error('Error Uploading Bank Statements:', error);
        return null;
    }
};

export const uploadStatements = async (id, bankname = "", pdfs, isImage = false, password) => {
    try {
        let localstorageService = new LocalstorageService();
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');
        const falseFiles = [];
        
        const url = wantLocal ? 
        `http://127.0.0.1:8000/stmt/upload?apikey=${apiKey}&id=${id}&bankname=${bankname}&is_img=${isImage}&password=${password}` : 
        `${baseURL}/bank/analysis/stmt/upload?apikey=${apiKey}&id=${id}&bankname=${bankname}&is_img=${isImage}&password=${password}`;

        const  uploadPromises = pdfs.map(pdf => {
            let formData = new FormData();
            formData.append('files', pdf);
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        });

        let responses = await Promise.all(uploadPromises);

        let success = false;

        responses.forEach(res => {
            if (res.data?.detail?.message === "Please provide bank name for this statement.") {
                falseFiles.push(...res.data.detail.file);
            }

            if (res.status === 200) {
                success = true;
            }
        });

        console.log("Files with issues:", falseFiles);

        if (isImage) {
            publishStatements(id);
            console.log("Calling Publish")
        }

        if (isImage === false && falseFiles.length === 0 && success) {
            publishStatements(id);
            console.log("Calling Publish")
        }


        return { status: 200 , falseStatements: falseFiles}
    } catch (error) {
        
        if (error.response && error.response.data && error.response.data.detail) {
            const errorDetail = error.response.data.detail;
            const isPasswordProtected = Object.values(errorDetail).some(value => {
              return typeof value === 'string' && value.includes("PDF is password protected");
            });
            if (isPasswordProtected) {
              return { status: 400 , isPasswordProtected: isPasswordProtected}  
            } else {
              console.error('Error Uploading Bank Statements:', errorDetail);
            }
          } else {
            console.error('Error Uploading Bank Statements:', error);
          }
          return null;
    }
};

export const eachStatementsUploads = async (id, banknames, pdfs, isImage = false, password) => {
    try {
        let localstorageService = new LocalstorageService();
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

        const uploadPromises = pdfs.map((pdf, index) => {
            let url = wantLocal ? 
            `http://127.0.0.1:8000/stmt/upload?apikey=${apiKey}&id=${id}&bankname=${banknames[index]}&is_img=${isImage}&password=${password}` : 
            `${baseURL}/bank/analysis/stmt/upload?apikey=${apiKey}&id=${id}&bankname=${banknames[index]}&is_img=${isImage}&password=${password}`;

            let formData = new FormData();
            formData.append('files', pdf);
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        });

        let responses = await Promise.all(uploadPromises);

        let success = false;

        responses.forEach(res => {
            if (res.status === 200) {
                success = true;
            }
        });

        if (success) {
            publishStatements(id);
            console.log("Calling Publish")
        }

    } catch (error) {
        console.error('Error Uploading Bank Statements:', error);
        return null;
    }
} 

export const publishStatements = async (id) => {
    try{
        let localstorageService = new LocalstorageService();
        const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

        const url = wantLocal ? 
        `http://127.0.0.1:8000/stmt/publish?apikey=${apiKey}&id=${id}` : 
        `${baseURL}/bank/analysis/stmt/publish?apikey=${apiKey}&id=${id}`;

        const response = await axios.post(url);
        return response.data;
    } catch (error) 
    {
        console.error('Error Publishing Bank Statements:', error);
        return null;
    }
}
