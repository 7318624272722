import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const ErrorModal = (props) => {
    const { closeResponse, errorMessage, errorMessageToken, isTranslated } = props;

    const [timer, setTimer] = useState(6);
    
    const timerFun = () => {
        setTimer(timer => {
            if(timer == 1){
                closeResponse();
            }
            else{
                setTimeout(() => {
                    timerFun();
                }, 1000);
            }
            return timer-1;
        });
    };

    const translation = {
        "PDF either edited, or incorrect. Please upload a valid PDF e-statement!": "PDF salah atau tidak asli. Mohon untuk mengunggah PDF eStatement yang benar!",
        'PDF is password protected, please enter the password and try again!': 'PDF dilindungi kata sandi, masukkan kata sandi dan coba lagi!',
        'Incorrect PDF password, please try again!': 'Kata sandi PDF salah, silakan coba lagi!'
        
    }

    let  titleT = null;
    if(errorMessage && errorMessage.body && errorMessage.body == 'URL toko tidak valid. Coba lagi, ya!'){
        titleT = 'Proses Gagal';
    }
    else if(errorMessage && errorMessage.body && errorMessage.body == 'Kamu tidak bisa menghubungkan URL toko tersebut karena bukan toko resmi atau power merchant'){
        titleT = 'Gagal Menghubungkan';
    }

    if(errorMessage && errorMessage.head){
        titleT = errorMessage.head;
    }

    useEffect(() => {
        if(errorMessageToken){
            timerFun();
        }
    }, []);

    return (
        <div className='flash-message-outer'>
            <div className='flash-message-background'></div>
            <div className='flash-message-container flash-message-success'>
                <div className='flash-message-body'>
                    <div className='flash-message-icon-div'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" className='flash-message-success-icon' viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div className='flash-message-body'>
                        <div className='flash-message-title'>
                            {
                                isTranslated ? (titleT ? titleT : 'Kesalahan') : 'Error'
                            }
                        </div> <br/>
                        <div className='flash-message-info'>
                            {   
                                errorMessage && errorMessage.body && typeof errorMessage.body === 'object' && !Array.isArray(errorMessage.body) && errorMessage.body !== null ?
                                (
                                    Object.keys(errorMessage.body).filter(key => {
                                        return errorMessage.body[key] !== 'Uploaded successfully...';
                                    }).map((key, index) => {
                                        index = index + 1
                                        return (
                                            <div style={{paddingTop: '7px'}}>
                                                <span>
                                                    {index}. "{key}":&nbsp;
                                                </span>
                                                <span>
                                                    "{isTranslated ? translation[errorMessage.body[key]] : errorMessage.body[key]}"
                                                </span>
                                            </div>
                                        )
                                    })
                                ) : (
                                    isTranslated 
                                    ? (
                                        errorMessageToken 
                                        ? `Token yang salah/Kedaluwarsa. Anda akan diarahkan ke halaman Beranda klien dalam ${timer} detik` 
                                        : ( errorMessage && errorMessage.body ? errorMessage.body : 'Tidak dapat memproses data, mohon coba kembali!')
                                    )
                                    : (
                                        errorMessageToken 
                                        ? `Wrong/Expired token. You will be redirect to client's Home page in ${timer}s` 
                                        : ( errorMessage && errorMessage.body ? errorMessage.body : 'Unable to process data, please try again!')
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='flash-message-close' onClick={() => closeResponse()}>
                    {
                        isTranslated ? 'Tutup' : 'Close'
                    }
                </div>
            </div>
        </div>
    );
    return (
        <div className='flash-message-outer'>
            <div className='flash-message-container'>
                <div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className='flash-message-success-icon' width="24" height="24" fill="green" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                    </div>
                    <div className='flash-message-body'>
                        <div className='flash-message-title'>Success</div> <br/>
                        <div className='flash-message-info'>Your account has been saved</div>
                    </div>
                </div>
                <div className='flash-message-close' onClick={closeResponse}>
                    CLOSE
                </div>
            </div>
        </div>
    );
    return ( 
        <div>
            <Modal 
                show={true}
                onHide={closeResponse}
                backdrop="static"
                keyboard={false}
                className="resmodal"
            >
                <Modal.Header>
                    <div className='icon-box danger_backcolor' onClick={closeResponse}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className='bi bi-x' viewBox="0 0 16 16">
                            <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/>
                        </svg>
                    </div>
                    <Modal.Title className='w-100' align="center">
                        {
                            errorMessage.head !== undefined ? errorMessage.head : 'Sorry!'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p className="mbody_text">
                        {
                            errorMessage.body !== undefined ? errorMessage.body : 'Unable to process data, please try again!'
                        }
                    </p>
                </Modal.Body>
            </Modal>
        </div>
     );
}
 
export default ErrorModal;
