import { useState } from 'react';
import { Form } from 'react-bootstrap';

import InputModal from './InputModal';
import Confirmation from './Confirmation';

const Widget = (props) => {
    const { eCommerce, index, updateParams, removeSelected, bankIndex } = props;

    const partner = eCommerce.banks ? eCommerce.banks[bankIndex] : eCommerce;

    const [showModal, setShowModal] = useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);

    const confirmed = () => {
        removeSelected(index, bankIndex);
        setShowConfirmation(false);
        const bank_code = eCommerce.banks ? { bank: eCommerce.banks[bankIndex].code } : {};
        window.gtag('event', 'widget_unselect', {
            'partner_name': eCommerce.code, 
            ...bank_code
        });
    };

    const changeCheckbox = () => {
        if((eCommerce.banks && eCommerce.banks[bankIndex].isSelected) || (!eCommerce.banks && eCommerce.isSelected)){
            setShowConfirmation(true);
        }
        else{
            if(eCommerce.params){
                setShowModal(true);
            }
            else{
                updateParams(index, [], bankIndex);
            }
            const bank_code = eCommerce.banks ? { bank: eCommerce.banks[bankIndex].code } : {};
            window.gtag('event', 'widget_click', {
                'partner_name': eCommerce.code, 
                ...bank_code
            });
        }
    };

    return (
        <>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 pad_15">
                <div className="col-xs-12 cards" onClick={changeCheckbox}>
                    <Form.Check
                        inline
                        aria-label={`${partner.code}_check`}
                        name="ecommerce_group"
                        type="checkbox"
                        id={`${partner.code}_check`}
                        checked={partner.isSelected}
                        readOnly
                    />
                    <img src={require('../components/images/'+partner.icon)} alt={`${partner.code}_icon`} height="50" />
                    <p className="cards_name">{partner.name}</p>
                </div>
            </div>
            {
                showModal && <InputModal index={index} eCommerce={eCommerce} bankIndex={bankIndex} updateParams={updateParams} close={() => setShowModal(false)}/>
            }
            {
                showConfirmation && <Confirmation partner={partner.name} confirmed={confirmed} closeMessage={() => setShowConfirmation(false)}/>
            }
        </> 
     );
}
 
export default Widget;