import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Confirmation from './Confirmation';
import Message from './Message';
import PartnerPage from './PartnerPage';
import OTPModal from './OTPModal';
import FullPageLoader from '../components/FullPageLoader';
import ErrorModal from './ErrorModal';
import PWC from 'library-javascript-pwc';
import Success from './Success';
import SuccessModal from './SuccessModal';


const Partner3 = (props) => {
    const { isTranslated, translate } = props;

    const initialECommerceList = [
        {
            code: 'instagram',
            name: 'Instagram',
            icon: 'images/instagram_icon.png',
            category: 'Social',
            // isShowing: partners.find(partner => partner === 'instagram') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'profile_id', value: '', label: 'Enter your profile id:', placeHolder: 'Enter profile id', infoMessage: 'Please enter your profile'}
            ],
            modalInfo: {
                title: 'Instagram Authorization'
            },
            infoMessage: 'To connect, please enter your profile handle. This should be a public profile. For e.g Nike'
        },
        {
            code: 'lazada',
            name: 'Lazada',
            icon: 'images/lazada_icon.png',
            category: 'E-Commerce',
            // isShowing: partners.find(partner => partner === 'lazada') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'region', value: '', label: 'Please select your region', type: 'options', options: ['Indonesia', 'Singapore', 'Malaysia', 'Thailand', 'Vietnam', 'Philippines'], infoMessage: 'Please select your region where your Shop belongs, ex. Indonesia'}
            ],
            modalInfo: {
                title: 'Authorize Lazada Seller'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username and Password'
        },
        {
            code: 'shopee',
            name: 'Shopee',
            icon: 'images/shopee.png',
            category: 'E-Commerce',
            // isShowing: partners.find(partner => partner === 'shopee') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'shop_name', value: '', label: 'Shopee shop name:', placeHolder: 'Enter your Shopee shop name', infoMessage: 'Please enter your registered shop name'}
            ],
            modalInfo: {
                title: 'Shopee Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username, Password and Shop Name'
        },
        {
            code: 'blibli',
            name: 'Blibli',
            icon: 'images/blibli.png',
            category: 'E-Commerce',
            // isShowing: partners.find(partner => partner === 'blibli') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'username', value: '', label: 'Blibli seller registered email id', placeHolder: 'Enter your Blibli seller registered email id', infoMessage: 'Please enter your registered email address'},
                { key: 'store_code', value: '', label: 'Store id', placeHolder: 'Enter your Store id', infoMessage: 'Please enter your Store id'},
                { key: 'api_seller_key', value: '', label: 'API seller key:', placeHolder: 'Enter your API seller key', infoMessage: 'Please enter your API seller key'}
            ],
            modalInfo: {
                title: 'Blibli Authorization',
                specialNote: '*Note: Seller have to bind our API Client ID before entering below details.',
                linkURL: '/BlibliStoreBindReference',
                link: 'How to bind and connect your Blibli store?'
            },
            infoMessage: 'To connect, please enter API seller key, Store id and registered email id'
        },
        {
            code: 'xero',
            name: 'Xero',
            icon: 'images/xero.png',
            category: 'Accounting',
            // isShowing: partners.find(partner => partner === 'xero') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'business_name', value: '', label: 'Enter your organization name:', placeHolder: 'Enter organization name', infoMessage: 'Please enter your organization name'}
            ],
            modalInfo: {
                title: 'Xero Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username, Password and Business Name'
        },
        {
            code: 'location',
            name: 'Google Location',
            icon: 'images/gl_icon.jpg',
            category: 'Social',
            // isShowing: partners.find(partner => partner === 'location') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'place_search_text', value: '', label: 'Enter place name', placeHolder: 'Ex: Google Singapore, Sarasbaug Pune', infoMessage: 'Please enter your location'}
            ],
            modalInfo: {
                title: 'Google Location Fetching'
            },
            infoMessage: 'To connect, please enter the location name. For e,g Google Singapore'
        },
        {
            code: 'tokopedia',
            name: 'Tokopedia',
            icon: 'images/tokopedia.png',
            category: 'E-Commerce',
            // isShowing: partners.find(partner => partner === 'tokopedia') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'shop_url', value: '', label: 'Enter your Shop URL', placeHolder: 'Enter Shop URL', infoMessage: 'Please enter your Shop URL'}
            ],
            modalInfo: {
                title: 'Tokopedia Authorization'
            },
            infoMessage: 'To connect, please enter your Shop URL, for e.g "https://www.tokopedia.com/<shop_name>"'
        },
        {
            code: 'bankconnect',
            name: 'Retail/Personal Banking',
            icon: '',
            category: 'Retail/Personal Banking',
            // isShowing: partners.find(partner => partner === 'bankconnect') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password'}
            ],
            modalInfo: {
                title: 'Retail/Personal Banking Authorization'
            },
            banks: [
                {
                    code: 'bca_bc',
                    name: 'BCA',
                    icon: 'images/BCA.png',
                    isSelected: false,
                    data: { institution_id: 2 },
                    infoMessage: 'To connect, please enter your Username and Password'
                },
                {
                    code: 'bri_bc',
                    name: 'BRI',
                    icon: 'images/BRI.webp',
                    isSelected: false,
                    data: { institution_id: 5 },
                    infoMessage: 'To connect, please enter your Username and Password'
                },
                {
                    code: 'bni_bc',
                    name: 'BNI',
                    icon: 'images/BNI.webp',
                    isSelected: false,
                    data: { institution_id: 4 },
                    infoMessage: 'To connect, please enter your Username and Password'
                },
                {
                    code: 'mandiri_bc',
                    name: 'Mandiri',
                    icon: 'images/Mandiri.webp',
                    isSelected: false,
                    data: { institution_id: 3 },
                    infoMessage: 'To connect, please enter your Username and Password'
                }
            ],
            data: { 
                institution_id: null 
            }
        },
        {
            code: 'bankVerification',
            name: 'Bank Verification',
            icon: '',
            category: 'Bank Verification',
            // isShowing: partners.find(partner => partner === 'bankVerification') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'name', value: '', label: 'Name', placeHolder: 'Enter Name', infoMessage: 'Please enter your Name'},
                { key: 'nik', value: '', label: 'NIK', placeHolder: 'Enter NIK', infoMessage: 'Please enter your NIK'},
                { key: 'account_number', value: '', label: 'Account Number', placeHolder: 'Enter Account Number', infoMessage: 'Please enter your Account Number'}
            ],
            modalInfo: {
                title: 'Bank Verification'
            },
            banks: [
                {
                    code: 'bca_bcv',
                    name: 'BCA',
                    icon: 'images/BCA.png',
                    isSelected: false,
                    data: { bank_short_code: 'BCA' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number'
                },
                {
                    code: 'bri_bcv',
                    name: 'BRI',
                    icon: 'images/BRI.webp',
                    isSelected: false,
                    data: { bank_short_code: 'BRI' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number'
                },
                {
                    code: 'bni_bcv',
                    name: 'BNI',
                    icon: 'images/BNI.webp',
                    isSelected: false,
                    data: { bank_short_code: 'BNI' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number'
                },
                {
                    code: 'mandiri_bcv',
                    name: 'Mandiri',
                    icon: 'images/Mandiri.webp',
                    isSelected: false,
                    data: { bank_short_code: 'MANDIRI' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number'
                },
                {
                    code: 'cimb_bcv',
                    name: 'CIMB',
                    icon: 'images/CIMB.png',
                    isSelected: false,
                    data: { bank_short_code: 'CIMB_NIAGA' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number'
                },
                {
                    code: 'danamon_bcv',
                    name: 'Danamon',
                    icon: 'images/danamon.png',
                    isSelected: false,
                    data: { bank_short_code: 'DANAMON' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number'
                }
            ],
            data: { 
                bank_short_code: null 
            }
        },
        {
            code: 'altconnect',
            name: 'Corporate Banking',
            icon: '',
            category: 'Corporate Banking',
            // isShowing: partners.find(partner => partner === 'altconnect') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'identifier', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username'},
                { key: 'secret', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password'}
            ],
            modalInfo: {
                title: 'Corporate Banking Authorization'
            },
            banks: [
                {
                    code: 'bca_ac',
                    name: 'BCA',
                    icon: 'images/BCA.png',
                    isSelected: false,
                    data: { bank_code: 'BCA_CORPORATE' },
                    infoMessage: 'To connect, please enter your Username and Password'
                },
                {
                    code: 'bni_ac',
                    name: 'BNI',
                    icon: 'images/BNI.webp',
                    isSelected: false,
                    data: { bank_code: 'BNI_CORPORATE' },
                    infoMessage: 'To connect, please enter your Username and Password'
                },
                {
                    code: 'mandiri_ac',
                    name: 'Mandiri',
                    icon: 'images/Mandiri.webp',
                    isSelected: false,
                    data: { bank_code: 'MANDIRI_CORPORATE' },
                    infoMessage: 'To connect, please enter your Username and Password'
                }
            ],
            data: { 
                bank_code: null 
            }
        },
        {
            code: 'shopeeVerification',
            name: 'Shopee Verification',
            icon: 'images/shopee.png',
            category: 'E-Commerce Verification',
            // category: 'Shopee Verification',
            // isShowing: partners.find(partner => partner === 'shopeeVerification') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username'},
                { key: 'accountNo', value: '', label: 'Account Number', placeHolder: 'Enter Account Number', infoMessage: 'Please enter your Account Number'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password'}
            ],
            modalInfo: {
                title: 'Shopee Verification'
            },
            infoMessage: 'To connect, please enter your Username, Password and your bank account number linked to your shopee account'
        },
        {
            code: 'tokopediaVerification',
            name: 'Tokopedia Verification',
            icon: 'images/tokopedia.png',
            category: 'E-Commerce Verification',
            // category: 'Tokopedia Verification',
            // isShowing: partners.find(partner => partner === 'tokopediaVerification') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'shop_url', value: '', label: 'Enter your Shop URL', placeHolder: 'Enter Shop URL', infoMessage: 'Please enter your Shop URL'},
                { key: 'bank_acc', value: '', label: 'Enter your Bank account', placeHolder: 'Enter bank account', infoMessage: 'Please enter your bank account'}
            ],
            modalInfo: {
                title: 'Tokopedia Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Shop URL, for e.g "https://www.tokopedia.com/<shop_name>" and Bank account'
        },
        {
            code: 'jurnal',
            name: 'Jurnal',
            icon: 'images/jurnal_icon.png',
            category: 'Accounting',
            // isShowing: partners.find(partner => partner === 'jurnal') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'user_email', value: '', type: "email", label: 'Enter your Email id:', placeHolder: 'Enter email id', infoMessage: 'Please enter your Email address'}
            ],
            modalInfo: {
                title: 'Jurnal Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username and Password'
        },
        {
            code: 'mokapos',
            name: 'Mokapos',
            icon: 'images/mokapos_icon.webp',
            category: 'Payment',
            // isShowing: partners.find(partner => partner === 'mokapos') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'business_name', value: '', label: 'Business name', placeHolder: 'Enter your business name', infoMessage: 'Please enter your business name'}
            ],
            modalInfo: {
                title: 'Mokapos Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Business name'
        },
        {
            code: 'plncheck',
            name: 'PlnCheck',
            icon: 'images/plncheck_icon.jpeg',
            category: 'Utility Bills',
            // isShowing: partners.find(partner => partner === 'plncheck') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'customername', value: '', label: 'Customer name', placeHolder: 'Enter your customer name', infoMessage: 'Please enter your customer name'},
                { key: 'phonenumber', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', infoMessage: 'Please enter your phone number'},
                { key: 'accountnumber', value: '', label: 'Account number', placeHolder: 'Enter your account number', infoMessage: 'Please enter your account number'}
            ],
            modalInfo: {
                title: 'PlnCheck Authorization'
            },
            infoMessage: 'To connect, please enter your utility Account number, Customer name and Phone Number'
        },
        {
            code: 'ovo',
            name: 'Ovo',
            icon: 'images/ovo_icon.jpeg',
            category: 'Payment',
            // isShowing: partners.find(partner => partner === 'ovo') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'username', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', isPhoneNo: true, infoMessage: 'Please enter your phone number'},
                { key: 'password', value: '', type: 'password', label: 'Pin', placeHolder: 'Enter your Pin', infoMessage: 'Please enter your Pin'},
            ],
            paramsOTP: [
                { key: 'otp', value: '', type: 'number', label: 'OTP', placeHolder: 'Enter your otp', infoMessage: 'Please enter your otp'},
            ],
            modalInfo: {
                title: 'Ovo Authorization'
            },
            isOTP: true,
            errorMessage: {
                head: undefined,
                body: 'Authentication failed!'
            },
            paramsHandle(params){
                params['username'] = `+62${params['username']}`;
            },
            infoMessage: 'To connect, please enter your username and OTP Pin'
        },
        {
            code: 'gopay',
            name: 'Gopay',
            icon: 'images/gopay_icon.jpeg',
            category: 'Payment',
            // isShowing: partners.find(partner => partner === 'gopay') !== undefined,
            isShowing: true,
            isSelected: false,
            params: [
                { key: 'username', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', isPhoneNo: true, infoMessage: 'Please enter your phone number'},
                { key: 'password', value: '', type: 'password', label: 'Pin', placeHolder: 'Enter your Pin', infoMessage: 'Please enter your Pin'},
            ],
            paramsOTP: [
                { key: 'otp', value: '', type: 'number', label: 'OTP', placeHolder: 'Enter your otp', infoMessage: 'Please enter your otp'},
            ],
            modalInfo: {
                title: 'Gopay Authorization'
            },
            isOTP: true,
            errorMessage: {
                head: undefined,
                body: 'Authentication failed!'
            },
            paramsHandle(params){
                params['username'] = `+62${params['username']}`;
            },
            infoMessage: 'To connect, please enter your username and OTP Pin'
        },
    ];

    const [eCommerceList, setECommerceList] = useState(initialECommerceList);

    const categories = [ 'E-Commerce', 'Accounting', 'Payment', 'Social', 'Utility Bills', 'Retail/Personal Banking', 'Corporate Banking', 'Bank Verification', 'E-Commerce Verification'];
    
    const categoriesData = {
        'E-Commerce': { 
            infoMessage: 'Connect any of the relevant e-commerce platforms that you have your account in. You can connect more than 1.',
            data: ['Transaction Data', 'Profile Data', 'Metric Data']
        },
        'Accounting': {
            infoMessage: 'Connect any of the relevant accounting platforms that you have your account in. You can connect more than 1',
            data: ['Transaction Data', 'Company Profile', 'Account Data']
        },
        'Payment': {
            infoMessage: 'Connect any of the relevant Payment platforms that you have your account in. You can connect more than 1',
            data: ['Transaction Data', 'Profile Data', 'Account Data']
        },
        'Social': {
            infoMessage: 'Connect any of the relevant social platforms that you have your account in. You can connect more than 1',
            data: ['Profile Data', 'Metric Data', 'Engagement Data']
        },
        'Utility Bills': {
            infoMessage: 'Connect any of the relevant Utility Bills platforms that you have your account in. You can connect more than 1',
            data: ['Transaction', 'Account Data']
        },
        'Retail/Personal Banking': {
            infoMessage: 'Connect any of the relevant Banking platforms that you have your account in. You can connect more than 1',
            data: ['Transaction Data', 'Profile Data', 'Account Data']
        },
        'Corporate Banking': {
            infoMessage: 'Connect any of the relevant Banking platforms that you have your account in. You can connect more than 1',
            data: ['Transaction Data', 'Profile Data', 'Account Data']
        },
        'Bank Verification': {
            infoMessage: 'Connect any of the relevant Banking platforms that you have your account in. You can connect more than 1',
            data: ['Account Data', 'Profile Data']
        },
        'E-Commerce Verification': {
            infoMessage: 'Connect any of the relevant e-commerce platforms that you have your account in. You can connect more than 1.',
            data: ['Account Data', 'Profile Data']
        },
    };
    
    const [category, setCategory] = useState({});
    
    const [selectedPartner, setSelectedPartner] = useState(null);

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const[confirmData, setConfirmData] = useState(null);

    const [showPartnerPage, setShowPartnerPage] = useState(false);

    const [showMessage, setShowMessage] = useState(null);

    const [isLoading, setLoader] = useState(false);
    
    const [isError, setIsError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);

    const ovoObj = {
        index: eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.code == 'ovo')),
        eCommerce: eCommerceList.find(eCommerce => eCommerce.code == 'ovo'),
    };
    const [showOTPModal, setShowOTPModal] = useState(false);

    const [pwc, setPwc] = useState(null);

    const [message, setMessage] = useState(null);

    const [title, setTitle] = useState(null);

    const [isSuccess, setIsSuccess] = useState(false);

    const [count, setCount] = useState(0);

    // const [isTranslated, setIsTranslated] = useState(false);

    const [key, setKey] = useState(categories[0]);

    // const [openedDropdown, setOpenedDropdown] = useState(null);

    // let openedDropdownvar = false;

    const closeResponse = () => {
        setIsError(false);
        setErrorMessage(false);
    }

    const handleDropdown = (key, bool) => {
        const categoryNew = {...category};
        categoryNew[key] = {...categoryNew[key]};
        Object.keys(categoryNew).forEach(key => {
            categoryNew[key].isShowing = false;
        });
        categoryNew[key].isShowing = bool;
        setCategory(categoryNew);
        // bool && setOpenedDropdown(key);
    };

    const selectPartner = (index, bankIndex) => {
        setSelectedPartner({
            index, bankIndex
        });
        goNext();
    };

    const closeDropdown = () => {
        // if(openedDropdown){
        //     console.log('opened ', openedDropdown);
        //     setOpenedDropdown(null);

            
        //     setCategory(category => {
        //         const categoryNew = {...category};
        //         categoryNew[openedDropdown] = {...categoryNew[openedDropdown]};
        //         categoryNew[openedDropdown].isShowing = false;
        //         return categoryNew;
        //     });
        // }
    };

    const removePartner = (index, bankIndex) => {
        setConfirmData({
            index, bankIndex
        });
        setShowConfirmModal(true);
    }

    const unSelectPartner = (index, bankIndex) => {
        const newECommerceList = [...eCommerceList];
        newECommerceList[index] = {...newECommerceList[index]};
        newECommerceList[index].isSelected = false;
        if(newECommerceList[index].banks){
            // newECommerceList[index].banks = {...newECommerceList[index].banks};
            newECommerceList[index].banks[bankIndex].isSelected = false;
        }
        setECommerceList(newECommerceList);
        setShowConfirmModal(false);
        setConfirmData(null);
    };

    const goNext = () => {
        // if(!selectedPartner){
        //     setShowMessage('Please Select partner');
        //     return;
        // }
        setShowPartnerPage(true);
    }

    const processSuccess = (index, bankIndex, bankCode, result) => {
        const newECommerceList = [...eCommerceList];
        newECommerceList[index] = {...newECommerceList[index]};
        newECommerceList[index].isSelected = true;
        newECommerceList[index].isInitiated = false;
        if(newECommerceList[index].banks){
            newECommerceList[index].banks = newECommerceList[index].banks.map(bank => {
                bank.isSelected = bank.code === bankCode;
                return {...bank};
            });
        }
        setECommerceList(newECommerceList);
        
        setIsSuccess(true);
        setShowPartnerPage(false);
        setSelectedPartner(null);
    }

    const updateParams = async (index, params, bankIndex) => {
        let bankCode;
        if(eCommerceList[index].banks){
            bankCode = eCommerceList[index].banks[bankIndex].code;
            eCommerceList[index].data = eCommerceList[index].banks[bankIndex].data;
        }
        
        if(eCommerceList[index].isInitiated){
            setLoader(true);
            getResponse(eCommerceList[index].code, index, bankCode);
            return;
        }

        params = params.reduce((prev, curr) => {
            prev[curr.key] = curr.value;
            return prev;
        }, {});
        eCommerceList[index].paramsHandle && eCommerceList[index].paramsHandle(params);

        setLoader(true);
        const result = await pwc.setParameters(eCommerceList[index].code, params, eCommerceList[index].data, bankCode);
        setLoader(false);

        if(result.isSuccess){
            if(eCommerceList[index].isOTP){
                setShowOTPModal({index, eCommerce: eCommerceList[index]});
            }
            else{
                // setLoader(true);
                // const result = await pwc.getProfileData(eCommerceList[index].code, true);
                // setLoader(false);
                // processSuccess(index, bankIndex, bankCode, result);
                // setTimeout(() => {
                //     if(unprocessedCountVar.length){
                //         setLoader(false);
                //         setIsError(true);
                //             setIsTimeOut(true);
                //             isTimeOutVar = true;
                //         eCommerceList[index].errorMessage && setErrorMessage(eCommerceList[index].errorMessage);
                //     }
                // }, 200000);
                if(eCommerceList[index].isOAuth){
                    setShowMessage('Please Authorize and then click on the NEXT button');
                    
                    const newECommerceList = [...eCommerceList];
                    newECommerceList[index] = {...newECommerceList[index]};
                    newECommerceList[index].isInitiated = true;
                    setECommerceList(newECommerceList);
                }
                else if(result.showMessage){
                    let message = 'A verification email has been sent to your email address, Please authorize it and then click on NEXT button';
                    const newECommerceList = [...eCommerceList];
                    newECommerceList[index] = {...newECommerceList[index]};
                    newECommerceList[index].isInitiated = true;
                    setECommerceList(newECommerceList);

                    setShowMessage(result.showMessage);
                    setTitle('');
                }
                else{
                    setLoader(true);
                    getResponse(eCommerceList[index].code, index, bankCode);
                }
                // setTimeout(() => {
                //     getResponse(eCommerceList[index].code, index, bankCode);
                // }, 10000);
            }
        }
        else{
            setIsError(true);
            eCommerceList[index].errorMessage && setErrorMessage(eCommerceList[index].errorMessage);
        }
    }

    const getResponse = async (code, index, bankCode, count = 0) => {
        // let lcount = count;
        console.log(code, ' called', ++count);

        // setLoader(true);
        const response = await pwc.getProfileData(code, true);
        // setLoader(false);
        
        if(!response.isError && (response.data.status_code == undefined || response.data.status_code != 202)){
            // const widgetResponseNew = {...widgetResponseVar};
            // widgetResponseNew.gateway_res[code] = response.data;
            // setWidgetResponse(widgetResponseNew);
            // widgetResponseVar = widgetResponseNew;
            // const index = unprocessedCountVar.indexOf(unprocessedCountVar.find(partner => partner == code));
            // unprocessedCountVar.splice(index, 1);
            // setUnprocessedCount(unprocessedCountVar);
            // if(!unprocessedCountVar.length){
            //     success(widgetResponseNew);
            // }
            
            // setCount(0);
            setLoader(false);
            console.log(code, ' success', response); //, widgetResponseNew, unprocessedCountVar);
            processSuccess(index, undefined, bankCode, response);
        }
        else if(response.isError || count > 10){
            
            // setCount(0);
            setLoader(false);
            console.log(code, ' error');
            setIsError(true);
            eCommerceList[index].errorMessage && setErrorMessage(eCommerceList[index].errorMessage);

            const newECommerceList = [...eCommerceList];
            newECommerceList[index] = {...newECommerceList[index]};
            newECommerceList[index].isInitiated = false;
            setECommerceList(newECommerceList);
        }
        else if(true){
            setTimeout(() => getResponse(code, index, bankCode, count), 10000);
            // setCount(lcount);
        }
    };

    const updateParamsOTP = async (index, params) => {
        params = params.reduce((prev, curr) => {
            prev[curr.key] = curr.value;
            return prev;
        }, {});

        setLoader(true);
        const result = await pwc.setOtp(eCommerceList[index].code, params);
        setLoader(false);
        
        if(result.isSuccess){
            setLoader(true);
            getResponse(eCommerceList[index].code, index);
            // processSuccess(index, undefined, undefined, result);
        }
        else{
            setLoader(false);
            setIsError(true);
            setErrorMessage({
                head: '',
                body: 'OTP Authentication failed!'
            });
        }
    }
    
    const createPWCObject = () => {
        const apiKey = 'iiV0pirSZpnKudtQX4MCrNgAHbUTAnOwOSQM4BOfVrfwUqyp';
        const pwc = new PWC({ 
            apiKey,
            callback: (companyID) => {
                window.gtag('config', 'G-0WQWYRSVZK', {
                    'user_id': companyID
                });
            }
         });
        setPwc(pwc);
    };

    const searchPartner = e => {
        console.log(e.target.value);
        // return;
        const filter = e.target.value.toUpperCase();

        const ul = document.getElementsByClassName("search-partners-container")[0];
        const li = ul.getElementsByClassName("search-partner");
        for(let i = 0; i < li.length; i++) {
            const a = li[i].getElementsByTagName("span")[0];
            const txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    const showPartners = e => {
        // console.log('asd');
        document.getElementsByClassName("search-partners-container")[0].style.display = 'block';
        // document.getElementsByClassName('search-partners-background')[0].style.display = 'block';
        const categoryNew = {...category};
        Object.keys(categoryNew).forEach(key => {
            categoryNew[key].isShowing = false;
        });
        setCategory(categoryNew);

        e.stopPropagation();
    };

    const hidePartners = e => {
        const div = document.getElementsByClassName("search-partners-container");
        if(div.length){
            div[0].style.display = 'none';
        }
        // document.getElementsByClassName('search-partners-background')[0].style.display = 'none';
    }

    const showPartner = eCommerce => {
        const div = document.getElementById(eCommerce.category);
        const button = div.getElementsByTagName('button')[0];
        if(button.classList.contains('collapsed')){
            button.click();
        }

        if(!eCommerce.isSelected){
            const newCategory = {...category};
            newCategory[eCommerce.category] = {...newCategory[eCommerce.category]};
            newCategory[eCommerce.category].isShowing = true;
            setCategory(newCategory);
            // document.getElementById(eCommerce.category+'-title').scrollIntoView();
        }
        
        if(document.body.clientWidth <= 576){
            
            let element;
            if(eCommerce.isSelected){
                setKey('connected');
                element = document.getElementById('connected-title');
            }
            else{
                setKey(eCommerce.category);
                element = document.getElementById(eCommerce.category+'-title');
            }
            element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
            
        }
        else{
            document.getElementById(eCommerce.category).scrollIntoView();
        }
        

        // handleDropdown(eCommerce.category, true); 
        document.getElementsByClassName("search-partners-container")[0].style.display = 'none';
    }

    // const translate = (lang) => {
    //     let ind = 1;
    //     if(lang == 'en'){
    //         if(!isTranslated){  //document.getElementsByClassName('goog-te-combo')[0].options[0].value == ''){
    //             return;
    //         }
    //         ind = 0;
    //     }
    //     else if(isTranslated){
    //         return;
    //     }
    //     setIsTranslated(ind ? true : false);
       
    //     document.getElementsByClassName('goog-te-combo')[0].options[ind].selected = true;
    //     var evt = document.createEvent("HTMLEvents");
    //     evt.initEvent('change', true, true ); // event type,bubbling,cancelable
    //     return !document.getElementsByClassName('goog-te-combo')[0].options[ind].dispatchEvent(evt);
        
    // };

    useEffect(() => {
        createPWCObject();

        const category = eCommerceList.filter(eCommerce => eCommerce.isShowing).reduce((prev, eCommerce) => {
            if(eCommerce.category in prev){
                prev[eCommerce.category].partners.push(eCommerceList.indexOf(eCommerce));
            }
            else{
                prev[eCommerce.category] = {
                    isShowing: false,
                    partners: [eCommerceList.indexOf(eCommerce)]
                };
            }
            return prev;
        }, {});
        setCategory(category);

        document.getElementsByTagName('body')[0].addEventListener('click', e => {
            // console.log('qwe');
            hidePartners(e);
        });
    }, []);

    // if(showPartnerPage){
    //     return <PartnerPage eCommerceList={eCommerceList} index={selectedPartner.index} bankIndex={selectedPartner.bankIndex} updateParams={updateParams} back={() => setShowPartnerPage(false)}/>;
    // }
    // if(isSuccess){
    //     return <Success back={() => setIsSuccess(false)}/>
    // }
    return ( 
        <div onClick={closeDropdown}>
            <div>
                <nav className="home-click" onClick={() => setShowPartnerPage(null)}>
                    {/* <div className="container"> */}
                        <img src={require('../components/images/images/powercred_logo_2.png')} alt="powercred_logo" height="60" className='pwc-logo' />
                        {/* <div className="navbar-brand" id="google_translate_element_i"></div> */}
                    {/* </div> */}
                </nav>
                {/* <div className='menu-icon'>
                    <div className='menu-icon-row'></div>
                    <div className='menu-icon-row'></div>
                    <div className='menu-icon-row'></div>
                </div> */}
                <div className="google-translate">
                    <div className={`notranslate google-translate-btn google-translate-en ${!isTranslated ? 'google-translate-btn-selected' : ''}`} onClick={() => translate('en')}> EN </div>
                    <div className={`notranslate google-translate-btn ${isTranslated ? 'google-translate-btn-selected' : ''}`}  onClick={() => translate('id')}> ID </div>
                </div>
            </div>
            {
                !showPartnerPage && (
                    <div className="Partner-class-nui">
                        <h3 className="Partner-heading1-nui-p2 Partner-heading1-nui-p3">Choose your account</h3>

                        <div className="search-container-div search-container-div-p3" >
                            <div className="search-container search-container-p3">
                                <form action="/action_page.php">
                                    <input type="text" placeholder="Choose your Partners" name="search" onKeyUp={searchPartner} onClick={showPartners} className='searchbar-input searchbar-input-p3'/>
                                </form>
                            
                                <div className='search-partners-container' style={{zIndex: '100'}}>
                                    {
                                        eCommerceList.map(eCommerce => {
                                            if(eCommerce.banks){
                                                return eCommerce.banks.map(bank => {
                                                    return (
                                                        <div className='search-partner' onClick={() => showPartner(eCommerce)} key={bank.code}>  
                                                            <div>
                                                                <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image'/>
                                                                <span>
                                                                    {bank.name}
                                                                    <span className='search-partner-category'>{' ('+eCommerce.name+')'}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            return (
                                                <div className='search-partner' onClick={() => showPartner(eCommerce)} key={eCommerce.code}>  
                                                    <div>
                                                        <img src={require(`../components/images/${eCommerce.icon}`)} alt={`${eCommerce.code}-logo`} className='dropdown-options-partner-image'/>
                                                        <span>
                                                            {eCommerce.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        

                        <div className="accordion" id="accordionExample">
                            {
                                categories.filter(key => key in category).map((key, index) => {
                                    return (
                                        <div className="accordion-item" key={key} id={key}>
                                            <h2 className="accordion-header" id={`heading-${index}`}>
                                                <button className="accordion-button collapsed" style={index%2 == 0 && false ? {backgroundColor: '#ebebeb'} : {}} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded="false" aria-controls={`collapse-${index}`}>
                                                    {key}
                                                </button>
                                            </h2>
                                            <div id={`collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-${index}`} data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div>
                                                        <span className="accordion-body-heading-p3">Select {key} account</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                        </svg>
                                                        <div className='partner-info-icon-message'>
                                                            {categoriesData[key].infoMessage}
                                                        </div>
                                                    </div>
                                                    {
                                                        eCommerceList.filter(eCommerce => eCommerce.category == key && eCommerce.isShowing && eCommerce.isSelected).map((eCommerce, index) => {
                                                            if(eCommerce.banks) {
                                                                return eCommerce.banks.filter(bank => bank.isSelected).map((bank, index) => {
                                                                    return (
                                                                        <div className='div-partner-p3' key={bank.code}>
                                                                            <span className='category-p3-selected-no'>Account {index+1} :</span>
                                                                            <div className='category-p3-selected'id={'key'}> 
                                                                                <div className='dropdown dropdown-p3'>
                                                                                    <div className='dropdown-div-p3 selected-partner-p3'>  
                                                                                        {
                                                                                            (
                                                                                                <>
                                                                                                    <div>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="green" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                                                                        <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image'/>
                                                                                                        <span>
                                                                                                            {bank.name}
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                                                            </svg>
                                                                                                            <div className='partner-info-icon-message'>
                                                                                                                {bank.infoMessage}
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='partner-remove-p3' onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerceList.find(eCommerceI => eCommerceI.code == eCommerce.code)), eCommerce.banks.indexOf(eCommerce.banks.find(bankI => bankI.code == bank.code)));}}>
                                                                                <span >Remove</span>
                                                                                <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} width="24" height="24" className='remove-icon'/>
                                                                                {/* <svg width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg> */}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                });
                                                            }
                                                            return (
                                                                <div className='div-partner-p3' key={eCommerce.code}>
                                                                    <span className='category-p3-selected-no'>Account {index+1} :</span>
                                                                    <div className='category-p3-selected'id={'key'}> 
                                                                        <div className='dropdown dropdown-p3'>
                                                                            <div className='dropdown-div-p3 selected-partner-p3'>  
                                                                                {
                                                                                    (
                                                                                        <>
                                                                                            <div>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="green" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                                                                <img src={require(`../components/images/${eCommerce.icon}`)} alt={`${eCommerce.code}-logo`} className='dropdown-options-partner-image'/>
                                                                                                <span>
                                                                                                    {eCommerce.name}
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                                                    </svg>
                                                                                                    <div className='partner-info-icon-message'>
                                                                                                        {eCommerce.infoMessage}
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='partner-remove-p3' onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerceList.find(eCommerceI => eCommerceI.code == eCommerce.code)));}}>
                                                                        <span >Remove</span>
                                                                        <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} width="24" height="24" className='remove-icon'/>
                                                                        {/* <svg width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg> */}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className='div-partner-p3'>
                                                        <span className='category-p3-selected-no'>Account {eCommerceList.filter(eCommerce => eCommerce.category == key && eCommerce.isShowing && eCommerce.isSelected).length+1} :</span>
                                                        <div className='category-p3 category-p3-selected'id={'key'}> 
                                                            <div className='dropdown-background' style={category[key].isShowing ? {display: 'block', zIndex: '10'} : {display: 'none'}} onClick={() => { handleDropdown(key,false)}}></div>
                                                            <div className='dropdown dropdown-p3' style={category[key].isShowing ? {zIndex: '11'} : {}}>
                                                                <div className='dropdown-div-p3 selected-partner-p3' onClick={() => {handleDropdown(key, !category[key].isShowing)} }>  
                                                                    {
                                                                        (
                                                                            <>
                                                                                <span>Select a partner</span>
                                                                                <i className="arrow down"></i>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className='dropdown-options-p3' style={category[key].isShowing ? {display: 'inline-block'} : {display: 'none'}}>
                                                                    {
                                                                        eCommerceList.filter(eCommerce => eCommerce.isShowing && eCommerce.category == key && (eCommerce.banks || !eCommerce.isSelected)).map(eCommerce => {
                                                                            if(eCommerce.banks){
                                                                                return eCommerce.banks.filter(bank => !bank.isSelected).map((bank, index) => {
                                                                                    return (
                                                                                        <div className='dropdown-options-partner-p3' key={bank.code} id={bank.code} onClick={() => {handleDropdown(key, false); selectPartner(eCommerceList.indexOf(eCommerceList.find(eCommerceI => eCommerceI.code == eCommerce.code)), eCommerce.banks.indexOf(eCommerce.banks.find(bankI => bankI.code == bank.code)));}}>  
                                                                                            <div>
                                                                                                <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image'/>
                                                                                                <span>
                                                                                                    {bank.name}
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                                                    </svg>
                                                                                                    <div className='partner-info-icon-message'>
                                                                                                        {bank.infoMessage}
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            }
                                                                            return (
                                                                                <div className='dropdown-options-partner-p3' key={eCommerce.code} id={eCommerce.code} onClick={() => {handleDropdown(key, false); selectPartner(eCommerceList.indexOf(eCommerceList.find(eCommerceI => eCommerceI.code == eCommerce.code)));}}>  
                                                                                    <div>
                                                                                        <img src={require(`../components/images/${eCommerce.icon}`)} alt={`${eCommerce.code}-logo`} className='dropdown-options-partner-image'/>
                                                                                        <span>
                                                                                            {eCommerce.name}
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                                            </svg>
                                                                                            <div className='partner-info-icon-message'>
                                                                                                {eCommerce.infoMessage}
                                                                                            </div>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                                        Add More 
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        <div className='categories-container-p2-mobile'>
                            <div className='categories-mobile categories-mobile-p3'>
                                <div key='connected' id='connected-title' className={'connected' == key ? 'categories-mobile-category categories-mobile-category-selected' : 'categories-mobile-category'} onClick={() => {setKey('connected'); document.getElementById('connected-title').scrollIntoView();}}> Connected </div>
                                {
                                    categories.filter(key => key in category).map(keyI => {
                                        return (
                                            <div key={keyI} id={keyI+'-title'} className={keyI == key ? 'categories-mobile-category categories-mobile-category-selected' : 'categories-mobile-category'} onClick={() => {
                                                setKey(keyI);
                                                const element = document.getElementById(keyI+'-title');
                                                element.scrollIntoView({
                                                    behavior: 'auto',
                                                    block: 'center',
                                                    inline: 'center'
                                                }); 
                                            }}> {keyI} </div>
                                        );
                                    })
                                }
                                {
                                   
                                }
                            </div> 
                            <div className={'scrollbar-mobile-div'}>
                                        <div key={'connected'} className={'connected' == key ? 'scrollbar-mobile scrollbar-mobile-selected' : 'scrollbar-mobile'} onClick={() => document.getElementsByClassName('categories-mobile-category')[0].click()}> </div>
                                        {
                                            categories.filter(key => key in category).map((keyI, index) => {
                                                return (
                                                    <div key={keyI} className={keyI == key ? 'scrollbar-mobile scrollbar-mobile-selected' : 'scrollbar-mobile'} onClick={() => document.getElementsByClassName('categories-mobile-category')[index + 1].click()}> </div>
                                                );
                                            })
                                        }
                            </div>
                            <div className='option-div-mobile'>
                                <div className='dropdown-options-p2-mobile' style={key == 'connected' && eCommerceList.filter(eCommerce => eCommerce.isSelected).length == 0 ? {border: 'none', backgroundColor: '#fff0'} : {}}>
                                    {
                                        key in category && category[key].partners.filter(index => eCommerceList[index].banks || !eCommerceList[index].isSelected).map(index => {
                                            if(eCommerceList[index].banks){
                                                return (
                                                    eCommerceList[index].banks.filter(bank => !bank.isSelected).map((bank, bankIndex) => {
                                                        return (
                                                            <div className='dropdown-options-partner' onClick={() => {if(eCommerceList[index].banks[bankIndex].isSelected) return; handleDropdown(key, false); selectPartner(index, bankIndex);}} key={bank.code}>  
                                                                <div>
                                                                    <svg style={!bank.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!bank.isSelected ? "#979595" : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                                    <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image'/>
                                                                    <span>
                                                                        {bank.name}
                                                                        <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                        </svg>
                                                                        <div className='partner-info-icon-message'>
                                                                            {bank.infoMessage}
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                {
                                                                    !bank.isSelected 
                                                                    ? <i className="arrow arrow-option right"></i> 
                                                                    : <svg onClick={(event) => {event.stopPropagation(); removePartner(index, bankIndex);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                );
                                            }
                                            return (
                                                <div className='dropdown-options-partner dropdown-options-partner-p2-m' onClick={() => {if(eCommerceList[index].isSelected) return; handleDropdown(key, false); selectPartner(index);}} key={eCommerceList[index].code}>  
                                                    <div>
                                                        <svg style={!eCommerceList[index].isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!eCommerceList[index].isSelected ? '#979595' : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                        <img src={require(`../components/images/${eCommerceList[index].icon}`)} alt={`${eCommerceList[index].code}-logo`} className='dropdown-options-partner-image'/>
                                                        <span>
                                                            {eCommerceList[index].name}
                                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                            </svg>
                                                            <div className='partner-info-icon-message'>
                                                                {eCommerceList[index].infoMessage}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {
                                                        !eCommerceList[index].isSelected 
                                                        ? <i className="arrow arrow-option right"></i> 
                                                        : <svg onClick={(event) => {event.stopPropagation(); removePartner(index);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                     {
                                        key == 'connected' && eCommerceList.filter(eCommerce => eCommerce.isSelected).map(eCommerce => {
                                            if(eCommerce.banks){
                                                return (
                                                    eCommerce.banks.filter(bank => bank.isSelected).map((bank, bankIndex) => {
                                                        return (
                                                            <div className='dropdown-options-partner' onClick={() => {if(eCommerce.banks[bankIndex].isSelected) return; handleDropdown(key, false); selectPartner('index', bankIndex);}} key={bank.code}>  
                                                                <div>
                                                                    <svg style={!bank.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!bank.isSelected ? "#979595" : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                                    <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image'/>
                                                                    <span>
                                                                        {bank.name}
                                                                        <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                        </svg>
                                                                        <div className='partner-info-icon-message'>
                                                                            {bank.infoMessage}
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                {
                                                                    !bank.isSelected 
                                                                    ? <i className="arrow arrow-option right"></i> 
                                                                    : <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce), bankIndex);}} width="24" height="24" className='remove-icon'/>
                                                                    // : <svg onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce), bankIndex);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                );
                                            }
                                            return (
                                                <div className='dropdown-options-partner dropdown-options-partner-p2-m' onClick={() => {if(eCommerce.isSelected) return; handleDropdown(key, false); selectPartner('index');}} key={eCommerce.code}>  
                                                    <div>
                                                        <svg style={!eCommerce.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!eCommerce.isSelected ? '#979595' : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                        <img src={require(`../components/images/${eCommerce.icon}`)} alt={`${eCommerce.code}-logo`} className='dropdown-options-partner-image'/>
                                                        <span>
                                                            {eCommerce.name}
                                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                            </svg>
                                                            <div className='partner-info-icon-message'>
                                                                {eCommerce.infoMessage}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {
                                                        !eCommerce.isSelected 
                                                        ? <i className="arrow arrow-option right"></i> 
                                                        : <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce));}} width="24" height="24" className='remove-icon'/>
                                                        // : <svg onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce));}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        key == 'connected' && eCommerceList.filter(eCommerce => eCommerce.isSelected).length == 0 && (
                                            <div className='option-div-no-selected'>No partners connected</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {showPartnerPage && <PartnerPage eCommerceList={eCommerceList} index={selectedPartner.index} categoriesData={categoriesData} bankIndex={selectedPartner.bankIndex} updateParams={updateParams} back={() => setShowPartnerPage(false)}/>}

            {showConfirmModal && <Confirmation partner={confirmData.bankIndex ? eCommerceList[confirmData.index].banks[confirmData.bankIndex].name : eCommerceList[confirmData.index].name} confirmed={() => unSelectPartner(confirmData.index, confirmData.bankIndex)} closeMessage={() => setShowConfirmModal(false)}/>}
            {showMessage && <Message message={showMessage} title={title} closeMessage={() => setShowMessage(null)}/>}
            
            {isLoading && <FullPageLoader />}
            {isError && <ErrorModal closeResponse={closeResponse} errorMessage={errorMessage}/>}
            {showOTPModal && <OTPModal index={showOTPModal.index} eCommerce={showOTPModal.eCommerce} updateParamsOTP={updateParamsOTP} close={() => setShowOTPModal(false)}/>}
            {isSuccess && <SuccessModal closeResponse={() => setIsSuccess(false)}/>}
        </div>
    );
}
 
export default Partner3;