import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import ErrorModal from '../../newUi/ErrorModal';
import SuccessModal from '../../newUi/SuccessModal';
import LocalstorageService from '../../services/localstorage.service';
import { getOTP, verifyOTP, verifyPIN } from '../../services/gopay.service';
import { verifySession } from '../../services/verify.service';
import BrandBadge from '../../Widget/BrandBadge/brandBadge.widget';
import { Toast } from 'primereact/toast';
import './gopay.component.css';

const Gopay = ({ back, onFormSuccess, isTranslated }) => {
    const toast = useRef(null);
    const { t } = useTranslation();
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [MobileNumber, setMobileNumber] = useState('');
    const [OTP, setOTP] = useState('');
    const [PIN, setPIN] = useState('');
    const timerIntervalRef = useRef(null);

    const [isRequireOTP, setRequireOTP] = useState(false);
    const [isRequirePIN, setRequirePIN] = useState(false);

    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [isFinish, setIsFinish] = useState(false);

    const [enableResendOTP, setEnableResendOTP] = useState(false);

    const [minHand, setMinHand] = useState('02');
    const [secHand, setSecHand] = useState('00');
    const [showTimer, setShowTimer] = useState(true);


    /*
        1. User will enter Phone Number and hit submit
            - Will call the function RequestOTP
            - If success, will trigger showSuccess for success message for 'Enter OTP'
            - The isRequireOTP will be set true
            - The timer will start
            - enableResendOTP will be set false
            - If error, will setIsError true

        2. User will enter OTP and hit submit
            - Will call the function VerifyOTP

            - If response is not equal to 'Awaiting TFA'
            - setIsSuccess will be set true
            - true will be passed to onFormSuccess

            - If error, will setIsError true

            - If response is equal to 'Awaiting TFA', will trigger showSuccess for 'Enter PIN'
            - The isRequirePIN will be set true
            - The timer will be stopped and the timer section will be hidden

        3. User will enter PIN and hit submit
            - Will call the function VerifyPIN
            - If success, setIsSuccess will be set true
            - true will be passed to onFormSuccess
            - If error, will setIsError true

    */


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 577) {
                setIsMobileScreen(true);
            } else {
                setIsMobileScreen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handlePhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setMobileNumber(e.target.value);
        }
    };

    const handleOTPChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setOTP(e.target.value);
        }
    }

    const handlePINChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPIN(e.target.value);
        }
    };

    const startTimer = () => {
        let timer = 120;
        let minutes;
        let seconds;
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = setInterval(() => {

            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
            minutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
            seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

            setMinHand(minutes);
            setSecHand(seconds);

            if (--timer < 0) {
                clearInterval(timerIntervalRef.current);
                setEnableResendOTP(true);
                setMinHand('02');
                setSecHand('00');
            }
        }, 1000);
    }

    const resetTimer = () => {
        setMinHand('02');
        setSecHand('00');
        clearInterval(timerIntervalRef.current);
        setEnableResendOTP(false);
    }

    const closeResponse = () => {
        resetTimer();
        setIsError(false);
    }

    const showSuccess = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
    }
    const showError = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 6000 });
    }
    const showWarn = (message) => {
        toast.current.show({ severity: 'warn', summary: message, life: 3000 });
    }

    const RequestOTP = async () => {
        const verification = await verifySession();
        if (verification) {
            if (verification.status == "failed") {
                window.location.reload();
                return
            }
        }
        setIsDataLoading(true);
        const localStorageService = new LocalstorageService();
        try {
            const response = await getOTP(localStorageService.getItem('id', false, 'tokenVerification'), MobileNumber);
            if (response) {
                setRequireOTP(true);
                startTimer();
                showSuccess(t('form.otp'));
                setIsDataLoading(false);
            } else {
                setIsError(true);
                setIsDataLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsError(true);
            setIsDataLoading(false);
        }
    }

    const VerifyOTP = async () => {
        const verification = await verifySession();
        if (verification) {
            if (verification.status == "failed") {
                window.location.reload();
                return
            }
        }
        setIsDataLoading(true);
        const localStorageService = new LocalstorageService();
        try{
            const response = await verifyOTP(localStorageService.getItem('id', false, 'tokenVerification'), OTP);
            if (response) {
                if (response == 'Awaiting TFA') {
                    setRequirePIN(true);
                    showSuccess(t('form.pin'));
                    resetTimer();
                    setIsDataLoading(false);
                } else {
                    setIsSuccess(true);
                    setIsFinish(true);
                    setIsDataLoading(false); 
                    onFormSuccess(true);
                }
            } else {
                setIsError(true);
                setIsDataLoading(false);
                setEnableResendOTP(true);
                resetTimer();
            }
        } catch (error) {
            console.log(error);
            setIsError(true);
            setIsDataLoading(false);
        }
    }

    const VerifyPIN = async () => {
        const verification = await verifySession();
        if (verification) {
            if (verification.status == "failed") {
                window.location.reload();
                return
            }
        }
        setIsDataLoading(true);
        const localStorageService = new LocalstorageService();
        try{
            const response = await verifyPIN(localStorageService.getItem('id', false, 'tokenVerification'), PIN);
            if (response) {
                setIsSuccess(true);
                setIsFinish(true);
                setIsDataLoading(false);
                onFormSuccess(true);
            } else {
                setIsError(true);
                setIsDataLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsError(true);
            setIsDataLoading(false);
        }
    }

    const formRender = () => {
        return (
            <div className="info-p info-p-n info-p-n-m" style={{ textAlign: 'center' }}>
                <div className="info-inner-p" ></div>
                <div className="form-p" style={{"paddingBottom": "6rem"}}>

                    {/* Phone Number Section */}
                    <div>
                        <label className="scl">
                            {t('selectPartnerPage.Gopay.phone')}
                        </label>
                        <div className="form-group-pn mt-2 input-p">
                            <span class="border-end country-code country-code-n" style={{zIndex: '9'}}>+62</span>
                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                <input type="text" id="phone" className="form-control" placeholder={t('selectPartnerPage.Gopay.phone_placeholder')} name="phone"
                                                disabled={isRequireOTP || isFinish}
                                                value={MobileNumber}
                                                onChange={handlePhoneChange}
                                                maxLength={14}
                                                autoComplete="off"
                                            />
                            </span>
                            {(isRequireOTP || isRequirePIN) && (!isFinish) ? (<div style={{display:'flex'}}>
                            
                            <span style={{flex: '1'}}></span>
                            <Button className='' 
                            onClick={() => {
                                clearInterval(timerIntervalRef.current);
                                resetTimer();
                                setRequireOTP(false);
                                setRequirePIN(false);
                                setMobileNumber('');
                                setOTP('');
                                setPIN('');
                            }}
                            outlined
                            style={{
                                'height': '2rem',
                                'marginTop': '1rem',
                                'color': '#1c1b4b',
                                'cursor': 'pointer',
                                'textDecoration': 'none',
                            }}>
                                {t('selectPartnerPage.Gopay.change_number')} 
                            </Button>
                        </div> ) : null}
                        </div>
                    </div>

                    {/* OTP Section */}
                    {isRequireOTP && (
                    <div style={{ paddingTop: '30px' }}>
                        <label className="scl">
                            {t('selectPartnerPage.Gopay.otp')}
                        </label>

                        <div className="input-p">
                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                <input type="text" id="otp" className="form-control" placeholder={t('selectPartnerPage.Gopay.otp_placeholder')} name="otp"
                                                disabled={isRequirePIN || isFinish}
                                                value={OTP}
                                                onChange={handleOTPChange}
                                                maxLength={4}
                                                autoComplete="off"
                                            />
                            </span>
                            { (isRequirePIN == false && isFinish == false ? true :  false) ? (<div style={{display:'flex'}}>
                            <div className='timer' style={{
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'height': '2rem',
                                    'borderRadius': '5px',
                                    'margin': '1rem 0'
                                }}>
                                <i className='pi pi-stopwatch' style={{ fontSize: '1.6rem', marginRight: '.3rem' }}></i>
                                <span className='timer-text'>{minHand}:{secHand}</span>
                                
                            </div>

                            <span style={{flex: '1'}}></span>
                            <Button className='' 
                            disabled={!enableResendOTP}
                            outlined
                            onClick={() => {
                                setEnableResendOTP(false);
                                RequestOTP();
                            }}
                            style={{
                                'height': '2rem',
                                'marginTop': '1rem'
                            }}>
                                {t('selectPartnerPage.Gopay.resend')} 
                            </Button>
                        </div> ) : null}
                        </div>
                    </div>)}

                    {/* PIN Section */}
                    {isRequirePIN && (
                    <div style={{ paddingTop: '30px' }}>
                        <label className="scl">
                            {t('selectPartnerPage.Gopay.pin')}
                        </label>

                        <div className="input-p">
                            <span className="p-input-icon-right" style={{ "width": "100%" }}>
                                {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}
                                <input type="text" id="pin" className="form-control" placeholder={t('selectPartnerPage.Gopay.pin_placeholder')} name="pin"
                                                disabled={isFinish}
                                                value={PIN}
                                                onChange={handlePINChange}
                                                maxLength={6}
                                                autoComplete="off"
                                />
                            </span>
                            <p className="scl scl-gojek">{t('selectPartnerPage.Gopay.checkGojek')}</p>
                        </div>
                    </div>)}
                </div>
            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="partner-page-form-n">
                <div>
                    <h3 className="title-p title-p-p1 title-p-n">
                        {t('selectPartnerPage.Gopay.title')}
                    </h3>
                    {formRender()}
                </div>

                <div className="btn-div-p-n">
                    <div className="back-btn-pp back-btn-pp-n btn nav-btn-pwc next-btn" style={isMobileScreen ? {} : { display: 'none' }} onClick={back}>
                        {t('form.back')}
                    </div>
                    <div className="buttons-bottom-div">
                        <button type="submit" className="btn nav-btn-pwc next-btn btn-n btn-next-partner-n"
                            disabled={
                                MobileNumber.length < 7            || 
                                (isRequireOTP && OTP.length !== 4) || 
                                (isRequirePIN && PIN.length !== 6) || 
                                isDataLoading                      || 
                                isFinish}
                            onClick={(isRequireOTP && !isRequirePIN) ? VerifyOTP : (isRequireOTP && isRequirePIN) ? VerifyPIN : RequestOTP}
                        >{t('form.submit')} {isDataLoading ? <i className="pi pi-spin pi-spinner" /> : null}</button>
                    </div>
                </div>
                {isMobileScreen && <BrandBadge option={"footer"} />}
            </div>

    
            {isError && <ErrorModal isTranslated={isTranslated} closeResponse={closeResponse} />}
            {isSuccess && <SuccessModal isTranslated={isTranslated} closeResponse={isMobileScreen ? back : () => setIsSuccess(false)} message={isSuccess !== true ? isSuccess : false}/>}
        </>
    );
}

export default Gopay;