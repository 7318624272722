import axios from 'axios';
import LocalstorageService from './localstorage.service';

const isProduction = window.location.href.includes('https://auth.powercred.io')||window.location.href.includes('https://docs.julo.co.id');
const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';
const wantLocal = false; // Mandatory change to false before pushing to gitlab

export const uploadKTP = async (formData, id, email, phone) => {
  try {
    let localstorageService = new LocalstorageService();
    const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');

    const url = wantLocal ?
    `http://127.0.0.1:8000/get/ktp?apikey=${apiKey}&id=${id}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}` : 
    `${baseURL}/identity/get/ktp?apikey=${apiKey}&id=${id}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}`;

    const response = await axios.post(url, formData);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to upload KTP. Status: ' + response.status);
    }
  } catch (error) {
    console.error('Error uploading KTP:', error);
    return null;
  }
};

export const verifyKTP = async (req_body) => {
  try {
    let localstorageService = new LocalstorageService();
    const apiKey = localstorageService.getItem('client_id', false, 'tokenVerification');
    const phone = localstorageService.getItem('user_id', false, 'tokenVerification');

    const url = wantLocal ? 

    `http://127.0.0.1:8000/verify/dukcapil?
apikey=${apiKey}&
id=${req_body.id}&
nik=${req_body.nik}&
name=${encodeURIComponent(req_body.name)}&
birth_date=${encodeURIComponent(req_body.dob)}&
gender=${req_body.gender}&
citizenship=${req_body.citizenship}&
religion=${req_body.religion}&
marital_status=${req_body.maritalStatus}&
occupation=${req_body.occupation}&
place_of_birth=${req_body.pob}&
address=${encodeURIComponent(JSON.stringify(req_body.address.address))}&
rtrw=${req_body.address.rtrw}&
sub_district=${req_body.address.sub_district}&
district=${req_body.address.district}&
education=${req_body.education}&
company_founded_on=${req_body.companyEstablished}&
employee_count=${req_body.employeeCount}&
current_address=${encodeURIComponent(JSON.stringify(req_body.currentAddress.address))}&
current_rtrw=${req_body.currentAddress.rtrw}&
current_sub_district=${req_body.currentAddress.sub_district}&
current_district=${req_body.currentAddress.district}` 
    
    :
    
    `${baseURL}/identity/verify/dukcapil?
apikey=${apiKey}&
id=${req_body.id}&
nik=${req_body.nik}&
name=${encodeURIComponent(req_body.name)}&
birth_date=${encodeURIComponent(req_body.dob)}&
gender=${req_body.gender}&
citizenship=${req_body.citizenship}&
religion=${req_body.religion}&
marital_status=${req_body.maritalStatus}&
occupation=${req_body.occupation}&
place_of_birth=${req_body.placeOfBirth}&
address=${encodeURIComponent(JSON.stringify(req_body.address.address))}&
rtrw=${req_body.address.rtrw}&
sub_district=${req_body.address.sub_district}&
district=${req_body.address.district}&
education=${req_body.education}&
company_founded_on=${req_body.companyEstablished}&
employee_count=${req_body.employeeCount}&
current_address=${encodeURIComponent(JSON.stringify(req_body.currentAddress.address))}&
current_rtrw=${req_body.currentAddress.rtrw}&
current_sub_district=${req_body.currentAddress.sub_district}&
current_district=${req_body.currentAddress.district}&
phone=${phone}` 

    const response = await axios.post(url);
    return response.data;

  } catch (error) {
    console.error('Error Verifying KTP:', error);
    return null;
  }
};
