/*
  Three ways of Uploading Image:
    • Drag and Drop
    • Click on Upload Box
    • Click on Camera Icon

  There is a centralised function to handle all the image upload.
  If the image is >2MB or <250KB, it'll increase / decrease the size of the image.
*/ 

import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { ImageCompressor } from 'image-compressor';
import './selfieCapture.widget.css';


const SelfieCapture = ({onCapture}) => {
  // #region Declarations
  const { t } = useTranslation();
  const toast = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [capturePhotoPage, setCapturePhotoPage] = useState(false);
  const [imageSize, setImageSize] = useState('');
  const fileUploadRef = useRef(null);
  const [cameras, setCameras] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(1); // 0 for front & 1 for back
  const [camPermission, setCamPermission] = useState(0);
  const [permission, setPermission] = useState('');
  // #endregion Declarations

  // #region Toast-Section
  const showWarn = (message) => {
    toast.current.show({severity:'warn', summary: 'Warning', detail: message, life: 3000});
  }
  // #endregion Toast-Section

  // #region Image-Handling-Section
  async function imageUrlToBlobAndSize(imageUrl) {
    try {
      const response = await fetch(imageUrl);
      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], { type: response.headers.get('content-type') });
      const sizeInKB = Math.round(blob.size / 1024);
      if(sizeInKB < 250) {
        showWarn(t('selectPartnerPage.KYC.warningSmall'));
        return;
      } 
      if(sizeInKB > 2000) {
        showWarn(t('selectPartnerPage.KYC.warningLarge'));
        return;
      }
      centralisedImageHandler(blob);
    } catch (error) {
      console.error('Error converting image URL to Blob:', error);
    }
  }

  const handleFileTooLarge = (blob) => {
    const img = new Image();
    img.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL('image/png');

      const imageCompression = new ImageCompressor();
      const compressorSettingsDecreaseSize = {
        toWidth: 1920,
        toHeight: 1080,
        mimeType: 'image/png',
        quality: 0.5, 
        speed: 'high',
      };
      imageCompression.run(image, compressorSettingsDecreaseSize, imageUrlToBlobAndSize)
    }
    img.src = URL.createObjectURL(blob);
  };
  
  const handleFileTooSmall = (blob) => {
    const img = new Image();
    img.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL('image/png');

      const imageCompression = new ImageCompressor();
      const compressorSettingsIncreaseSize = {
          toWidth: 1920, 
          toHeight: 1080, 
          mimeType: 'image/png', 
          quality: 0.3, 
          speed: 'high', 
      };
      imageCompression.run(image, compressorSettingsIncreaseSize, imageUrlToBlobAndSize)
    }
    img.src = URL.createObjectURL(blob);
  };

  const centralisedImageHandler = (blob) => {
    const fileSize = blob.size / 1024;
    if(fileSize > 1000 && fileSize < 2000) {
      loadImage(blob);
    } else {
      // loadImage(blob);
      if (fileSize > 2000) {
        showWarn(t('selectPartnerPage.KYC.warningLarge'));
        // handleFileTooLarge(blob);
        return;
      } else {
        const targetSizeBytes = 1000 * 1024;
        const currentSizeBytes = blob.size;
        const nullBytesToAdd = targetSizeBytes - currentSizeBytes;
        const nullBuffer = new ArrayBuffer(nullBytesToAdd);
        const nullBytesBlob = new Blob([nullBuffer]);
        const increasedBlob = new Blob([blob, nullBytesBlob]);
        loadImage(increasedBlob);
      }
    }
  };

  const loadImage = (file_raw) => {
    const fileSize = file_raw.size / 1024;
    if (fileSize === 1000){
      setImageSize('1 MB');
    } else {
      if (fileSize > 1024) {
        setImageSize((fileSize / 1024).toFixed(1) + ' MB');
      } else {
        setImageSize(fileSize.toFixed(1) + ' KB');
      }
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const imageRaw = event.target.result;
      const canvas = canvasRef.current;
      canvas.removeAttribute('width');
      canvas.removeAttribute('height');
      const context = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL('image/png');
        setImageData(image);
        onCapture(image, file_raw);
        handleCloseButton();
      };
      img.src = imageRaw;
    }
    reader.readAsDataURL(file_raw);
  }

  // #endregion Image-Handling-Section

  // #region Camera-Section
    const changeCamera = async () => {
      setSelectedCamera(selectedCamera === 0 ? 1 : 0);
      stopCamera();
      await sleep(2100);
      startCamera();
    }

    const getConstraints = async (cameras) => {
      if (cameras.length > 0) {
        if (selectedCamera == 0){
          const constraints = {
            video: {
              deviceId: cameras[0].deviceId
            },
            advanced: [{
              focusMode: 'manual',
              zoom: 1,
              exposureMode: 'manual',
            }]
          };
          return constraints;
        }
        if (selectedCamera == 1){
          if(cameras.length != 1){
            const constraints = {
              video: {
                deviceId: cameras[cameras.length - 1].deviceId
              },
              advanced: [{
                focusMode: 'manual',
                zoom: 1,
                exposureMode: 'manual',
              }]
            };
            return constraints;
          } else {
            const constraints = {
              video: {
                deviceId: cameras[0].deviceId
              },
              advanced: [{
                focusMode: 'manual',
                zoom: 1,
                exposureMode: 'manual',
              }]
            };
            return constraints;
          }
        }
      }
    }

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }



    async function getCameras() {
      try {       
        console.log('getting cameras...')
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        videoDevices.sort((a, b) => a.label.localeCompare(b.label));
        setCameras(videoDevices);
        return videoDevices;
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    }

    async function getPermissions() {
      try {
        navigator.mediaDevices.getUserMedia({audio: false, video: true})

        const permission = await navigator.permissions.query({ name: 'camera' });
        if (permission.state === 'prompt') {
          checkPermissionStatus(3, handleBackButton);
        }
        permission.state != 'denied' ? setCamPermission(0) : setCamPermission(1);
        return permission.state;
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    }

    function checkPermissionStatus(retries, callback) {
      const checkStatus = async () => {
        const permissionStatus = await navigator.permissions.query({ name: 'camera' });
        
        if (permissionStatus.state === 'prompt') {
          if (retries > 0) {
            console.log(`Waiting for permission... Retries left: ${retries}`);
            setTimeout(() => checkStatus(retries - 1, callback), 6000);
          } else {
            console.log("Permission still prompt after retries. Executing callback.");
            callback();
          }
        } else {
          console.log(`Permission status: ${permissionStatus.state}`);
          if (permissionStatus.state == "denied") {
            handleBackButton()
          }
          if (permissionStatus.state == "granted") {
            startCamera();
          }
        }
      };
    
      checkStatus(retries, callback);
    }
    
    
    
    const startCamera = async () => {
      setCapturePhotoPage(true);
      await getPermissions();
      getCameras().then(
        async (cameras) => {
          try {
            const constraints = await getConstraints(cameras);
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            videoRef.current.srcObject = stream;
            await sleep(3000);
          } catch (error) {
            console.error('Error accessing camera:', error);
            setCamPermission(1);
          } 
      });
    };
  
    const stopCamera = () => {
      setIsVideoLoaded(false);
      if (videoRef.current) {
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    };
  
    const captureImage = () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.width = 1440;
      canvas.height = 960;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        centralisedImageHandler(blob);
      }, 'image/jpeg');
    };
  // #endregion Camera-Section
  
  // #region Upload-Section
  const handleUploadClick = (event) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.multiple = false;
    fileInput.style.display = 'none';

    fileInput.onchange = (event) => {
      centralisedImageHandler(event.target.files[0]);
    };
    fileInput.click();
  }
  // #endregion Upload-Section
  
  // #region Drag-Section
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    centralisedImageHandler(event.dataTransfer.files[0]);
  };
  // #endregion Drag-Section

  // #region UI-Handlers
  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  }

  const openUploadDialog = () => {
    setVisible(true);
  }

  const handleBackButton = () => {
    stopCamera();
    setCapturePhotoPage(false);
  }

  const handleCloseButton = () => {
    stopCamera();
    setCapturePhotoPage(false);
    setVisible(false);
  }
  // #endregion UI-Handlers
  
  // #region UI-Section
  const footerContent = (
    <div style={{
      textAlign: 'center',
      width: '100%',
    }}>
    {capturePhotoPage && camPermission != 1 ? (
      <>
        <Button icon="pi pi-camera" rounded outlined severity="danger" aria-label="capture" onClick={captureImage} style={{
          width: '2.9rem',
          margin: '0 1rem', 
        }} disabled={!isVideoLoaded}/>

        { (cameras?.length > 1)  && (<Button icon="pi pi-sync" rounded outlined severity="primary" aria-label="rotate" onClick={changeCamera} style={{
          width: '2.9rem',
          margin: '0 1rem', 
        }} disabled={!isVideoLoaded}/>)}
        
      </>
    ) : null}
  </div>
  );

  const HeaderContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
    {capturePhotoPage ? (
      <>
        <Button icon="pi pi-chevron-left" rounded text onClick={handleBackButton}/>
        <span style={{ flex: '0.5' }}></span>
      </>
    ) : (
      <></>
    )}
  
    <div style={{ flex: '1', textAlign: capturePhotoPage ? 'center' : 'left' }}>
      {t('selectPartnerPage.KYC.selfie')}
    </div>
  
    <span style={{ flex: '0.5' }}></span>
    <Button icon="pi pi-times" rounded text onClick={handleCloseButton} />
    </div>
  );
  // #endregion UI-Section
  
  return (
    <>
      <Toast ref={toast} />
      <Dialog header={HeaderContent} visible={visible} className='pw-dialog'
      position="top" style={{width: '40vw'}}
      draggable={false} resizable={false} closeOnEscape={true} onHide={handleCloseButton}
      footer={footerContent}>
        { capturePhotoPage && camPermission == 0 ? (
         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {!isVideoLoaded && (<ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" animationDuration=".5s" className='spinner' />)}
          <video ref={videoRef} autoPlay playsInline onLoadedData={handleVideoLoaded} style={{ width: '100%' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
         </div>
        ): capturePhotoPage && camPermission == 1 ? (<h4>{t('selectPartnerPage.KYC.cameraPermission')}</h4>) : null }
        { !capturePhotoPage ? (
          <>
          <div className="input-p input-f-p1" onClick={startCamera} style={{width:'375px'}}>
             <div>
               <div role="presentation" tabIndex={0} className="form-control ddrop-div">
                 <div>
                     <i className="pi pi-camera" style={{ fontSize: '2.5rem' }}></i>
                 </div>
                 </div>
             </div>
          </div>
          
          <div style={{padding:"3px", marginTop: "3px", marginBottom: "3px"}}> <b>OR</b> </div>

          <div className='input-p input-f-p1' style={{width:'375px'}}>
          <div onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
              <div
                role="presentation"
                className={`drag-drop-area form-control ddrop-div ${isDragOver ? 'drag-over' : ''}`} 
                style={{ cursor: 'pointer' }}
                onClick={handleUploadClick}
              >
                <canvas ref={canvasRef} style={{ display: 'none' }} />
                <div>
                  <p
                    className="m-0 message-inner"
                    style={{ height: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    Drag and Drop or Click to upload <i className="pi pi-upload ml-3"></i>
                  </p>
                </div>
              </div>
            </div>

            <p className='message-inner2' style={{textAlign: 'left'}}>Drag a file on the upload box or {<a className="p-buttonx" onClick={handleUploadClick}>browse from folder.</a>}</p>
            <br />
            <p style={{textAlign: 'center', color: 'red', fontSize: '0.8rem'}}>{t('selectPartnerPage.KYC.sizeStatement')}</p>
          </div>

          
          </>
        ) :null}
      </Dialog>

       {!imageData && (
        <div className="input-p input-f-p1" onClick={openUploadDialog}>
             <div>
               <div role="presentation" tabIndex={0} className="form-control ddrop-div">
                 <input accept="application/pdf,.pdf" multiple type="file" tabIndex={-1} style={{display: 'none'}} />
                 <div>
                     <i className="pi pi-image" style={{ fontSize: '2.5rem' }}></i>
                 </div>
                 </div>
             </div>
        </div>
      )}

      {imageData && (
        <>
        <div className="input-p input-f-p1">
              <div role="presentation" tabIndex={0} className="form-control ddrop-div">
                <div>
                    <img src={imageData} alt="Captured Image" />
                </div>
              </div>

              <div style={{display:'flex'}}>
                <p><b>Size:</b> {imageSize} </p>
                <span style={{flex: '1'}}></span>
                <Button onClick={openUploadDialog} style={{
                      'height': '2rem',
                      'marginTop': '1rem'
                }}>
                  {t('selectPartnerPage.KYC.reupload')} 
                </Button>
              </div>

        </div>
        </>
      )}
      
    </>
  );
};

export default SelfieCapture;
